import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Row,
    Col,
    Label,
    Button,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import {
    CREATE_RISK_SUCCESSFUL,
    CREATE_RISK_FAILED,
    UPLOAD_ATTACHMENT_FAILED,
} from "src/common/constants";

import {
    API_URL_CREATE_RISK,
    API_URL_RISK_UPLOAD_FILE
} from '../../../../constants';

import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { CgLink } from "react-icons/cg";
import { TbLoader } from "react-icons/tb";
import { convertToHTML } from "draft-convert";
import Select from "react-select";
import axios from "axios";

import FileUploader from "../../uploader";
import CustomMultiSelect from "../../../CustomMultiSelect";
import CommonService from "src/modules/3rd-party-management/apis/CommonService";
import { useQuery } from "@tanstack/react-query";

const CreateRiskModal = function (props) {
    const {
        t,
        token,

        close,
        isOpen,
        categories,
        owners,
        locations,
        damages,
        likelihoods,
        creationRequestPayload  =   {},

        onRiskCreationSuccess
    } = props;

    const categoryHTMLRef = useRef(null);
    const otherCategoryHTMLRef = useRef(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedCategoryOtherString, setSelectedCategoryOtherString] =
        useState(null);
    const [categoryError, setCategoryError] = useState(null);
    const [categoryOtherError, setCategoryOtherError] = useState(null);

    const subCategoryHTMLRef = useRef(null);
    const otherSubCategoryHTMLRef = useRef(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [selectedSubCategoryOtherString, setSelectedSubCategoryOtherString] =
        useState(null);
    const [subCategoryError, setSubCategoryError] = useState(null);
    const [subCategoryOtherError, setSubCategoryOtherError] = useState(null);

    const locationHTMLRef = useRef(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locationError, setLocationError] = useState(null);

    const ownerHTMLRef = useRef(null);
    const [selectedOwner, setSelectedOwner] = useState(null);
    const [ownerError, setOwnerError] = useState(null);

    const damageHTMLRef = useRef(null);
    const [selectedDamage, setSelectedDamage] = useState(null);
    const [damageError, setDamageError] = useState(null);

    const likelihoodHTMLRef = useRef(null);
    const [selectedLikelihood, setSelectedLikelihood] = useState(null);
    const [likelihoodError, setLikelihoodError] = useState(null);

    const entetiesHTMLRef = useRef(null);
    const [selectedLegalEntity, setSelectedLegalEntity] = useState("");
    const [legalEntityError, setLegalEntityError] = useState(null);

    const descriptionHTMLRef = useRef(null);
    const [description, setDescription] = useState(EditorState.createEmpty());
    const [descriptionError, setDescriptionError] = useState(null);

    const [showAttachmentsList, setShowAttachmentsList] = useState(false);
    const [showAttachmentsProgress, setShowAttachmentsProgress] = useState(false);
    const [showSubmitProgress, setShowSubmitProgress] = useState(false);
    const [attachmentsList, setAttachmentsList] = useState([]);

    const [legalEntitiesOptions, setLegalEntitiesOptions] = useState([]);
    const [selectedLegalEntities, setSelectedLegalEntities] = useState([]);

    const handleToggleModal = () => {
        handleCloseModal();
    };

     // Fetch Legal Enteties Options
    const fetchLegalEntitiesOptions = useQuery({
        queryKey: ["data-protection-enteties-list"],
        queryFn: async () => {
        const service = CommonService.getInstance();

        return await service.fetchLegalEntities(props?.Organization?.id);
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (error) => {
        if (process.env.NODE_ENV === "development") console.error(error);

        toast(props.t("An error occurred while fetching legal entities."), {
            type: "error",
        });
        },
    });

    const handleChangeCategory = (category) => {
        if (category.label.toLowerCase() !== "other") {
            setSelectedCategoryOtherString("");
        }

        setSelectedCategory(category);

        if (category.subCategories.length === 1) {
            handleChangeSubCategory(category.subCategories[0]);
        } 
        else {
            handleChangeSubCategory(null);
        }
    };

    const handleChangeSubCategory = (subCategory) => {
        if (subCategory && subCategory.label.toLowerCase() !== "other"){
            setSelectedSubCategoryOtherString(null);
        }

        setSelectedSubCategory(subCategory);
    };

    const handleChangeOtherCategoryString = (value) => {
        setSelectedCategoryOtherString(value);
    };

    const handleChangeOtherSubCategoryString = (value) => {
        setSelectedSubCategoryOtherString(value);
    };

    const handleCloseModal = () => {
        setSelectedCategory(null);
        setSelectedCategoryOtherString(null);
        setCategoryError(null);
        setCategoryOtherError(null);
        setSelectedSubCategory(null);
        setSelectedSubCategoryOtherString(null);
        setSubCategoryError(null);
        setSubCategoryOtherError(null);
        setSelectedLocation(null);
        setLocationError(null);
        setSelectedOwner(null);
        setOwnerError(null);
        setSelectedDamage(null);
        setDamageError(null);
        setSelectedLikelihood(null);
        setLikelihoodError(null);
        setSelectedLegalEntity("");
        setLegalEntityError(null);
        setDescription(EditorState.createEmpty());
        setDescriptionError(null);
        setAttachmentsList([]);
        setShowAttachmentsList(false);
        setShowAttachmentsProgress(false);
        setShowSubmitProgress(false);
        setSelectedLegalEntities([]);
        close();
    };

    const handleUploadAttachments = (fs) => {
        try {
            setShowAttachmentsProgress(true);

            const files = [];
            const formData = new FormData();

            for (const f in fs.target.files) {
                if (fs.target.files.hasOwnProperty(f)) {
                    files.push(fs.target.files[f]);
                }
            }

            files.map(async (file) => {
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: file.size,
                });

                formData.append("file", file);

                const result = await axios.post(API_URL_RISK_UPLOAD_FILE,
                    formData,
                    {
                        headers: {
                        Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (result.status === 200) {
                    const fileData = result.data.data;
                    setAttachmentsList((oldArray) => [
                        ...oldArray,
                        {
                            id: fileData.id,
                            file: fileData,
                            name: file.name,
                            preview: file.preview,
                            formattedSize: file.formattedSize,
                        },
                    ]);

                    if (file === files[files.length - 1])
                        setShowAttachmentsProgress(false);
                } 
                else {
                    setShowAttachmentsProgress(false);

                    toast(t(UPLOAD_ATTACHMENT_FAILED), {
                        type: 'error',
                    });
                }
            });
        } 
        catch (error) {
            toast(t(UPLOAD_ATTACHMENT_FAILED), {
				type: 'error',
			});
        }
    };

    const handleRemoveAttachment = (id) => {
        const index = attachmentsList.findIndex((i) => i.id === id);
        if (index > -1) {
            const oldArray = Array.from(attachmentsList);
            oldArray.splice(index, 1);
            setAttachmentsList(oldArray);
        }
    };

    const handleFormValidations = () => {
        let isValid = true;

        if (!selectedCategory) {
        isValid = false;
        setCategoryError("Please Select Category");
        }

        if (
            selectedCategory &&
            selectedCategory.baseLabel === "Other" &&
            !selectedCategoryOtherString
        ) {
            isValid = false;
            setCategoryOtherError("Please Select Other Category");
        }

        if (!selectedSubCategory) {
            isValid = false;
            setSubCategoryError("Please Select Subcategory");
        }

        if (
            selectedSubCategory &&
            selectedSubCategory.baseLabel === "Other" &&
            !selectedSubCategoryOtherString
        ) {
            isValid = false;
            setSubCategoryOtherError("Please Select Other Subcategory");
        }

        if (!selectedLocation) {
            isValid = false;
            setLocationError("Please Select Location");
        }

        if (!selectedOwner) {
            isValid = false;
            setOwnerError("Please Select Owner");
        }

        if (!selectedDamage) {
            isValid = false;
            setDamageError("Please Select Damage");
        }

        if (!selectedLikelihood) {
            isValid = false;
            setLikelihoodError("Please Select Likelihood");
        }

        if (!selectedLegalEntities?.length) {
            isValid = false;
            setLegalEntityError("Please Select Legal Entities");
        }

        if (!description.getCurrentContent().hasText()) {
            isValid = false;
            setDescriptionError(t("Please type description"));
        }

        if (!isValid) categoryHTMLRef.current.scrollIntoView();

        return isValid;
    };

    const handleSubmit = async () => {
        setShowSubmitProgress(true);

        try {
            if (handleFormValidations()) {
                const legalEntityValues = selectedLegalEntities?.map((item) => item?.value.toString());
                const data = {
                    description :   convertToHTML(description.getCurrentContent()),
                    category    :   selectedCategory.label.toLowerCase() === "other"
                        ? null
                        : selectedSubCategory.label.toLowerCase() ===
                        "other"
                        ? selectedCategory.value
                        : selectedSubCategory.value,
                    otherCategory   :   selectedCategoryOtherString
                        ? selectedCategoryOtherString
                        : null,
                    otherSubCategory    :   selectedSubCategoryOtherString
                        ? selectedSubCategoryOtherString
                        : null,
                    location            :   selectedLocation,
                    legalEntities     :     legalEntityValues,
                    damage              :   selectedDamage,
                    likelihood          :   selectedLikelihood,
                    descriptionAttachments  :   attachmentsList.map((f) => String(f.file.id)),
                    owner                   :   selectedOwner ? selectedOwner : null,
                    ...(creationRequestPayload || {})
                };

                const result = await axios.post(API_URL_CREATE_RISK,data,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    }
                );

                if (result.status === 200) {
                    toast(t(CREATE_RISK_SUCCESSFUL), {
                        type: 'success',
                    });

                    onRiskCreationSuccess && onRiskCreationSuccess(result.data.data);
                    
                    handleCloseModal();
                } 
                else {
                    toast(t(CREATE_RISK_FAILED), {
                        type: 'error',
                    });
                }
            }
        } 
        catch (error) {
            toast(t(CREATE_RISK_FAILED), {
                type: 'error',
            });
        }

        setShowSubmitProgress(false);
    };

    useEffect(() => {
        const entities = fetchLegalEntitiesOptions?.data;
        if(entities && entities?.legalEntities){
            setLegalEntitiesOptions(entities.legalEntities.map(entity => ({
                value: entity.id,
                label: entity.title
            })));
        }
    }, [fetchLegalEntitiesOptions?.data])

    return (
        <Modal isOpen={isOpen}
            size="lg"
            toggle={handleToggleModal}
            backdrop="static"
            scrollable={true}
            className="create-risk-modal">

            <ModalHeader toggle={handleToggleModal}>
                {t("Create a risk")}
            </ModalHeader>

            <ModalBody className="create-risk-modal-body">
                <AvForm style={{
                        opacity: "1",
                        transition: "all ease 0.3s",
                    }} className="needs-validation" onValidSubmit={() => handleSubmit()}>
                        <Row className="mb-3">
                            <Col sm="12" md={selectedCategory && selectedCategory.baseLabel === "Other" ? 3: 6} lg={ selectedCategory && selectedCategory.baseLabel  === "Other" ? 3 : 6 }>
                                <span ref={categoryHTMLRef}></span>
                                <Label className="form-label text-dark">
                                    {`${t("Risk category")}: `}
                                </Label>

                                {categories ? (
                                    <>
                                        <Select
                                            name="category"
                                            classNamePrefix="select2-selection"
                                            options={categories.map((c) => {
                                            return {
                                                value: c.value,
                                                baseLabel: c.baseLabel,
                                                label: t(c.baseLabel),
                                                subCategories: c.subCategories,
                                            };
                                            })}
                                            value={selectedCategory}
                                            onChange={handleChangeCategory}
                                            placeholder={t("Select...")}
                                        />
                                        <p className="text-danger">
                                            {!selectedCategory ? t(categoryError) : ""}
                                        </p>
                                    </>
                                ) : (
                                    <div className="dt-field dt-skeleton dt-select-list" style={{ marginBottom: 16 }}
                                    ></div>
                                )}
                            </Col>

                            <Col sm="12" md={3} lg={3} hidden={ !selectedCategory || (selectedCategory && selectedCategory.baseLabel !== "Other") }>
                                <span ref={otherCategoryHTMLRef}></span>
                                <Label className="form-label text-dark">{`${t(
                                    "Other category"
                                )}: `}</Label>
                                <AvField
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    name="otherCat"
                                    type="text"
                                    value={selectedCategoryOtherString}
                                    hidden={
                                    selectedCategory && selectedCategory.baseLabel !== "Other"
                                    }
                                    onChange={(e) =>
                                    handleChangeOtherCategoryString(e.target.value)
                                    }
                                />
                                <p className="text-danger">
                                    {!selectedCategoryOtherString ? t(categoryOtherError) : ""}
                                </p>
                            </Col>

                            <Col sm="12" md={ selectedSubCategory && selectedSubCategory.baseLabel === "Other" ? 3 : 6 } lg={ selectedSubCategory && selectedSubCategory.baseLabel === "Other" ? 3 : 6}>
                                <span ref={subCategoryHTMLRef}></span>
                                <Label className="form-label text-dark">{`${t(
                                    "Risk subcategory"
                                )}: `}</Label>

                                {categories ? (
                                    <>
                                    <Select
                                        name="categorsubcategory"
                                        classNamePrefix="select2-selection"
                                        placeholder={t("Select...")}
                                        value={selectedSubCategory}
                                        options={
                                        selectedCategory
                                            ? selectedCategory.subCategories.map((c) => {
                                                return {
                                                value: c.value,
                                                baseLabel: c.baseLabel,
                                                label: t(c.baseLabel),
                                                };
                                            })
                                            : []
                                        }
                                        isDisabled={selectedCategory ? false : true}
                                        onChange={handleChangeSubCategory}
                                    />
                                    <p className="text-danger">
                                        {!selectedSubCategory ? t(subCategoryError) : ""}
                                    </p>
                                    </>
                                ) : (
                                    <div
                                    className="dt-field dt-skeleton dt-select-list"
                                    style={{ marginBottom: 16 }}
                                    ></div>
                                )}
                            </Col>

                            <Col sm="12" md={3} lg={3} hidden={
                                !selectedSubCategory ||
                                selectedSubCategory.baseLabel !== "Other" }>
                                    <span ref={otherSubCategoryHTMLRef}></span>
                                    <Label className="form-label text-dark">{`${t(
                                        "Other subcategory"
                                    )}: `}</Label>
                                    <AvField
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        name="otherCat"
                                        type="text"
                                        onChange={(e) =>
                                        handleChangeOtherSubCategoryString(e.target.value)
                                        }
                                    />
                                    <p className="text-danger">
                                        {!selectedSubCategoryOtherString
                                        ? t(subCategoryOtherError)
                                        : ""}
                                    </p>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col sm="12" md="6" lg="6">
                                <span ref={locationHTMLRef}></span>
                                <Label className="form-label text-dark">{`${t(
                                    "Location of risk "
                                )}: `}</Label>
                                {locations ? (
                                    <>
                                    <Select
                                        name="location"
                                        classNamePrefix="select2-selection"
                                        options={locations.map((l) => {
                                        return {
                                            value: l.value,
                                            baseLabel: l.label,
                                            label: t(l.label),
                                        };
                                        })}
                                        placeholder={t("Select...")}
                                        onChange={(e) => {
                                        setSelectedLocation(e.value);
                                        }}
                                    />
                                    <p className="text-danger">
                                        {!selectedLocation ? t(locationError) : ""}
                                    </p>
                                    </>
                                ) : (
                                    <div
                                    className="dt-field dt-skeleton dt-select-list"
                                    style={{ marginBottom: 16 }}
                                    ></div>
                                )}
                            </Col>

                            <Col sm="12" md="6" lg="6">
                                <span ref={ownerHTMLRef}></span>
                                <Label className="form-label text-dark">{`${t(
                                    "Risk owner"
                                )}: `}</Label>
                                {owners ? (
                                    <>
                                    <Select
                                        name="owner"
                                        classNamePrefix="select2-selection"
                                        options={owners}
                                        onChange={(e) => {
                                        setSelectedOwner(e.value);
                                        }}
                                        placeholder={t("Select...")}
                                    />
                                    <p className="text-danger">
                                        {!selectedOwner ? t(ownerError) : ""}
                                    </p>
                                    </>
                                ) : (
                                    <div
                                    className="dt-field dt-skeleton dt-select-list"
                                    style={{ marginBottom: 16 }}
                                    ></div>
                                )}
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col sm="12" md="6" lg="6">
                                <span ref={damageHTMLRef}></span>
                                <Label className="form-label text-dark">{`${t(
                                    "Damage"
                                )}: `}</Label>
                                {damages ? (
                                    <>
                                    <Select
                                        name="damage"
                                        classNamePrefix="select2-selection"
                                        options={damages}
                                        onChange={(e) => {
                                        setSelectedDamage(e.value);
                                        }}
                                        placeholder={t("Select...")}
                                    />
                                    <p className="text-danger">
                                        {!selectedDamage ? t(damageError) : ""}
                                    </p>
                                    </>
                                ) : (
                                    <div
                                    className="dt-field dt-skeleton dt-select-list"
                                    style={{ marginBottom: 16 }}
                                    ></div>
                                )}
                            </Col>

                            <Col sm="12" md="6" lg="6">
                                <span ref={likelihoodHTMLRef}></span>
                                <Label className="form-label text-dark">{`${t(
                                    "Likelihood"
                                )}: `}</Label>
                                {likelihoods ? (
                                    <>
                                    <Select
                                        name="likelihood"
                                        classNamePrefix="select2-selection"
                                        options={likelihoods.map((l) => {
                                        return {
                                            value: l.value,
                                            label: t(l.label),
                                        };
                                        })}
                                        placeholder={t("Select...")}
                                        onChange={(e) => {
                                        setSelectedLikelihood(e.value);
                                        }}
                                    />
                                    <p className="text-danger">
                                        {!selectedLikelihood ? t(likelihoodError) : ""}
                                    </p>
                                    </>
                                ) : (
                                    <div
                                    className="dt-field dt-skeleton dt-select-list"
                                    style={{ marginBottom: 16 }}
                                    ></div>
                                )}
                            </Col>
                        </Row>

                        <Row>
                            <Col sm="12" md="6" lg="6">
                                <span ref={entetiesHTMLRef}></span>
                                <Label className="form-label text-dark">{`${t(
                                    "Involved entities"
                                )}: `}</Label>
                                <CustomMultiSelect
                                    placeholder={t("Select") + "..."}
                                    selectedValues={selectedLegalEntities}
                                    options={legalEntitiesOptions}
                                    isDisabled={fetchLegalEntitiesOptions.isLoading || fetchLegalEntitiesOptions.isFetching}
                                    onChange={(selectedValues)=>setSelectedLegalEntities(selectedValues)}
                                    />
                                <p className="text-danger">
                                    {!selectedLegalEntity ? t(legalEntityError) : ""}
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm="12" md="12" lg="12">
                            <span ref={descriptionHTMLRef}></span>
                            <Label className="form-label text-dark">{`${t(
                                "Description"
                            )}: `}</Label>

                            <Editor
                                editorState={description}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(e) => setDescription(e)}
                                toolbar={{
                                options: [
                                    "inline",
                                    "blockType",
                                    "fontSize",
                                    "list",
                                    "textAlign",
                                    "colorPicker",
                                    "link",
                                    "remove",
                                    "history",
                                ],
                                inline: {
                                    options: [
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "monospace",
                                    ],
                                    bold: { className: "bordered-option-classname" },
                                    italic: { className: "bordered-option-classname" },
                                    underline: { className: "bordered-option-classname" },
                                    strikethrough: { className: "bordered-option-classname" },
                                    code: { className: "bordered-option-classname" },
                                },
                                blockType: {
                                    className: "bordered-option-classname",
                                },
                                fontSize: {
                                    className: "bordered-option-classname",
                                },
                                }}
                            />
                            <p className="text-danger">
                                {descriptionError ? descriptionError : ""}
                            </p>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <Button
                                    color="primary"
                                    onClick={() => setShowAttachmentsList(!showAttachmentsList)}
                                    outline>
                                    <CgLink />
                                    {t("Attach")}
                                </Button>
                            </Col>
                        </Row>

                        <Row hidden={!showAttachmentsList}>
                            <Col>
                                <FileUploader
                                    uploadedFiles={attachmentsList}
                                    handleAcceptedFiles={(e) =>
                                        handleUploadAttachments(e)
                                    }
                                    showProg={showAttachmentsProgress}
                                    handleClickDeleteFiles={(e) => handleRemoveAttachment(e.id)}
                                />
                            </Col>
                        </Row>

                        <ModalFooter>
                            <Button
                                color="primary"
                                className="waves-effect waves-light"
                                type="submit">
                                    {showSubmitProgress ? <TbLoader /> : t("Save")}
                            </Button>
                        </ModalFooter>
                </AvForm>
            </ModalBody>
        </Modal>
    )
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;

    return {
        user : state.Login.user,
        token
    };
};

export default withNamespaces()(
    connect(
        mapStatetoProps,
        {}
    )(CreateRiskModal)
);
