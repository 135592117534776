import { memo, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";

const { Label, Col, Input } = require("reactstrap");

const MeasuresList = ({
  measures,
  measureType,
  handleInputChange,
  toggleMeasure,
  onAddNewMeasure,
  subSectionId,
  t,
}) => {
  const [newMeasure, setNewMeasure] = useState("");

  const handleNewMeasureChange = (e) => {
    setNewMeasure(e.target.value);
  };

  const handleAddNewMeasure = () => {
    if (newMeasure.trim()) {
      onAddNewMeasure(newMeasure, measureType, subSectionId);
      setNewMeasure("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddNewMeasure();
    }
  };

  return (
    <Col md={6} sm={12}>
      <Label className="form-label">
        {t(
          `${
            measureType.charAt(0).toUpperCase() + measureType.slice(1)
          } measures`
        ) + ":"}
      </Label>

      <ul>
        {measures?.map((measure, measure_index) => {
          const { is_checked, title, type, id } = measure;
          return (
            type === measureType && (
              <li className="p-1" key={id} style={{ listStyleType: "none" }}>
                <Input
                  type="checkbox"
                  defaultChecked={is_checked}
                  onChange={() => {
                    handleInputChange(`measures_${measure_index}`);
                    toggleMeasure(id);
                  }}
                  className="me-2"
                />
                {t(title)}
              </li>
            )
          );
        })}
      </ul>
      <div className="d-flex align-items-center mt-2">
        <div className="me-2 ms-5">+</div>
        <Input
          type="text"
          placeholder={t("Add New")}
          value={newMeasure}
          onChange={handleNewMeasureChange}
          onKeyPress={handleKeyPress}
          style={{ width: "15rem" }}
        />
      </div>
    </Col>
  );
};

const mapStatetoProps = (state) => {
  const { Organization } = state;

  return {
    Organization,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(memo(MeasuresList)));
