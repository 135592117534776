import React, { memo, useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import MessageBox from "src/modules/data-protection/components/MessageBox";
import ReportIncidenService from "../../components/api/ReportIncidenService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ContentState, EditorState } from "draft-js";

const MessageComponent = ({ t, caseId, type, reportDetails, categories, ...rest }) => {
  const [mentionSuggestionList, setMentionSuggestionList] = useState([]);
  const [aiButtonDisabled, setAIButtonDisabled] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty()); // State to hold editor content
  
  const messageSettings = {
    messageListUrl: type === "internal" ? "report.listCommentInternal" : "report.listComment",
    sendMessageUrl: type === "internal" ? "report.addCommentInternal" : "report.addComment",
    attachmentDownloadUrl: "/report/attachment/{attachmentId}/download",
    attachmentUploadUrl: "report.uploadAttachment",
    attachmentDeleteUrl: "attachment",
    payloadId: "report_case_id",
    assetId: caseId,
    mention: {
      enable: true,
      suggestionList: mentionSuggestionList,
    },
  };

  const handleFetchCommunicationMentionSuggestionListQuery = useQuery({
    queryKey: ["fetch-communication-mention-suggestion-list-query"],
    queryFn: async () => {
      const service = new ReportIncidenService();
      return await service.mentionCommentSearch(caseId, {
        query: "",
      });
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === "development") console.error(error);
      toast(t("An error occurred while fetching analyst admins list."), {
        type: "error",
      });
    },
  });

  
  const generateAIResponse = useMutation({
    queryKey: ["data-protection-ai-response"],
    mutationFn: async () => {
      setAIButtonDisabled(true);
      const service = ReportIncidenService.getInstance();
      const chosenCategoryId = reportDetails?.admin_category?.length ? reportDetails?.admin_category[0] : null;
      const chosenCategoryTitle = chosenCategoryId 
      ? categories.find(category => category.id === chosenCategoryId)?.title || "" 
      : "";
      const requestCategory = chosenCategoryTitle;
      const subjectCategory = reportDetails?.question_answers
        .filter(cat => cat?.system_data === "data_category")[0]
        ?.answers[0]
        ?.title || "";
      const content = reportDetails?.question_answers
      .filter(cat => cat.system_data === "content")[0]
      ?.answers[0]
      ?.title || "";
      const payload = {
        request_category: requestCategory,
        subject_category: subjectCategory,
        content: content,
        target_language: rest?.lng
      }
      return await service.FetchAIResponse(payload);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    enabled: false,
    onError: (error) => {
      setAIButtonDisabled(false);
      toast(t("An error occurred while fetching overview content."), {
        type: "error",
      });
    },
    onSuccess: (data) => {
      setAIButtonDisabled(false);
      const contentState = ContentState.createFromText(data || ""); // Convert plain text to ContentState
      setEditorState(EditorState.createWithContent(contentState)); 
    }
  });

  useEffect(() => {
    const { data } = handleFetchCommunicationMentionSuggestionListQuery ?? {};
    if (data && Array.isArray(data)) {
      const list = data.map((item) => ({
        text: item.full_name,
        url: item.id,
        value: item.full_name,
      }));
      setMentionSuggestionList(list);
    }
  }, [handleFetchCommunicationMentionSuggestionListQuery.data]);

  if (
    handleFetchCommunicationMentionSuggestionListQuery.isLoading ||
    handleFetchCommunicationMentionSuggestionListQuery.isFetching ||
    !caseId
  ) {
    return (
      <div className="dt-field dt-skeleton" style={{ width: "100%", height: "320px" }}></div>
    );
  }
  
  return (
    <MessageBox
      settings={{
        ...messageSettings,
        headerOptions: {
          displayFilters: true,
          title: type === "internal" ? "Internal Communication" : "Contact Person",
        },
      }}
      generateAIResponse={() => generateAIResponse.mutate()}
      reportDetails={reportDetails}
      aiButtonDisabled={aiButtonDisabled}
      editorState={editorState} 
    />
  );
};

export default withNamespaces()(memo(MessageComponent));
