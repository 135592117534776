import { memo, useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { AvForm, AvField, AvInput, AvFeedback, AvGroup } from "availity-reactstrap-validation";
import { Button, ButtonDropdown, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label, Row } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import LocalizedPhoneInput from "src/modules/3rd-party-management/components/LocalizedPhoneInput";
import { useMutation } from "@tanstack/react-query";
import CommonService from "src/modules/3rd-party-management/apis/CommonService";
import { toast } from "react-toastify";

const ContactPerson = ({
  t,
  organization,
  setCompanyDetailsTabCompleted,
  goToPrevTab,
  contacts,
  setContacts,
  contactCount,
  setContactCount,
}) => {

  const [ titleDrops, setTitleDrops ] = useState([]);

  const {
    mutate      :   checkUserExistenceMutation
  } = useMutation({
    mutationFn: async (payload) => {
      const service = CommonService.getInstance();

      return await service.checkUserExistence(payload);
    }
  });

  useEffect(() => {
    const difference = contactCount - contacts.length;
    if (difference > 0) {
      const newContacts = Array(difference)
        .fill(null)
        .map((_, index) => ({
          id: `factory-${Date.now() + index}`,
          title : "Mr.",
          firstName: "",
          lastName : "",
          position: "",
          language: "",
          email: "",
          phoneNumber: "",
        }));
      setContacts(contacts.concat(newContacts));
    }
  }, [contactCount]);

  const updateLanguage = (index, newValue) => {
    setContacts((currentContacts) => {
      const newContacts = [...currentContacts];
      newContacts[index] = { ...newContacts[index], language: newValue };
      return newContacts;
    });
  };

  const onDeleteOffice = (index) => {
    const newContacts = contacts.filter((_, i) => i !== index);
    setContacts(newContacts);
    setContactCount((prevCount) => prevCount - 1);
  };

  const handleBack = () => {
    goToPrevTab(2);
  };

  const languages = (organization?.languages || []).map((lng) => {
    return {
      value : lng?.language?.id,
      label : t(lng.name),
      code  : lng.language_code,
    }
  });
  
  return (
    <>
      <AvForm
        className="needs-validation"
        onValidSubmit={() => {
            if(!contacts.filter((c) => c.emailValidationStatus !== 'valid').length){
                return setCompanyDetailsTabCompleted("contact-person");
            }
        }}
        id="contact_person">
        <Card className="wizard-steps-content-container">
          <CardBody className="p-0">
            <p className="sub-header medium">
              {t("Contact Person")}
            </p>

            {contacts.length > 0 ? (
                <>
                    {contacts.map((item, i) => {
                        return (
                            <Row className="mb-4" key={item.id}>
                            <Row className="mb-4">
                                <Col md="4" sm="4">
                                    <Label for={`firstName${i}`}>
                                    {t("First Name")}: 
                                    </Label>

                                    <AvGroup>
                                    <div className="d-flex flex-wrap position-relative">
                                        <ButtonDropdown className="position-absolute" isOpen={ titleDrops[i] } toggle={() => setTitleDrops((drops) => {
                                        drops[i] = !drops[i];

                                        return [...drops];
                                        })} style={{
                                        left: "0px",
                                        top: "0px",
                                        width: "94px",
                                        height: "34px"
                                        }}>
                                        <DropdownToggle outline split style={{ border: 'none', borderRight: '1px solid #282828' }}>
                                            <span style={{marginRight: '8px'}}>{ t(contacts[i].title || 'Mr.') }</span>
                                        </DropdownToggle>

                                        <DropdownMenu>
                                            <DropdownItem onClick={() => { contacts[i].title = 'Mr.' }}>
                                            { t('Mr.') }
                                            </DropdownItem>
                                            <DropdownItem onClick={() => { contacts[i].title = 'Mrs.' }}>
                                            { t('Mrs.') }
                                            </DropdownItem>
                                            <DropdownItem onClick={() => { contacts[i].title = 'Mx.' }}>
                                            { t('Mx.') }
                                            </DropdownItem>
                                        </DropdownMenu>
                                        </ButtonDropdown>

                                        <AvInput 
                                        id={`firstName${i}`}
                                        required
                                        name={`firstName${i}`}
                                        type="text"
                                        className="form-control"
                                        value={contacts[i]?.firstName}
                                        onChange={(e) => { contacts[i].firstName = e.target.value; }}
                                        style={{
                                            paddingLeft: '98px',
                                            flexBasis : '100%'
                                        }}
                                        />

                                        <AvFeedback style={{
                                        flexBasis : '100%'
                                        }}>{t("This field cannot be blank")}</AvFeedback>
                                    </div>
                                    </AvGroup>
                                </Col>

                                <Col md="4" sm="12">
                                <Label for="website">{t("Last Name")}: </Label>
                                <AvField
                                    name={`lastName${i}`}
                                    type="text"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                    required: { value: true },
                                    }}
                                    id={`lastName${i}`}
                                    onChange={(e) => {
                                    contacts[i].lastName = e.target.value;
                                    }}
                                    value={contacts[i]?.lastName}
                                />
                                </Col>

                                {/* Position */}
                                <Col md="4" sm="12">
                                <Label for="position">{t("Position")}: </Label>
                                <AvField
                                    name={`position${i}`}
                                    type="text"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                    required: { value: true },
                                    }}
                                    id={`position${i}`}
                                    onChange={(e) => {
                                    contacts[i].position = e.target.value;
                                    }}
                                    value={contacts[i]?.position}
                                />
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                {/* Languages */}
                                <Col md="4" sm="12">
                                <Label for="language">{t("Language")}: </Label>
                                <Select
                                    placeholder={t("Select") + "..."}
                                    classNamePrefix="select2-selection"
                                    id={`language${i}`}
                                    options={ languages }
                                    menuPortalTarget={document.body}
                                    onChange={(e) => {
                                    updateLanguage(i, e);
                                    }}
                                    value={contacts[i]?.language}
                                />
                                <AvField
                                    name={`language${i}`}
                                    placeholder=""
                                    type="hidden"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                    required: { value: true },
                                    }}
                                    value={contacts[i]?.language?.label}
                                />
                                </Col>

                                {/* Email */}
                                <Col md="4" sm="12">
                                <Label for="email">{t("Email")}: </Label>
                                <AvGroup>
                                <AvField
                                    name={`email${i}`}
                                    type="email"
                                    className="form-control"
                                    validate={{
                                    required: {
                                        value: true,
                                        errorMessage: t("This field cannot be blank"),
                                    },
                                    email: { errorMessage: t("Your email is invalid") }
                                    }}
                                    id={`email${i}`}
                                    onChange={(e) => {
                                    contacts[i].email = e.target.value;
                                    }}
                                    onBlur={(e) => {
                                        if(!e.target.value){
                                            setContacts((currentContacts) => {
                                                const newContacts = [...currentContacts];
                                                newContacts[i] = { ...newContacts[i], emailValidationStatus: null };
                                                return newContacts;
                                            });
                                            return;
                                        }

                                        if(contacts[i].emailValidationStatus !== 'in_process'){
                                            setContacts((currentContacts) => {
                                                const newContacts = [...currentContacts];
                                                newContacts[i] = { ...newContacts[i], emailValidationStatus: 'in_process' };
                                                return newContacts;
                                            });

                                            checkUserExistenceMutation({
                                                email : e.target.value
                                            }, {
                                                onSuccess: () => {
                                                    setContacts((currentContacts) => {
                                                        const newContacts = [...currentContacts];
                                                        newContacts[i] = { ...newContacts[i], emailValidationStatus: 'valid' };
                                                        return newContacts;
                                                    });
                                                },
                                                onError: () => {
                                                    setContacts((currentContacts) => {
                                                        const newContacts = [...currentContacts];
                                                        newContacts[i] = { ...newContacts[i], emailValidationStatus: 'invalid' };
                                                        return newContacts;
                                                    });
                                                }
                                            })
                                        }
                                    }}
                                    disabled={contacts[i].emailValidationStatus === 'in_process'}
                                    value={contacts[i]?.email}
                                />
                                    {contacts[i].emailValidationStatus === 'invalid' && (
                                        <AvFeedback valid={false} className='d-block'>
                                            {t('Email already exists')}
                                        </AvFeedback>
                                    )}
                                </AvGroup>
                                </Col>

                                {/* Phone Number */}
                                <Col md="4" sm="12">
                                <Label for="phone">{t("Phone Number")}: </Label>
                                <LocalizedPhoneInput
                                    country={ "us" }
                                    disableInitialCountryGuess={ false }
                                    disableCountryGuess={ false }
                                    inputClass="w-100"
                                    onChange={ (e) => {
                                    setContacts((currentContacts) => {
                                        const newContacts = [...currentContacts];
                                        newContacts[i] = { ...newContacts[i], phoneNumber: e };
                                        return newContacts;
                                    });
                                    }}
                                    value={ contacts[i]?.phoneNumber }
                                />
                                <AvField
                                    name={`phone${i}`}
                                    type="hidden"
                                    errorMessage={t("This field cannot be blank")}
                                    validate={{
                                    required: { value: true },
                                    }}
                                    value={ contacts[i]?.phoneNumber }
                                />
                                </Col>
                                
                                {/* Delete */}
                                <Col
                                md="2"
                                sm="12"
                                className="pt-4 mt-2"
                                >
                                <Button
                                    color="danger"
                                    onClick={() => onDeleteOffice(i)}
                                    outline
                                >
                                    {/* <RiDeleteBin5Line />  */}
                                    <span>{t("Delete Contact")} </span>
                                </Button>
                                </Col>
                            </Row>
                            </Row>
                        );
                    })}
                </>
            ) : (
                <div className="alert alert-warning">
                    <p className="m-0">{t('This list is empty')}</p>
                </div>
            )}

            <Row className="mt-4">
              <Col md="10"></Col>
              <Col md="2" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  color="primary"
                  onClick={() => {
                    setContactCount(contactCount + 1);
                  }} outline>
                  {" + " + t("Add A Contact Person")}
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row className="mt-2 mb-4">
          <FormGroup
            style={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "space-between",
            }}
            className="actions-buttons-container"
          >
            <Button color="primary" onClick={handleBack} outline>
              {t("Back")}
            </Button>
            <Button color="primary" type="submit" disabled={contacts.filter((c) => c.emailValidationStatus === 'in_process').length > 0}>
              {t("Next")}
            </Button>
          </FormGroup>
        </Row>
      </AvForm>
    </>
  );
};

const mapStatetoProps = (state) => {
  const { Organization } = state;
  return {
    organization  : Organization
  };
};

export default withNamespaces()(
  connect(
    mapStatetoProps,
    {}
  )
(memo(ContactPerson)));
