import { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'

const RadioList = ({
    t,
    name,
    data = [],
    isLoading,
    formatter,
    defaultValue,
    onFilterChange
}) => {
    const [value, setValue] = useState(null)
    const [items, setItems] = useState([])

    useEffect(() => {
        if (data && Array.isArray(data)) {
            const result = data.map((item) => {
                return {
                    id: item.id,
                    title: (formatter && formatter(item)) || ''
                }
            })

            result.unshift({
                id: 'all',
                title: t('All')
            })

            setItems(result)
        }
    }, [data])

    useEffect(() => {
        if (value !== null) {
            onFilterChange &&
                onFilterChange({
                    filterName: name,
                    value: value
                })
        }
    }, [value])

    useEffect(() => {
        setValue(defaultValue !== null && defaultValue !== undefined ? defaultValue : 'all')
    }, [defaultValue])

    return (
        <>
            {isLoading && (
                <div className='text-center'>
                    <Spinner animation='border' variant='primary' size='md' />
                </div>
            )}

            {!isLoading &&
                items.map((item, index) => {
                    return (
                        <div className='filter-item' key={index}>
                            <Form.Check
                                id={`${name}-item-${item.id}`}
                                type='radio'
                                checked={value === item.id}
                                onChange={() => {
                                    setValue(item.id)
                                }}
                                label={t(item.title)}
                            />
                        </div>
                    )
                })}
        </>
    )
}

export { RadioList }
