import { withNamespaces } from "react-i18next";
import { memo } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Col,
  Row,
  Button,
} from "reactstrap";
import { userRoll } from "../../../../../pages/Admin/administration/history-logs/constants";
import ClockOutlineIcon from "src/modules/data-protection/assets/images/clock-outline.svg";
import { generateDescription, generateAction} from "../list/components/table-filter/constants"

const ViewProcessingDirectoryModal = ({ t, modal, setModal, activity }) => {
  const toggle = () => setModal(!modal);

  const renderChanges = (changes) => {
    if (!changes) return null;
  
    const formatChange = (change, level = 0) => {
      if (Array.isArray(change)) {
        return change.map((item, index) => (
          <div key={index}  >
            {level === 0 ? item.title : `Subitem: ${item.title || `Item ${index + 1}`}`}
            {item.children && item.children.length > 0 && (
              <div>
                {formatChange(item.children, level + 1)}
              </div>
            )}
          </div>
        ));
      } else if (typeof change === 'object' && change !== null) {
        return Object.entries(change).map(([key, value], index) => (
          <div key={index}  >
            {level === 0 ? key : `Subitem: ${key}`} 
            {value.title ? `: ${value.title}` : `: Item ${index + 1}`}
            {value.children && value.children.length > 0 && (
              <div>
                {formatChange(value.children, level + 1)}
              </div>
            )}
          </div>
        ));
      }
      return change;
    };
  
    return Object.entries(changes).map(([key, value]) => (
      <Row key={key} className="pt-3" style={{borderTop:'1px solid #e0e0e0'}}>
        <Col sm={4}>
          <p className="fs-6" style={{ color: "#8A8DA3" }}>
            {key}
          </p>
        </Col>
        <Col sm={7}>
          <p className="fs-6">
            <strong>From:</strong>
            {formatChange(value.from)}
            <br />
            <strong>To:</strong>
            {formatChange(value.to)}
          </p>
        </Col>
      </Row>
    ));
  };
  
  const renderMetadata = (metadata) => {
    if (!metadata) return null;

    return (
      <Row className="pt-3" style={{borderTop:'1px solid #e0e0e0'}}>
        <Col sm={4}>
          <p className="fs-6" style={{ color: "#8A8DA3" }}>
            Changes
          </p>
        </Col>
        <Col sm={7}>
          <p className="fs-6">
            <strong>From:</strong> {metadata.from_name}
            <br />
            <strong>To:</strong> {metadata.to_name}
          </p>
        </Col>
      </Row>
    );
  };

  
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} style={{ minWidth: "700px" }}>
        <ModalHeader toggle={toggle} className="custom--processs-directory-modal-header">Processing Activity {activity?.id}</ModalHeader>
        <ModalBody>
          <Container className="px-4 pb-2 pt-2">
            <Row>
              <Col sm={3}>
                <p className="fs-6" style={{ color: "#8A8DA3" }}>
                {t("Activities")}
                </p>
              </Col>
              <Col sm={9}>
                <p className="fs-6 text-capitalize fw-bolder">{generateAction(t, activity?.action)}</p>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <p className="fs-6" style={{ color: "#8A8DA3" }}>
                {t("Event ID")}
                </p>
              </Col>
              <Col sm={9}>
                <p className="fs-6">{activity?.id}</p>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <p className="fs-6" style={{ color: "#8A8DA3" }}>
                  {t("Service Name")}
                </p>
              </Col>
              <Col sm={9}>
                <p className="fs-6">{t(activity?.section)}</p>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <p className="fs-6" style={{ color: "#8A8DA3" }}>
                  {t("User")}
                </p>
              </Col>
              <Col sm={9}>
                <p className="fs-6">{activity?.user_name}</p>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <p className="fs-6" style={{ color: "#8A8DA3" }}>
                  {t("User Role")}
                </p>
              </Col>
              <Col sm={9}>
                <p className="fs-6">{userRoll[activity?.user_role]}</p>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <p className="fs-6" style={{ color: "#8A8DA3" }}>
                {t("Date")}
                </p>
              </Col>
              <Col sm={9}>
                <p
                  style={{ color: "#8A8DA3" }}
                  className="d-flex align-items-center fs-6"
                >
                  <span className="pe-2">
                    <img src={ClockOutlineIcon} width={24} height={24} />
                  </span>
                  {activity?.created_at}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <p className="fs-6" style={{ color: "#8A8DA3" }}>
                  {t("Browser")}
                </p>
              </Col>
              <Col sm={9}>
                <p className="fs-6">{`${activity?.browser}`}</p>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <p className="fs-6" style={{ color: "#8A8DA3" }}>
                  {t("Description")}
                </p>
              </Col>
              <Col sm={9}>
                <p className="fs-6">{generateDescription(t, activity?.action, activity?.section, activity?.assetType)}</p>
              </Col>
            </Row>
                {activity?.action === "update_processing_activity_module_name" 
              ? renderMetadata(activity?.metadata)
              : renderChanges(activity?.metadata?.changes)}
            <Row>
              <div className="d-flex justify-content-end w-full mt-3">
                <Button
                  color="primary"
                  size="sm"
                  className="px-3"
                  onClick={toggle}
                >
                  {t("Ok")}
                </Button>
              </div>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default withNamespaces()(memo(ViewProcessingDirectoryModal));


