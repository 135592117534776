import { memo, useEffect, useRef, useState } from 'react';
import { Col, Row, Button, Progress } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { withNamespaces } from 'react-i18next';

import FileUploader from './Questions/FileUploader';
import Textbase from './Questions/Textbase';
import Checkbox from './Questions/Checkbox';
import Radiobox from './Questions/Radiobox';
import CountryList from './Questions/CountryList';
import CustomSelect from './Questions/CustomSelect';

import QuestionsLoadingPlaceholder from './Components/QuestionsLoadingPlaceholder';

import Accordion from 'react-bootstrap/Accordion';

import { QuestionnaireSectionsImages } from '../../constants/Common';

const Questionar = (props) => {
    const [ sectionsInit, setSectionsInit ] = useState(false);

    const [
        displayConfirmFilledInputsForm,
        setDisplayConfirmFilledInputsForm
    ] = useState(false);

    const [
        answersResultToSubmit,
        setAnswersResultToSubmit
    ] = useState({});

    const [
        sections,
        setSections
    ] = useState([]);

    const [
        activeSectionIndex,
        setActiveSectionIndex
    ] = useState(0);

    const [
        preFilledAnswersModel,
        setPreFilledAnswersModel
    ] = useState({});

    const { 
        loading,
        questionnaireId,
        questionnaireType, 
        questions, 
        onValidSubmit, 
        onChange,
        user,
        preFilledAnswers,
        resetFormModel,
        isFormSubmiting,
        settings
    } = props;

    let {
        confirmFilledInputsForm : confirmFilledInputsFormSettings,
        buttons
    } = settings || {};

    const formRef = useRef();

    const dropzoneAcceptedFiles = {
        accepted:
          "text/plain, , application/pdf, application/msword, application/vnd.ms-excel, application/vnd, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/*, audio/*, video/mp4, video/x-mpegURL, video/MP2T, video/3gpp, video/quicktime, video/x-msvideo, video/x-ms-wmv",
        description: [
          `${props.t(
            "You are allowed to upload limited file types"
          )}. ${props.t("For more information")}, ${props.t("visit")}`,
          <a href="https://diss-co.tech/faq-diss-co/"
            target="_blank"
          >{` ${props.t("FAQ")} `}</a>,
          props.t("page"),
        ],
    };

    const makeQuestionsAnswersReadyToSend = (answers) => {
		const result = [];

        questions.forEach((question) => {
            const questionAnswers = answers.question[question.id];

            let itemResult = {
                question    :   question.id,
                answer      :   []
            };

            if(questionAnswers){
                switch(question.type){
                    case 'textarea':
                    case 'text':
                    case 'date':
                            itemResult.answer = [{
                                title : questionAnswers
                            }];
                        break;

                    case 'checkbox':
                    case 'category': 
                        questionAnswers.forEach((eachCheckedAnswer) => {
                            const elRef = document.getElementById(`${questionnaireId}-question-${question.id}-checkbox-${eachCheckedAnswer}`);

                            itemResult.answer.push({
                                title   :   elRef.attributes['label'].value,
                                value   :   eachCheckedAnswer
                            });
                        });
                        break;
                    
                    case 'radiobox':
                            const elRef = document.getElementById(`${questionnaireId}-question-${question.id}-option-${questionAnswers}`);

                            if(elRef){
                                const radioType = elRef.attributes['datatype'].value;

                                if(radioType === 'date' || radioType === 'text'){
                                    let answerValue = '';

                                    const relInputRefId =  elRef.attributes['datarelinputref'].value;
                                    const relInputRef = document.getElementById(relInputRefId);
                                    if(relInputRef){
                                        answerValue = relInputRef?.value;
                                    }

                                    itemResult.answer = [{
                                        title   :   answerValue,
                                        value   :   questionAnswers
                                    }];
                                }
                                else{
                                    const resultAns = [{
                                        title       :   elRef.attributes['datatitle'].value,
                                        value       :   questionAnswers
                                    }];

                                    const questionOption = (question.options || []).find((opt) => {
                                        return Number.parseInt(opt.id) === Number.parseInt(questionAnswers);
                                    });

                                    if(questionOption && answers.sub_questions){
                                       
                                        const subQuestionsAnswers = answers.sub_questions[question.id] || [];

                                        if(questionOption.children && questionOption.children.length > 0 && subQuestionsAnswers[questionOption.id]){
                                            const children = [];
                                            const childrenAnswers = subQuestionsAnswers[questionOption.id];

                                            questionOption.children.forEach((optionChild) => {
                                                const childAnswers = childrenAnswers[optionChild.id];

                                                if(childAnswers){
                                                    if(optionChild.type === 'file_upload'){
                                                        children.push({
                                                            id      :   optionChild.id,
                                                            title   :   childAnswers[0]?.id,
                                                            type    :   'file_upload',
                                                            name    :   childAnswers[0]?.name
                                                        });
                                                    }
                                                    else if(optionChild.type === 'radiobox'){
                                                        children.push({
                                                            id      :   optionChild.id,
                                                            title   :   childAnswers,
                                                            type    :   'radiobox'
                                                        });
                                                    }
                                                    else{
                                                        children.push({
                                                            id      :   optionChild.id,
                                                            title   :   childAnswers[0],
                                                            type    :   optionChild.type
                                                        });
                                                    }
                                                }
                                                
                                            });

                                            resultAns[0].children = children;
                                        }
                                    }

                                    itemResult.answer = resultAns;
                                }
                            }
                        break;
                    case 'file_upload':
                            const uploadedFiles = (questionAnswers && Array.isArray(questionAnswers)) ? questionAnswers : [];

                            itemResult.answer = uploadedFiles.map((uploadedFile) => {
                                return {
                                    title : uploadedFile.id,
                                    name : uploadedFile.name
                                };
                            });
                        break;
                    case 'country_list':
                    case 'select':
                        itemResult.answer = [{
                            title   :   questionAnswers.label,
                            value   :   questionAnswers.value
                        }];
                        break;
                    default: break;
                }
            }

            result.push(itemResult);
        });

        return result;
	}

    const findQuestionPreFilledAnswers = (question) => {

        const questionIdToSearch = !question?.isReactSubQuestion ? question.id : question.parentQuestionId;

        const preFilledQuestionData = (preFilledAnswers && Array.isArray(preFilledAnswers)) ? preFilledAnswers.find((questionData) => {
            return questionData.id === questionIdToSearch;
        }) : null;

        if(preFilledQuestionData && preFilledQuestionData.answers){
            if(!question?.isReactSubQuestion){
                switch(question.type){
                    case 'textarea':
                    case 'text':
                    case 'date':
                        return (preFilledQuestionData.answers[0]?.title || question?.system_data) || '';

                    case 'checkbox':
                    case 'category':
                    case 'file_upload':
                    case 'country_list':
                    case 'select':
                        return preFilledQuestionData.answers;

                    case 'radiobox':
                        return preFilledQuestionData.answers[0];
                    default:
                        return null;
                }
            }
            else{
                const answer = preFilledQuestionData.answers[0];

                if(answer && answer?.children && Array.isArray(answer.children)){
                    let preFilledSubQuestionData = answer.children.find((subQuestionData) => {
                        return Number.parseInt(subQuestionData.id) === question.id;
                    });

                    if(preFilledSubQuestionData?.type === 'file_upload'){
                        return [{
                            title   :   preFilledSubQuestionData?.title,
                            name    :   preFilledSubQuestionData?.name
                        }];
                    }
                    else if(preFilledSubQuestionData?.type === 'radiobox'){
                        // just for now
                        const v = Number.parseInt(preFilledSubQuestionData?.title);
                        return {
                            title   :   v,
                            value   :   v
                        }
                    }

                    return preFilledSubQuestionData?.title;
                }
            }
        }

        return null;
    }

    const groupQuestionsBySection = () => {

        const groupedQuestionsBySection = [];

        const findSection = (sectionName) => {
            return groupedQuestionsBySection.find((section) => {
                return section.name === sectionName;
            });
        }

        for(let i = 0; i < questions.length; i++){
            const question = questions[i];
            let section = findSection(question.section || '');

            if(!section){
                section = {
                    name : question.section || '',
                    items : []
                }
                groupedQuestionsBySection.push(section);
            }

            section.items.push(question);
        }

        return groupedQuestionsBySection;
    }

    const groupQuestionsBySubSection = (items) => {

        const result = [];

        const findSection = (sectionName) => {
            return result.find((section) => {
                return section.name === sectionName;
            });
        }

        for(let i = 0; i < items.length; i++){
            const question = items[i];
            let subSection = findSection(question.sub_section || '');

            if(!subSection){
                subSection = {
                    name : question.sub_section || '',
                    items : []
                }
                result.push(subSection);
            }

            subSection.items.push(question);
        }

        return result;
    }

    const formStateChanged = () => {        
        onChange && formRef?.current && onChange({
            payload         :   {
                "questionnaireType"		:	questionnaireType,
                "questionAnswers"	    :	makeQuestionsAnswersReadyToSend(formRef.current.getValues()),
            },
            isCompleted     :   false
        });
    }

    const renderQuetionByType = (question, name = null, index) => {
        switch(question.type){
            case 'textarea':
            case 'text':
            case 'date':
                    return <Textbase id={question.id} 
                                questionIndex={index}
                                name={name}
                                elRefIdPrefix={questionnaireId}
                                title={question.title}
                                key={question.id}
                                type={question.type}
                                // isMandatory={question.is_mandatory}
                                isMandatory={false}
                                aiSuggestion={question?.suggestion} 
                                helpText={question?.info} 
                                defaultValue={findQuestionPreFilledAnswers(question)}
                                onTypingStoped={() => {
                                    formStateChanged();
                                }}
                                onChange={(e) => {
                                    let answered = (e.target.value && e.target.value.length > 0) ? true : false;

                                    setSections((sections) => {
                                        const sectionIndex = sections.findIndex((s) => s.name === question.section);
                                        if(sectionIndex > -1){
                                            const questionIndex = sections[sectionIndex].items.findIndex((q) => q.id === question.id);

                                            if(questionIndex > -1){
                                                sections[sectionIndex].items[questionIndex].answered = answered;
                                            }
                                        }

                                        return [
                                            ...sections
                                        ];
                                    });
                                }} />
            case 'file_upload':
                    return <FileUploader id={question.id} 
                                questionIndex={index}
                                name={name}
                                elRefIdPrefix={questionnaireId}
                                title={question.title} 
                                key={question.id} 
                                acceptedFiles={dropzoneAcceptedFiles}
                                userToken={user.token}
                                defaultValue={findQuestionPreFilledAnswers(question)}
                                helpText={question?.info}
                                onNewFileUploaded={() => {
                                    setSections((sections) => {
                                        const sectionIndex = sections.findIndex((s) => s.name === question.section);
                                        if(sectionIndex > -1){
                                            const questionIndex = sections[sectionIndex].items.findIndex((q) => q.id === question.id);

                                            if(questionIndex > -1){
                                                sections[sectionIndex].items[questionIndex].answered = true;
                                            }
                                        }

                                        return [
                                            ...sections
                                        ];
                                    });

                                    formStateChanged();
                                }}
                                onFileDeleted={() => {
                                    setSections((sections) => {
                                        const sectionIndex = sections.findIndex((s) => s.name === question.section);
                                        if(sectionIndex > -1){
                                            const questionIndex = sections[sectionIndex].items.findIndex((q) => q.id === question.id);

                                            if(questionIndex > -1){
                                                sections[sectionIndex].items[questionIndex].answered = false;
                                            }
                                        }

                                        return [
                                            ...sections
                                        ];
                                    });

                                    formStateChanged();
                                }} />
            case 'checkbox':
            case 'category':  
                    return <Checkbox id={question.id}
                                questionIndex={index}
                                name={name}
                                elRefIdPrefix={questionnaireId}
                                title={question.title}
                                key={question.id}
                                // isMandatory={question.is_mandatory}
                                isMandatory={false}
                                options={question.options} 
                                helpText={question?.info} 
                                onChange={() => formStateChanged()}
                                onNoOptionSelected={() => { 
                                    setSections((sections) => {
                                        const sectionIndex = sections.findIndex((s) => s.name === question.section);
                                        if(sectionIndex > -1){
                                            const questionIndex = sections[sectionIndex].items.findIndex((q) => q.id === question.id);

                                            if(questionIndex > -1){
                                                sections[sectionIndex].items[questionIndex].answered = false;
                                            }
                                        }

                                        return [
                                            ...sections
                                        ];
                                    });
                                }}
                                onAnyOptionSelected={() => {
                                    setSections((sections) => {
                                        const sectionIndex = sections.findIndex((s) => s.name === question.section);
                                        if(sectionIndex > -1){
                                            const questionIndex = sections[sectionIndex].items.findIndex((q) => q.id === question.id);

                                            if(questionIndex > -1){
                                                sections[sectionIndex].items[questionIndex].answered = true;
                                            }
                                        }

                                        return [
                                            ...sections
                                        ];
                                    });
                                }} />  
            case 'radiobox':
                    return <Radiobox id={question.id}
                                questionIndex={index}
                                name={name}
                                elRefIdPrefix={questionnaireId}
                                title={question.title}
                                key={question.id}
                                // isMandatory={question.is_mandatory}
                                isMandatory={false}
                                options={question.options}
                                defaultValue={findQuestionPreFilledAnswers(question)} 
                                helpText={question?.info} 
                                questionRendererFunc={renderQuetionByType} 
                                onChange={ () => formStateChanged() }
                                onNoOptionSelected={() => { 
                                    setSections((sections) => {
                                        const sectionIndex = sections.findIndex((s) => s.name === question.section);
                                        if(sectionIndex > -1){
                                            const questionIndex = sections[sectionIndex].items.findIndex((q) => q.id === question.id);

                                            if(questionIndex > -1){
                                                sections[sectionIndex].items[questionIndex].answered = false;
                                            }
                                        }

                                        return [
                                            ...sections
                                        ];
                                    });
                                }}
                                onAnyOptionSelected={() => {
                                    setSections((sections) => {
                                        const sectionIndex = sections.findIndex((s) => s.name === question.section);
                                        if(sectionIndex > -1){
                                            const questionIndex = sections[sectionIndex].items.findIndex((q) => q.id === question.id);

                                            if(questionIndex > -1){
                                                sections[sectionIndex].items[questionIndex].answered = true;
                                            }
                                        }

                                        return [
                                            ...sections
                                        ];
                                    });
                                }} />
            case 'country_list':
                    return <CountryList id={question.id}
                                questionIndex={index}
                                name={name}
                                elRefIdPrefix={questionnaireId}
                                title={question.title}
                                key={question.id}
                                // isMandatory={question.is_mandatory}
                                isMandatory={false}
                                options={question.options}
                                defaultValue={findQuestionPreFilledAnswers(question)} 
                                helpText={question?.info} 
                                onChange={(newSelectedItem) => {
                                    setSections((sections) => {
                                        const sectionIndex = sections.findIndex((s) => s.name === question.section);
                                        if(sectionIndex > -1){
                                            const questionIndex = sections[sectionIndex].items.findIndex((q) => q.id === question.id);

                                            if(questionIndex > -1){
                                                sections[sectionIndex].items[questionIndex].answered = (newSelectedItem && newSelectedItem?.value) || false;
                                            }
                                        }

                                        return [
                                            ...sections
                                        ];
                                    });

                                    formStateChanged();
                                }} />
            case 'select':
                    return <CustomSelect id={question.id}
                                questionIndex={index}
                                name={name}
                                elRefIdPrefix={questionnaireId}
                                title={question.title}
                                key={question.id}
                                // isMandatory={question.is_mandatory}
                                isMandatory={false}
                                options={question.options}
                                defaultValue={findQuestionPreFilledAnswers(question)} 
                                helpText={question?.info} 
                                onChange={(newSelectedItem) => {
                                    setSections((sections) => {
                                        const sectionIndex = sections.findIndex((s) => s.name === question.section);
                                        if(sectionIndex > -1){
                                            const questionIndex = sections[sectionIndex].items.findIndex((q) => q.id === question.id);

                                            if(questionIndex > -1){
                                                sections[sectionIndex].items[questionIndex].answered = (newSelectedItem && newSelectedItem?.value) || false;
                                            }
                                        }

                                        return [
                                            ...sections
                                        ];
                                    });

                                    formStateChanged();
                                }} />
            default:
                return null;
        }
    }

    const renderQuestionsList = (items) => {
        const subSections = groupQuestionsBySubSection(items);

        if(subSections.length === 1){
            return (
                <Row>
                    {
                        subSections[0].items.map((question, index) => {
                            return (
                                <Col sm='12' md='12' lg='12' className='mb-2' key={index}>
                                    { renderQuetionByType(question, null, index) }
                                </Col>
                            );
                        })
                    }
                </Row>
            );
            
        }
        else{
            return (
                subSections.map((section) => {
                    return (
                        <div className='sub-section'>
                            <div className='sub-section-title'>
                                <h4>{ props.t(section.name) }</h4>
                            </div>
                            <div className='sub-section-items'>
                                <Row>
                                    {
                                        section.items.map((question, index) => {
                                            return (
                                                <Col sm='12' md='12' lg='12' className='mb-2' key={index}>
                                                    { renderQuetionByType(question) }
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>
                            </div>
                        </div>
                    )
                })
            );
        }
    }

    const isQuestionAnswered = (question) => {
        let answered  = false;

        const preFilledQuestionData = (preFilledAnswers && Array.isArray(preFilledAnswers)) ? preFilledAnswers.find((questionData) => {
            return questionData.id === question.id;
        }) : null;

        if(preFilledQuestionData && Array.isArray(preFilledQuestionData.answers) && preFilledQuestionData.answers.length > 0){
            answered = true;
        }

        return answered;
    }

    const renderQuestionnaireBody = () => {
        return (
            <div className={`p-2`}>
                <AvForm id={questionnaireId} className={`needs-validation questionnaire-form  ${props.className}`} onValidSubmit={(e, values) => handleSubmit(values)} model={preFilledAnswersModel} ref={(ref) => formRef.current = ref}>
                    <Row>
                        <Col sm={12}>
                            <Accordion defaultActiveKey={activeSectionIndex}>
                                {
                                    sections.map((section, index) => {
                                        const percent = ((section.items.filter((q) => q.answered).length / section.items.length) * 100).toFixed(0);

                                        return (
                                            <Accordion.Item onClick={() => setActiveSectionIndex(index)} className={activeSectionIndex === index ? 'active' : ''} eventKey={index} key={index}>
                                                <Accordion.Header>
                                                    <div className="section-header">
                                                        <div>
                                                            <img 
                                                                src={QuestionnaireSectionsImages[`${(section.name.toString()).replaceAll(' ', '_').replaceAll('&','and').replaceAll(',','').replaceAll('-', '_').toLowerCase()}`]} 
                                                                alt="" 
                                                                style={{marginRight: '12px', width: '24px', marginLeft: '16px'}}
                                                            />

                                                            <span>
                                                                {`${index + 1}. ${props.t(section.name)}`}
                                                            </span>
                                                        </div>

                                                        <div className="completed-percent">
                                                            <Progress className={percent >= 100 ? 'completed' : 'incompleted'} color="success" value={percent} />
                                                            <span className='percent-title'>
                                                                {percent >= 100 ? props.t('Completed') : `${percent}%`}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                
                                                <Accordion.Body>
                                                    { renderQuestionsList(section.items) }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    })
                                }
                            </Accordion>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="12" className="d-flex justify-content-between mt-2 mb-2">
                            {
                                buttons?.back?.enabled && (
                                    <Button
                                        onClick={() => {
                                            if(buttons.back?.onClick){
                                                buttons.back?.onClick();
                                            }
                                        }}
                                        disabled={isFormSubmiting}
                                        color="btn btn-secondary tpdd-btn"
                                        type="button">
                                        {props.t(buttons.back?.title || "Back")}
                                    </Button>
                                )
                            }

                            <Button
                                color="btn btn-primary tpdd-btn"
                                type="submit"
                                disabled={!buttons?.submit?.enabled || !questions.length || isFormSubmiting}>
                                {props.t(buttons?.submit?.title || "Save")}
                            </Button>
                        </Col>
                    </Row>
                </AvForm>
            </div>
        );
    }

    const handleSubmit = (values) => {
        const butifiedQuestionsAnswers = {
            "questionnaireType"		:	questionnaireType,
			"questionAnswers"	    :	makeQuestionsAnswersReadyToSend(values)
        };

        if(confirmFilledInputsFormSettings?.enabled){
            setAnswersResultToSubmit(butifiedQuestionsAnswers);

            setDisplayConfirmFilledInputsForm(true);
        }
        else{
            if(onValidSubmit){
                onValidSubmit(butifiedQuestionsAnswers);
            }
        }
    }

    const makePreFilledAnswersModelReady = () => {
        const result = {};

        questions.forEach((question) => {
            const answer = findQuestionPreFilledAnswers(question);
            switch(question.type){
                case 'checkbox':
                case 'category':
                        const checkedItems = (answer && Array.isArray(answer) ? answer : []).map((ans) => {
                            return ans?.value;
                        });
                        Object.assign(result, {
                            [`question[${question.id}]`] : checkedItems
                        });
                    break;
                case 'radiobox':
                        Object.assign(result, {
                            [`question[${question.id}]`] : answer?.value
                        });
                    break;
                default: break;
            }
        });

        setPreFilledAnswersModel(result);
    }

    useEffect(() => {
        if(resetFormModel === true){
            if(sections.length > 1){
                setActiveSectionIndex(0);
            }
            else{
                formRef.current.reset();
            }
        }
    }, [resetFormModel]);

    useEffect(() => {
        if(!loading && questions && questions.length > 0){
            makePreFilledAnswersModelReady();

            const groupedQuestions = groupQuestionsBySection().map((group) => {
                return {
                    ...group,
                    items : group.items.map((item) => {
                        return {
                            ...item,
                            answered    :   isQuestionAnswered(item)
                        }
                    })
                }
            });

            setSections(groupedQuestions);
        }
    }, [loading]);

    useEffect(() => {
        if(sections){
            sections.length > 1 && setActiveSectionIndex(0);

            if(sectionsInit){
                if(questions.length > 0){
                    let totalAnsweredQuestions = 0;

                    sections.forEach((section) => {
                        section.items.forEach((item) => {
                            totalAnsweredQuestions += item.answered ? 1 : 0;
                        });
                    });

                    if(totalAnsweredQuestions >= questions.length){
                        onChange && formRef?.current && onChange({
                            payload         :   {
                                "questionnaireType"		:	questionnaireType,
                                "questionAnswers"	    :	makeQuestionsAnswersReadyToSend(formRef.current.getValues()),
                            },
                            isCompleted     :   true
                        });
                    }
                }
            }
            else{
                setSectionsInit(true);
            }
        }
    }, [sections]);

	return (
        <>
            {
                !loading && sections.length ? (
                    <>{ renderQuestionnaireBody() }</>
                ) : (
                    <QuestionsLoadingPlaceholder />
                )
            }
        </>
	);
};

export default withNamespaces()(memo(Questionar));
