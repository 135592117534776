import { withNamespaces } from "react-i18next";
import { Row, Col, CardBody, Card, CardTitle } from "reactstrap";
import { useEffect, useState } from "react";
import Select from 'react-select';
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
	Container
} from 'reactstrap';

import DashboardService from "../../../apis/DashboardService";

import DownloadStatistics from './components/DownloadStatistics';
import RisksMap from './components/RiskMap';
import Widget from "./components/Widget";

import { Radar } from "react-chartjs-2";
import { Polar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import ReactApexChart from "react-apexcharts";

import RiskMatrix from "src/modules/3rd-party-management/components/RisksAndTasks/risks/matrix";

import {
	SuppliersStatuses,
	TasksStatuses
} from 'src/modules/3rd-party-management/constants';
import PageHeader from "src/modules/3rd-party-management/components/page-header";

const Dashboard = (props) => {
    const {
        t
    } = props;

	const [ thirdPartiesCount, setThirdpartiesCount ] = useState(0);
	const [ riskAssessmentCount, setRiskAssessmentCount ] = useState(0);
	const [ statusesList, setStatusesList ] = useState([]);
	const [ countriesList, setCountriesList ] = useState([]);
	const [ riskRateCategory, setRiskRateCategory ] = useState(null);
	const [ suppliersScore, setSuppliersScore ] = useState(null);
	const [ suppliersByDate, setSuppliersByDate ] = useState(null);
	const [ myTasks, setMyTasks ] = useState(null);
	const [ riskRates, setRiskRates] = useState(null);
	const [ gotReports, setGotReports ] = useState(null);
	const [ dueDiligenceReports, setDueDiligenceReports ] = useState(null);
	const [ riskRateMatrix, setRiskRateMatrix ] = useState(null);
	const [ countriesRatings, setCountriesRatings ] = useState([]);
	
	const [dateFilter, setDateFilter] = useState(null);

    const handleFetchDashboardQuery = useQuery({
		queryKey: ["3rd-party-management-fetch-admin-dashboard-query"],
		queryFn: async () => {
		  const service = DashboardService.getInstance();
	
		  return await service.fetch({
			questionnaire_name	:	"tpdd",
			date				: 	dateFilter?.value
		  });
		},
		cacheTime: 0,
		enabled: false,
		onError: (error) => {
		  if (process.env.NODE_ENV === 'development') console.error(error);
	
		  toast(t("An error occurred while fetching dashboard data."), {
			type: "error",
		  });
		},
	});

    const rendeDateFilterOptions = () => {
		const filterOptions = [
			{
				value: 'last_year',
				label: t('Last year'),
			},
			{
				value: 'this_year',
				label: t('This year'),
			},
			{
				value: 'last_three_month',
				label: t('Last three month'),
			},
			{
				value: 'this_month',
				label: t('This month'),
			},
		];

		return (
			<Col lg="9">
				<Select
					required
					isClearable={true}
					name='sortByDate'
					classNamePrefix='select2-selection'
					options={filterOptions}
					value={dateFilter}
					onChange={(e) => setDateFilter(e)}
					placeholder={t('Filter by date')}
					style={{
						width: '200px'
					}}
					className="mr-2"
				/>
			</Col>
		);
	}

    useEffect(() => {
		handleFetchDashboardQuery.refetch()
	}, [dateFilter]);

	useEffect(() => {
		const { data } = handleFetchDashboardQuery;

		if(data){
			setStatusesList(data?.statuses || []);
			setCountriesList(data?.allCountries || []);
			setThirdpartiesCount(data?.thirdParties || 0);
			setRiskRateCategory(data?.riskRateCategory || null)
			setSuppliersScore(data?.suppliersScore || null);
			setSuppliersByDate(data?.suppliersByDate || null);
			setMyTasks(data?.myTasks || null);
			setRiskRates(data?.riskRate || null);
			setGotReports(data?.reportsByDate || null);
			setDueDiligenceReports(data?.dueDiligenceReports || null);
			setRiskAssessmentCount(data?.riskAssessmentCount || 0);
			setRiskRateMatrix(data?.riskRateMatrix || null);
			setCountriesRatings(data?.rating || [])
		}
	}, [handleFetchDashboardQuery.data]);

	useEffect(() => {
		handleFetchDashboardQuery.refetch();
	}, []);

	const loadingDashboard = handleFetchDashboardQuery.isLoading || handleFetchDashboardQuery.isFetching;

	const convertHexToRGBA = (hexCode, opacity = 1) => {  
		let hex = hexCode.replace('#', '');
		
		if (hex.length === 3) {
			hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
		}    
		
		const r = parseInt(hex.substring(0, 2), 16);
		const g = parseInt(hex.substring(2, 4), 16);
		const b = parseInt(hex.substring(4, 6), 16);
		
		/* Backward compatibility for whole number based opacity values. */
		if (opacity > 1 && opacity <= 100) {
			opacity = opacity / 100;   
		}
	
		return `rgba(${r},${g},${b},${opacity})`;
	};

	const createColorFromString = (str) => {
		const hashCode = (str) => {
			var hash = 0;
			for (var i = 0; i < str.length; i++) {
			   hash = str.charCodeAt(i) + ((hash << 5) - hash);
			}
			return hash;
		} 
	
		const intToRGB = (i) => {
			var c = (i & 0x00FFFFFF)
				.toString(16)
				.toUpperCase();
		
			return "00000".substring(0, 6 - c.length) + c;
		}

		return `#${intToRGB(hashCode(str))}`;
	}

	const renderWidgetsList = () => {
		if(loadingDashboard || !statusesList.length){
			return (
				<div className="mb-4">
					<Row className="mb-3">
						<Col lg="4">
							<div className="dt-field dt-skeleton" style={{width: '100%', height: '120px'}}></div>
						</Col>
						<Col lg="4">
							<div className="dt-field dt-skeleton" style={{width: '100%', height: '120px'}}></div>
						</Col>
						<Col lg="4">
							<div className="dt-field dt-skeleton" style={{width: '100%', height: '120px'}}></div>
						</Col>
					</Row>
					<Row>
						<Col lg="4">
							<div className="dt-field dt-skeleton" style={{width: '100%', height: '120px'}}></div>
						</Col>
						<Col lg="4">
							<div className="dt-field dt-skeleton" style={{width: '100%', height: '120px'}}></div>
						</Col>
						<Col lg="4">
							<div className="dt-field dt-skeleton" style={{width: '100%', height: '120px'}}></div>
						</Col>
					</Row>
				</div>
            );
		}

		const widgets = [];

		for (const item in statusesList) {
			widgets.push({
				amount	:	statusesList[item].amount,
				title	: 	SuppliersStatuses[statusesList[item]?.key]?.title,
				icon	: 	null,
				chart: {
					show	:	true,
					percent : 	statusesList[item]?.percent || 0
				},
				color	:	convertHexToRGBA(SuppliersStatuses[statusesList[item]?.key]?.color, 0.2)
			});
		}

		return (
			<>
				<Row>
					<Col lg={4}>
						<Widget {...{
							amount	:	thirdPartiesCount,
							title	: 	'Third Parties',
							icon	: 	'ri-stack-line'
						}} />
					</Col>
					<Col lg={4}>
						<Widget {...{
							amount	:	riskAssessmentCount,
							title	: 	'Risk Assessments',
							icon	: 	'ri-stack-line'
						}} />
					</Col>
					{
						widgets.length > 0 && (
							<Col lg={4}>
								<Widget {...widgets[0]} />
							</Col>
						)
					}
				</Row>
				<Row>
					{
						widgets.slice(1).map((widgetData) => {
							return (
								<Col lg={4}>
									<Widget {...widgetData} />
								</Col>
							)
						})
					}
				</Row>
			</>
		);
	};

	const renderRiskRateCategoryChart = () => {
		if(loadingDashboard){
			return (
				<Card>
					<CardBody>
						<div className="dt-field dt-skeleton mb-2" style={{width: '40%'}}></div>
						<div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
					</CardBody>
				</Card>
			);
		}

		const options = {
			legend: {
				position : 'bottom',
				labels : {
					usePointStyle : true
				}
			}
		};

		const labels = [
			"Strategic threat",
            "Operational threat", 
            "Financial threat", 
            "Employees and management", 
            "Other",
            "N/A"
		];

		const datasets = [];

        Object.keys(riskRateCategory || {}).slice(0, 7).forEach((itemName) => {
            const itemData = riskRateCategory[itemName];
			const color = createColorFromString(itemName);

			const datasetObject = {
				label						:	itemName,
                backgroundColor				: 	convertHexToRGBA(color, 0.2),
                borderColor					: 	color,
                pointBackgroundColor		: 	color,
                pointBorderColor			: 	"#fff",
                pointHoverBackgroundColor	: 	"#fff",
                pointHoverBorderColor		:	color,
				data : []
			};

			labels.forEach((label) => {
				datasetObject.data.push(itemData[label]?.amount);
			});

			datasets.push(datasetObject);
        });

		return(
			<Card>
				<CardBody>
					<CardTitle>
						<strong>{ t('Top 7 Risk Categories') }</strong>
					</CardTitle>
					<Radar height={220} data={{
						labels		:	labels.map((label) => t(label)),
						datasets	:	datasets
					}} options={options}/>
				</CardBody>
			</Card>
		);
	}

	const renderSuppliersScoreChart = () => {
		if(loadingDashboard || !suppliersScore){
			return (
				<Card>
					<CardBody>
						<div className="dt-field dt-skeleton mb-2" style={{width: '40%'}}></div>
						<div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
					</CardBody>
				</Card>
			);
		}

		const dataSetData = [];

		const options = {
			legend: {
				position : 'bottom',
				labels : {
					usePointStyle : true
				}
			}
		};

		const labels = [
			t("Less than 3"),
			t("3-6"),
			t("6-8"),
			t("More than 8")
		];

		const colors = [
			"#FF3D60",
			"#FCB92C",
			"#5664D2",
			"#1CBB8C"
		];

		for(const scoreName in suppliersScore){
			dataSetData.push(suppliersScore[scoreName]);
		}

		const data = {
			datasets	:	[{
				data				: 	dataSetData,
				backgroundColor		: 	colors,
				label				: 	"Supplier's Score",
				hoverBorderColor	:	"#fff"
			}],
			labels		:	labels
		};

		return (
			<Card>
				<CardBody>
					<div className="mb-4">
						<CardTitle>
							{ t("Supplier's Score") }
						</CardTitle>
					</div>
					<div className="mb-3">
						<Polar height={200} data={ data } options={ options }/>
					</div>
				</CardBody>
			</Card>
		);
	}

	const renderSuppliersPerMonthChart = () => {
		if(loadingDashboard || !suppliersByDate){
			return (
				<Card>
					<CardBody>
						<div className="dt-field dt-skeleton mb-2" style={{width: '40%'}}></div>
						<div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
					</CardBody>
				</Card>
			)
		}

		const dataSetData = [];

		const statuses = {
			"added"		:	{
				title	:	'Added',
				color	:	'#414FBD'
			},
			"total"		:	{
				title	:	'Total',
				color	:	'#3EC295'
			},
			"deleted"	:	{
				title	:	'Deleted',
				color	:	'#FCBD36'
			}
		};

		const {
			dates : labels,
			data,
			text
		} = suppliersByDate;

		let maxValue = 0;

		for(const keyName in data){
			const color = statuses[keyName]?.color;

			const labelData = [];

			for (let labelIndex = 0; labelIndex < labels.length; labelIndex++) {
				const label = labels[labelIndex];
				
				const val = data[keyName][label] || 0;

				labelData.push(val);

				if(val > maxValue){
					maxValue = val;
				}
			}

			dataSetData.push({
				label						:	t(statuses[keyName]?.title),
				fill						:	true,
				lineTension					:	0.5,
				backgroundColor				:	convertHexToRGBA(color, 0.2),
				borderColor					:	color,
				borderCapStyle				:	'butt',
				borderDash					:	[],
				borderDashOffset			:	0.0,
				borderJoinStyle				:	'miter',
				pointBorderColor			:	color,
				pointBackgroundColor		:	color,
				pointBorderWidth			:	1,
				pointHoverRadius			:	5,
				pointHoverBackgroundColor	:	color,
				pointHoverBorderColor		:	"#fff",
				pointHoverBorderWidth		:	2,
				pointRadius					:	1,
				pointHitRadius				:	10,
				data						:	labelData
			});
		}

		const options = {
			legend: {
				position : 'bottom',
				labels : {
					usePointStyle : true
				}
			},
			scales: {
				yAxes: [{
					ticks: {
						max			: maxValue + 1,
						min			: 0,
						stepSize	: 1
					}
				}]
			}
		}

		return (
			<Card>
				<CardBody>
					<div className="mb-4">
						<CardTitle>
							{ t(`Suppliers Per Day`) }
						</CardTitle>
					</div>
					<div className="mb-3">
						<Line height={200} data={{
							labels		: 	labels,
							datasets	:	dataSetData
						}} options={options} />
					</div>
				</CardBody>
			</Card>
		);
	}

	const renderMyTasksChart = () => {
		if(loadingDashboard || !myTasks){
			return (
				<Card>
					<CardBody>
						<div className="dt-field dt-skeleton mb-2" style={{width: '40%'}}></div>
						<div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
					</CardBody>
				</Card>
            );
		}

		const options = {
			labels: [],
			plotOptions: {
			  pie: {
				donut: {
				  size: "75%",
				},
			  },
			},
			dataLabels: {
			  enabled: true
			},
			legend: {
			  show: true,
			  position: 'bottom'
			},
			colors: []
		};

		const series = [];
		const labels = [];
		const colors = [];

		for(const index in myTasks){
			series.push(myTasks[index]?.amount);
			labels.push(t(TasksStatuses[index]?.title));
			colors.push(TasksStatuses[index]?.color);
		}

		return (
			<Card>
				<CardBody>
					<div className="mb-4">
						<CardTitle>
							<div style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}>
								<div>
									{t('Tasks')}
								</div>
							</div>
						</CardTitle>
					</div>

					<div className="mb-4">
						<div className="apex-charts mb-3">
							<ReactApexChart
								options={{
									...options,
									labels : labels,
									colors : colors
								}}
								series={ series }
								type="donut"
								height="255"
							/>
						</div>
					</div>
				</CardBody>
			</Card>
		);
	};

	const renderDueDiligenceChart = () => {
		if(loadingDashboard || !dueDiligenceReports){
			return (
				<Card>
					<CardBody>
						<div className="dt-field dt-skeleton mb-2" style={{width: '40%'}}></div>
						<div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
					</CardBody>
				</Card>
            );
		}

		const statuses = {
			missing		:	{
				title	:	'Missing',
				color	:	'#F693FF'
			},
			received	:	{
				title	:	'Received ',
				color	:	'#4CD4AC'
			},
			overdue		:	{
				title	:	'Overdue',
				color	:	'#FFEE93'
			}
		}

		const options = {
			labels: [],
			plotOptions: {
			  pie: {
				donut: {
				  size: "75%",
				},
			  },
			},
			dataLabels: {
			  enabled: true
			},
			legend: {
			  show: true,
			  position: 'bottom'
			},
			colors: []
		};

		const series = [];
		const labels = [];
		const colors = [];

		for(const statusKey in statuses){
			series.push(parseInt(dueDiligenceReports[statusKey] || 0));
			labels.push(t((statuses[statusKey]?.title).trim()));
			colors.push(statuses[statusKey]?.color);
		}
		
		return (
			<Card>
				<CardBody>
					<div className="mb-4">
						<CardTitle>
							<div style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}>
								<div>
									{t('Due Diligence Reports')}
								</div>
							</div>
						</CardTitle>
					</div>

					<div className="mb-4">
						<div className="apex-charts mb-3">
							<ReactApexChart
								options={{
									...options,
									labels : labels,
									colors : colors
								}}
								series={ series }
								type="donut"
								height="270"
							/>
						</div>
					</div>
				</CardBody>
			</Card>
		);
	}

	const renderRiskLevelsChart = () => {
		if(loadingDashboard || !riskRates){
			return (
				<Card>
					<CardBody>
						<div className="dt-field dt-skeleton mb-2" style={{width: '40%'}}></div>
						<div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
					</CardBody>
				</Card>
            );
		}

		const options = {
			labels: [],
			plotOptions: {
			  pie: {
				donut: {
				  size: "75%",
				},
			  },
			},
			dataLabels: {
			  enabled: true
			},
			legend: {
			  show: true,
			  position: 'bottom'
			},
			colors: []
		};

		const series = [];
		const labels = [];
		const colors = [];

		for(const index in riskRates){
			series.push(riskRates[index].amount);
			labels.push(t(riskRates[index].title));
			colors.push(`#${riskRates[index].color}`);
		}

		return (
			<Card>
				<CardBody>
					<div className="mb-4">
						<CardTitle>
							<div style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}>
								<div>
									{t('Third Party Risk Level')}
								</div>
							</div>
						</CardTitle>
					</div>

					<div className="mb-4">
						<div className="apex-charts mb-3">
							<ReactApexChart
								options={{
									...options,
									labels : labels,
									colors : colors
								}}
								series={ series }
								type="donut"
								height="255"
							/>
						</div>
					</div>
				</CardBody>
			</Card>
		);
	}

	const renderReceivedReportsByDataChart = () => {
		if(loadingDashboard || !gotReports){
			return (
				<Card>
					<CardBody>
						<div className="dt-field dt-skeleton mb-2" style={{width: '20%'}}></div>
						<div className="dt-field dt-skeleton" style={{width: '100%', height: '320px'}}></div>
					</CardBody>
				</Card>
            );
		}

		const names = [];
		const amounts = [];
		const colors = [];

		const {
			dates : labels,
			data,
			text
		} = gotReports;

        let totalCount = 0;

		for(const title in data){
			names.push(title);
			
			const itemData = {
				data : []
			};

            let count = 0;

			labels.forEach((label) => {
				itemData.data.push(parseInt(data[title][label]));
                count += parseInt(data[title][label]);
			});

            totalCount =+ count;

			amounts.push(itemData);

			colors.push(createColorFromString(title));
		}

		return (
			<Card>
				<CardBody>
					<div className="mb-4">
						<CardTitle>
							{ t(`Reports Received Per Day`) }
						</CardTitle>
					</div>
					<div className="mb-3">
						<ReactApexChart
							options={{
								colors : colors,
								legend: {
									show: false,
								},
								chart: {
									toolbar: {
										show: false,
									},
								},
								plotOptions: {
									bar: {
										horizontal: false,
										distributed: true
									},
								},
								dataLabels: {
									enabled: true,
									position: "center",
									style: {
										fontSize: "12px",
									},
									formatter: function (val) {
										return val;
									},
								},
								xaxis: {
									position: "bottom",
									categories: labels,
									axisBorder: {
										show: true,
									},
									axisTicks: {
										show: false,
									}
								},
                                yaxis: {
                                    show: totalCount > 0 ? true : false, 
                                    labels: {
                                        formatter: (val) => {
                                            return val.toFixed(0)
                                        }
                                    }
                                },
								tooltip: {
									theme: "light",
									enabled: true,
									followCursor: true,
								}
							}}
							series={ amounts }
							type="bar"
							height="230px"
						/>
					</div>
				</CardBody>
			</Card>
		);
	}

	const renderLocations = () => {
		if(loadingDashboard || !countriesRatings || !countriesList){
			return (
				<Card>
					<CardBody>
						<div className="dt-field dt-skeleton mb-2" style={{width: '20%'}}></div>
						<div className="dt-field dt-skeleton" style={{width: '100%', height: '360px'}}></div>
					</CardBody>
				</Card>
            );
		}

		return (
			<RisksMap countries={ countriesList } ratings={ countriesRatings } />
		);
	}

    return (
		<div className='page-content supplier-dashboard-page'>
			<Container fluid>
				<PageHeader title={t('Dashboard')} />

				<Row className="mb-4 mt-4">
					<Col lg="8"></Col>
					<Col lg="4">
						<Row>
							{ rendeDateFilterOptions() }
							<Col lg="3">
								<DownloadStatistics filterQuery={null} />
							</Col>
						</Row>
					</Col>
				</Row>

				<Row>
					<Col lg={9}>
						{ renderWidgetsList() }

						<Row>
							<Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">
                                            {t('Risk Matrix')}
                                        </CardTitle>

                                        <RiskMatrix
                                            matrix={riskRateMatrix?.matrix}
                                            consequences={riskRateMatrix?.consequences}
                                            currency={riskRateMatrix?.currency}
                                            likelihoods={riskRateMatrix?.likelihoods}
                                            risks={null}
                                            ratings={riskRateMatrix?.ratings}
                                            isLoading={ loadingDashboard }
                                        />
                                    </CardBody>
                                </Card>
							</Col>
						</Row>
					</Col>
					
					<Col lg={3}>
						{ renderRiskLevelsChart() }

						{ renderMyTasksChart() }
					</Col>
				</Row>

				<Row>
					<Col lg={9}>
						{ renderReceivedReportsByDataChart() }
					</Col>

					<Col lg={3}>
						{ renderDueDiligenceChart() }
					</Col>
				</Row>

				<Row>
					<Col lg={4}>
						{ renderRiskRateCategoryChart() }
					</Col>

					<Col lg={4}>
						{ renderSuppliersScoreChart() }
					</Col>

					<Col lg={4}>
						{ renderSuppliersPerMonthChart() }
					</Col>
				</Row>

				<Row>
					<Col lg={12}>
						{ renderLocations() }
					</Col>
				</Row>
			</Container>
		</div>
    );
}

export default withNamespaces()(Dashboard);