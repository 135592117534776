import { Fragment, useEffect, useState } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { authProtectedRoutes, publicRoutes } from './routes/';
import errorsRoutes from './routes/errors-routes';
import { getInfoOrganization } from './store/organization/actions';
import { 
    apiError, 
    authCurrentUser, 
    logoutUser, 
    loginUserSuccessful
} from './store/auth/login/actions';
import { changeWorkspace } from './store/workspace/actions';
import { appError, appLoadStatusChanged, changePreloader, confirmPromptShow } from './store/actions';
import { errorNotification } from './store/actions';
import { getBuildDate } from './utils/utils';
import AppRoute from './routes/route';
import UrlUtils from './services/utils/UrlUtils';
import packageJson from '../package.json';
import withClearCache from './ClearCache';
import Error404 from './pages/Error404';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import toastr from 'toastr';
import translationData from './locales/en/translation.json';
import axios from 'axios';

import {
	organizationModulesLoadedSuccessfully	as	orgModulesLoaded,
	setOrganizationActiveModule				as 	setOrgActiveModule
} from './store/actions';

import ConfirmPrompt from './components/Common/ConfirmPrompt'

import '@smartintegrity/risk-management-module/dist/index.css';
import '@smartintegrity/entity-management-module/dist/index.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'toastr/build/toastr.min.css';
import './assets/scss/theme.scss';
import 'react-toastify/dist/ReactToastify.css';
import './modules/data-protection/assets/scss/app.scss';
import './modules/3rd-party-management/assets/scss/app.scss';
import './theme.css';

const App = (props) => {
    const queryClient = new QueryClient();
	const urlUtils = new UrlUtils();

	const url = urlUtils.getUrlParam();
	const orgId = urlUtils.getOrganizationId();

	const [baseUrl] = useState(
		urlUtils.getAppRootPath(orgId, url)
	);

	const [organizationLoaded, setOrganizationLoaded] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

	/**
	 * states from props
	 */
	const {
		layout,
		token,
		organization,
		modules,
        ExitConfirmPrompt
	} = props;

	/**
	 * actions from props
	 */
	const {
		appLoadStatusChanged,
		authCurrentUser,
		getInfoOrganizationAction,
        SystemNotification,
        showConfirmPrompt
	} = props;

    const showToast = (options) => {
		toastr.options = {
			positionClass: 'toast-top-right',
			timeOut: 3000,
			extendedTimeOut: 1000,
			closeButton: false,
			debug: false,
			progressBar: true,
			preventDuplicates: true,
			newestOnTop: true,
			showEasing: 'swing',
			hideEasing: 'linear',
			showMethod: 'fadeIn',
			hideMethod: 'fadeOut',
			showDuration: 300,
			hideDuration: 1000,
		};
		if (options.func === 'success') {
			toastr.success(options.message);
		} else {
			toastr.error(options.message);
		}
	}

	const sendTranslationData = () => {
		const endpoint = `${process.env.REACT_APP_CUSTOMER_API_ENDPOINT}api/get.translation.en.json`;
	
		axios.post(endpoint, {
			data: translationData,
			organization_id: organization.id,
			code_lang: 'en',
		});
	};

    useEffect(() => {
        setShowConfirm(ExitConfirmPrompt.readyToShow);
    }, [ExitConfirmPrompt.readyToShow]);

	useEffect(() => {
		getInfoOrganizationAction({
			id	:	orgId,
			url	:	decodeURI(url)
		});
	}, []);

	useEffect(() => {
		if(organization?.id){
			setOrganizationLoaded(true);
			if(!token){
				const path = window.location.pathname.split('/');
				if (path.length >= 3 && path[3] === 'third-party') {
					appLoadStatusChanged(true);
				}
			}
			else{
				authCurrentUser();
			}
		}
	}, [organization, token]);

    useEffect(() => {
        if(organization.name && organization.id){
            const newBaseUrl = urlUtils.getAppRootPath(organization.id, organization.url);

            if(newBaseUrl !== baseUrl){
                window.location.replace(`${newBaseUrl}/${window.location.pathname.split('/', 4)[3]}/dashboard`);
            }
        }
    }, [ organization.id, organization.name ]);

    useEffect(() => {  
        if(SystemNotification.ok !== ''){      
            showToast({
                func: !SystemNotification.ok ? 'danger' : 'success',
                message: SystemNotification.message,
            });
        }
    }, [SystemNotification]);

	useEffect(() => {
		if(modules.active === -1){
			alert('not has active moduel!!!');
		}
	}, [modules.active]);

    useEffect(() => {
        if(organization?.id){
            sendTranslationData()
        }
    }, [ organization?.id ]);

	return (
		<Fragment>
            <ToastContainer position='bottom-left' />
            <QueryClientProvider client={queryClient}>
                <Router>
                    <Switch>
                        <Route path={ baseUrl }>
                            {!layout.isPreloader ? (
                                <div id='preloader'>
                                    <div id='status'>
                                        <div className='spinner'>
                                            <i className='ri-loader-line spin-icon'></i>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <Router basename={ baseUrl }
                            getUserConfirmation={(message, callback) => {
                                setShowConfirm((currentState) => {
                                    if(currentState){
                                        showConfirmPrompt({
                                            callback	:	callback
                                        });
    
                                        callback(false);
                                    }
                                    else{
                                        callback(true);
                                    }
                                    return currentState
                                })
                                
                            }}>
                                <ConfirmPrompt />
                                
                                <Switch>
                                    {
                                        errorsRoutes.map((route, index) => {
                                            return <Route key={index} {...route} />
                                        })
                                    }

                                    {
                                        organizationLoaded ? (
                                            publicRoutes.map((route, index) => {
                                                return (
                                                    <AppRoute
                                                        path={ route.path }
                                                        layout={ route.layout }
                                                        component={ route.component }
                                                        key={ index }
                                                        isAuthProtected={ false }
                                                        type={ route.type }
                                                        routeModules={ route.modules }
                                                    />
                                                );
                                            })
                                        )	:	null
                                    }

                                    {
                                        organizationLoaded ? (
                                            authProtectedRoutes.map((route, index) => {
                                                return (
                                                    <AppRoute
                                                        path={ route.path }
                                                        layout={ route.layout }
                                                        component={ route.component }
                                                        key={ index }
                                                        isAuthProtected={ true }
                                                        type={ route.type }
                                                        routeModules={ route.modules }
                                                    />
                                                );
                                            })
                                        )	:	null
                                    }
                                </Switch>
                            </Router>
                        </Route>

                        {/* not found */}
                        {
                            !organizationLoaded && (
                                <Route>
                                    <Error404 from='mainRouter' />
                                </Route>
                            )
                        }
                    </Switch>
                </Router>
                <p hidden>Build date: {getBuildDate(packageJson.buildDate)}</p>
            </QueryClientProvider>
		</Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		App					: 	state.App,
		layout				: 	state.Layout,
		user				: 	state.Login.user,
		loginError			: 	state.Login,
		token				: 	state.Login.token,
		organization		: 	state.Organization,
		SystemNotification	: 	state.SystemNotification,
        ExitConfirmPrompt	:	state.ExitConfirmPrompt,
		modules				:	state.Modules
	};
}; 

const mapDispachToProps = (dispatch) => {
	return {
		errorNotification					:	(message)		=>	dispatch(errorNotification(message)),
		logoutUser							:	(history) 		=> 	dispatch(logoutUser(history)),
		getInfoOrganizationAction			:	(organization)	=>	dispatch(getInfoOrganization(organization)),
		changePreloader						: 	(val) 		=> 	dispatch(changePreloader(val)),
		authCurrentUser						: 	() 			=> 	dispatch(authCurrentUser()),
		changeWorkspace						: 	(workspace) => 	dispatch(changeWorkspace(workspace)),
		loginUserSuccessful					: 	(user) 		=> 	dispatch(loginUserSuccessful(user)),
		apiError							: 	(error) 	=> 	dispatch(apiError(error)),
		appLoadStatusChanged				: 	(status) 	=> 	dispatch(appLoadStatusChanged(status)),
		appError							: 	(error) 	=> 	dispatch(appError(error)),
		modulesLoadedAction					:	(modules)	=>	dispatch(orgModulesLoaded(modules)),
		setOrgActiveModuleAction			:	(module) 	=>	dispatch(setOrgActiveModule(module)),
        showConfirmPrompt			        :	(payload)   =>  dispatch(confirmPromptShow(payload))

	};
};

export default connect(mapStateToProps, mapDispachToProps)(withClearCache(App));
