import { withNamespaces } from "react-i18next";
import { memo, useEffect, useImperativeHandle, useState } from "react";
import { connect } from "react-redux";
import DataProtectionTomService from "src/modules/data-protection/apis/DataProtectionTomService";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import BasicInformation from "./basic-info";
import TomItem from "../../components/tomItem";
import TOMFooter from "../../components/Footer";
import SubContractors from "../../components/sub-contractor";
import TopButtons from "./top-buttons";
import PdfDocument from "../../components/PDF";
import { Button, Spinner } from "reactstrap";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

const TOMHome = (props) => {
  const {
    t,
    actionsRef,
    handleFetchHeaderInformation,
    // Analysts
    users,
    handleFetchUsersListQuery,
    selectedAssignedAnalyst,
    changeAssignedAnalyst,
    updatingSelectedAssignedAnalyst,
    // Selected status
    changeStatus,
    updatingSelectedStatus,
    // Revision
    changeRevision,
    updatingSelectedRivision,
  } = props;

  const [details, setDetails] = useState({});
  const handleFetchDetails = useQuery({
    queryKey: ["data-protection-tom-details-contnet"],
    queryFn: async () => {
      const service = DataProtectionTomService.getInstance();
      return await service.fetchDetails();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching content."), {
        type: "error",
      });
    },
  });

  const {
    isFetching,
    refetch: fetchExportData
  } = useQuery({
    queryKey: ["data-protection-tom-pdf-export-query"],
    queryFn: async () => {
      const service = DataProtectionTomService.getInstance();
      return await service.fetchExportPDFData();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    enabled: false,
    onError: (error) => {
      toast(t("An error occurred while fetching content."), {
        type: "error",
      });
    },
    onSuccess: async (data) => {
        const fileName = `tom.pdf`;

        const blob = await pdf( 
            <PdfDocument 
                data={data}
                organization={props.Organization}
            /> 
        ).toBlob();
    
        saveAs(blob, fileName);
    }
  });

  useEffect(() => {
    if (handleFetchDetails.data) {
      setDetails(handleFetchDetails.data);
    }
  }, [handleFetchDetails.data]);

  useImperativeHandle(actionsRef, () => {
    return {
      reload: () => {
        handleFetchDetails.refetch();
      },
    };
  });

  return (
    <>
      <div className="p-4">
        {/* TOP Options */}
        <div style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "Hug (109.31px)",
            }}
            className="mb-4"
            >
                <Button color="primary" onClick={() => {
                    fetchExportData()
                }}>
                    {isFetching ? (
                        <>
                            <Spinner size='sm' color="white" className="me-2" />
                            {t("Loading document...")}
                        </>
                    ) : (
                        <>{t("Export PDF")}</>
                    )}
                </Button>
        </div>

        {/* Components */}
        <BasicInformation
          // Fetch Header
          handleFetchHeaderInformation={handleFetchHeaderInformation}
          // Analysts
          users={users}
          selectedAssignedAnalyst={selectedAssignedAnalyst}
          handleFetchUsersListQuery={handleFetchUsersListQuery}
          changeAssignedAnalyst={changeAssignedAnalyst}
          updatingSelectedAssignedAnalyst={updatingSelectedAssignedAnalyst}
          // Statuses
          changeStatus={changeStatus}
          updatingSelectedStatus={updatingSelectedStatus}
          // Revision
          changeRevision={changeRevision}
          updatingSelectedRivision={updatingSelectedRivision}
        />

        {details?.sections?.map((section, index) => {
          return (
            <TomItem
              section={section}
              index={index}
              handleFetchDetails={handleFetchDetails}
            />
          );
        })}

        <SubContractors
          sub_contractors={details?.sub_contractors}
          itemNumber={(details?.sections?.length || 0) + 1}
          handleFetchDetails={handleFetchDetails}
        />

        <TOMFooter
          changeStatus={changeStatus}
          updatingSelectedStatus={updatingSelectedStatus}
          handleFetchHeaderInformation={handleFetchHeaderInformation}
          handleFetchDetails={handleFetchDetails}
        />
      </div>
    </>
  );
};

const mapStatetoProps = (state) => {
  const { Organization } = state;

  return {
    Organization,
  };
};
export default withNamespaces()(connect(mapStatetoProps)(memo(TOMHome)));
