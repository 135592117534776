import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo, useRef } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { toast } from "react-toastify";

import SupplierService from "src/modules/3rd-party-management/apis/SupplierService";

import OverviewHeader from "../../../../components/overview-header";

import Accordion from 'react-bootstrap/Accordion';

import GeneralInformationImage from "src/modules/3rd-party-management/assets/images/general-information.svg";
import ProductsListImage from "src/modules/3rd-party-management/assets/images/products-list.svg";
import ContactPersonImage from "src/modules/3rd-party-management/assets/images/contact-person.svg";
import ShareholderImage from "src/modules/3rd-party-management/assets/images/shareholders.svg";
import LegalEntitiesImage from "src/modules/3rd-party-management/assets/images/legal-entities.svg";
import ManagementTeamImage from "src/modules/3rd-party-management/assets/images/management-team.svg";
import LocationImage from "src/modules/3rd-party-management/assets/images/location.svg";

import { Alert, Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
  
import ProductList from "./components/products";
import ContactPeople from "./components/contact-people";
import ManagementTeam from "./components/management-team";
import Select, { components } from "react-select";

import IndustriesService from "src/modules/3rd-party-management/apis/IndustriesService";

import {
    CompanySizes
} from "src/modules/3rd-party-management/constants";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Shareholders from "./components/shareholders";
import LegalEntites from "./components/legal-entities";
import Locations from "./components/locations";

const industriesFilterOption = (
    candidate,
    input
) => {
    if (input) {
        const loweredInput = input.toLowerCase();

        return candidate.label.toLowerCase().includes(loweredInput) || (candidate.data.keywords || []).find((keyword) => keyword.toLowerCase().includes(loweredInput));
    }

    return true;
};

const BasicInformation = ({
    t,
    supplierId
}) => {

    const [ activeAccordion, setActiveAccordion ] = useState(null);
    const [ supplierInfos, setSupplierInfos ] = useState(null);
    const [ industries, setIndustries ] = useState([]);
    const [ showEditGeneralInfosModal, setShowEditGeneralInfosModal ] = useState(false);
    const [ showIndustryDeletionError, setShowIndustryDeletionError ] = useState(null);

    const editGeneralInfosFormRef = useRef();
    const overviewHeaderRef = useRef();

    const handleFetchSupplierBasicInformation = useQuery({
		queryKey: ['3rd-party-management-supplier-details-basic-infos'],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchBasicInfos(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching supplier basic information.'), {
				type: 'error',
			});
		},
        onSuccess: (data) => {
            setSupplierInfos({
                ...data,
                industries  :   (data.industries || []).map((industry) => {
                    return {
                        value: industry?.id,
                        baseLabel: industry?.name,
                        label: `${industry?.class} - ${t(industry?.name)}`
                    }
                })
            });
        }
	});

    const handleFetchIndustriesListQuery = useQuery({
        queryKey: ['3rd-party-management-fetch-industries-list-query'],
        queryFn: async () => {
            const service = IndustriesService.getInstance();

            return await service.fetchList();
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (error) => {
            toast(t('An error occurred while fetching industries list.'), {
                type: 'error',
            });
        }
    });

    const handleUpdateSupplierGeneralInfosMutation = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierService.getInstance();
            return await service.updateGeneralInfos(payload);
        },
        onSuccess: () => {
            handleFetchSupplierBasicInformation.refetch();

            const {
                reload  :   reloadOverviewHeader
            } = (overviewHeaderRef?.current || {});

            reloadOverviewHeader && reloadOverviewHeader();

            toast(t("General information updated successfully."), {
                type: "success",
            });

            toggleEditGeneralInfosModal();
        },
        onError: () => {
            toast(t("An error occurred while updating General information."), {
                type: "error",
            });
        }
    });

    const handleValidateIndustryDeletion = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierService.getInstance();
            return await service.validateIndustryDeletion(payload);
        }
    });

    useEffect(() => {
        const list = (handleFetchIndustriesListQuery.data || []).map((industry) => {
            return {
                value       :   industry.id,
                label       :   `${industry.class} - ${t(industry.title)}`,
                baseLabel   :   industry.title,
                keywords    :   industry?.keywords || []
            };
        });

        setIndustries(list);
    }, [ handleFetchIndustriesListQuery.data, t ]);

    const toggleEditGeneralInfosModal = () => setShowEditGeneralInfosModal(!showEditGeneralInfosModal);

    const handleEditGeneralInfosForm = (e, {
        generalInfos
    }) => {
        handleUpdateSupplierGeneralInfosMutation.mutate({
            supplier        :   supplierId,
            generalInfo    :   generalInfos
        });
    }

    const handleChangeIndsutries = (selectedOptions, e) => {
        const industryInput = editGeneralInfosFormRef.current._inputs['generalInfos[industries]'];
        const selectedValues = selectedOptions.map(option => String(option.value));
        industryInput.value = selectedValues; // Assigning the array directly
        industryInput.validate();

        if(e.action === 'remove-value'){
            if(!e.removedValue.isNew){
                setSupplierInfos((currentState) => {
                    return {
                        ...currentState,
                        industries: currentState.industries.map((industry) => {
                            return {
                                ...industry,
                                isLoading: industry.value === e.removedValue.value ? true : !!(industry.isLoading)
                            }
                        })
                    }
                });

                handleValidateIndustryDeletion.mutate({
                    supplier    :   supplierId,
                    industry    :    e.removedValue.value
                }, {
                    onSettled: () => {
                        setSupplierInfos((currentState) => {
                            return {
                                ...currentState,
                                industries: currentState.industries.map((industry) => {
                                    return {
                                        ...industry,
                                        isLoading: industry.value === e.removedValue.value ? false : !!(industry.isLoading)
                                    }
                                })
                            }
                        });
                    },
                    onError: ({ response: { data } }) => {
                        setShowIndustryDeletionError(data.error)
                    },
                    onSuccess: () => {
                        setSupplierInfos((currentState) => {
                            return {
                                ...currentState,
                                industries: currentState.industries.filter((industry) => industry.value !== e.removedValue.value)
                            }
                        });
                    }
                });
            }
            else{
                setSupplierInfos((currentState) => {
                    return {
                        ...currentState,
                        industries: currentState.industries.filter((industry) => industry.value !== e.removedValue.value)
                    }
                });
            }
        }
        else{
            setSupplierInfos((currentState) => {
                return {
                    ...currentState,
                    industries: selectedOptions.map((industry) => {
                        const isNew = e.option.value === industry.value ? true : !!(industry.isNew)
                        return {
                            ...((currentState.industries || []).find((i) => i.value === industry.value)),
                            ...industry,
                            isNew: isNew
                        }
                    })
                }
            });
        }
    }

    const renderLoadingSection = () => {
        return (
            <Row>
                <Col sm="12" className="mt-4 mb-4">
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '100px'}}></div>
                </Col>
                <Col sm="12" className="mb-4">
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '100px'}}></div>
                </Col>
                <Col sm="12" className="mb-4">
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '100px'}}></div>
                </Col>
                <Col sm="12" className="mb-4">
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '100px'}}></div>
                </Col>
                <Col sm="12" className="mb-4">
                    <div className="dt-field dt-skeleton" style={{width: '100%', height: '100px'}}></div>
                </Col>
            </Row>
        )
    }

    const MultiValueRemove = (props) => {
        const isLoading = props.data.isLoading; 
        return (
            <components.MultiValueRemove {...props}>
                {isLoading && <Spinner color="secondary" size="sm" />}
            </components.MultiValueRemove>
        );
    };

    const renderEditGeneralInfosModal = () => {
        const industriesListIsLoading = handleFetchIndustriesListQuery.isFetching || handleFetchIndustriesListQuery.isLoading;
        const isUpdatingInProgress = handleUpdateSupplierGeneralInfosMutation.isFetching || handleUpdateSupplierGeneralInfosMutation.isLoading;
        const sizes = [];

        for(const sizeKey in CompanySizes){
            sizes.push({
                value : sizeKey,
                label : `${CompanySizes[sizeKey]?.title} ${t("Employees")}`
            });
        }

        return (
            <Modal isOpen={ showEditGeneralInfosModal } backdrop="static" keyboard={ false } size="xl">   
                <AvForm ref={ editGeneralInfosFormRef } onValidSubmit={ handleEditGeneralInfosForm } className="needs-validation m-2">
                    <ModalHeader toggle={ toggleEditGeneralInfosModal }>
                        {t("General Information")}
                    </ModalHeader>

                    <ModalBody>
                        <Row className="mb-3">
                            <Col md="6" sm="12">
                                <Label for="edit-general-infos-name-input">
                                    {t("Name")}
                                </Label>
                                <AvField
                                    name="generalInfos[name]"
                                    type="text"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    id="edit-general-infos-name-input"
                                    defaultValue={supplierInfos.name}
                                />
                            </Col>

                            <Col md="6" sm="12">
                                <Label for="edit-general-infos-vatnumber-input">
                                    {t("VAT ID")}
                                </Label>
                                <AvField
                                    name="generalInfos[vatNumber]"
                                    type="text"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    id="edit-general-infos-vatnumber-input"
                                    defaultValue={supplierInfos.vatNumber}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md="6" sm="12">
                                <Label for="edit-general-infos-industry-input">
                                    {t("Industries")}
                                </Label>
                                <Select
                                    isClearable={false}
                                    isLoading={ industriesListIsLoading }
                                    isDisabled={ industriesListIsLoading }
                                    placeholder={t("Select") + "..."}
                                    components={{ MultiValueRemove }}
                                    classNamePrefix="select2-selection"
                                    id='edit-general-infos-industry-input'
                                    menuPortalTarget={ document.body }
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    onChange={handleChangeIndsutries} 
                                    options={industries}                                                                
                                    filterOption={industriesFilterOption}
                                    isMulti
                                    value={supplierInfos?.industries}
                                />
                                <AvField
                                    name="generalInfos[industries]"
                                    type="hidden" 
                                    errorMessage={t("This field cannot be blank")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                    defaultValue={supplierInfos?.industries.map(industry => industry.value.toString())}
                                />
                            </Col>

                            <Col md="6" sm="12">
                                <Label for="edit-general-infos-website-input">
                                    {t("Website")}
                                </Label>
                                <AvField
                                    name="generalInfos[websiteUrl]"
                                    type="text"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    id="edit-general-infos-website-input"
                                    defaultValue={supplierInfos.websiteUrl}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md="6" sm="12">
                                <Label for="edit-general-infos-company-size-input">
                                    {t("Company Size")}
                                </Label>
                                <Select
                                    placeholder={t("Select") + "..."}
                                    classNamePrefix="select2-selection"
                                    id='edit-general-infos-company-size-input'
                                    options={ sizes }
                                    menuPortalTarget={ document.body }
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    onChange={(e) => {
                                        const sizeInput = editGeneralInfosFormRef.current._inputs['generalInfos[companySize]'];
                                        sizeInput.value = e.value;
                                        sizeInput.validate();
                                    }}
                                    defaultValue={supplierInfos.employeeSize && {
                                        value   :   supplierInfos.employeeSize,
                                        label   :   CompanySizes[supplierInfos.employeeSize]?.title
                                    }}
                                />
                                <AvField
                                    name="generalInfos[companySize]"
                                    type="hidden" 
                                    errorMessage={t("This field cannot be blank")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                    defaultValue={ supplierInfos.employeeSize }
                                />
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={ toggleEditGeneralInfosModal } color="primary" type="button" outline>
                            { t('Cancel') }
                        </Button>

                        <Button disabled={ isUpdatingInProgress } color="primary" type="submit" size="md">
                            {
                                isUpdatingInProgress ? (
                                    <Spinner className="me-2" animation="border" variant="white" size="sm"/>
                                ) : (
                                    <i className="ri-pencil-line align-middle me-2"></i>
                                )
                                
                            }
                            { t('Update') }
                        </Button>
                    </ModalFooter>
                </AvForm>
            </Modal>
        )
    }

    const renderIndustryDeletionErrorModal = () => {
        return (
            <Modal isOpen={ showIndustryDeletionError } backdrop="static" keyboard={ false } size="lg">   
                <ModalHeader toggle={ () => setShowIndustryDeletionError(null) }>
                    <div className="d-flex align-items-center justify-content-start">
                        {t('Deletion Error')}
                    </div>
                </ModalHeader>

                <ModalBody>
                    <Alert color="danger" className="d-flex justify-content-start">
                        <div className="d-flex align-items-center me-2">
                            <i className="ri-close-circle-line" style={{fontSize: '32px'}}/>
                        </div>
                        <div className="d-flex align-items-start justify-content-center flex-column">
                            <strong>{t('Error')}</strong>
                            {t("In order to remove the desired industry, the following products must be removed from the supplier's product list")}
                        </div>
                    </Alert>
                        <ul>
                            {(showIndustryDeletionError?.products || []).map((p) => <li className="mb-2">{`${p.class} - ${t(p.name)}`}</li>)}
                        </ul>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={() => setShowIndustryDeletionError(null)} color="primary" size="md">
                        {t('Close')}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    const dataIsLoading = (
        handleFetchSupplierBasicInformation.isLoading || handleFetchSupplierBasicInformation.isFetching
    );

    return (
        <div className="p-4">
            <OverviewHeader supplierId={ supplierId } actionsRef={ overviewHeaderRef } />
            
            {
                !dataIsLoading && supplierInfos ?  (
                    <>
                        <Accordion className="mt-4" defaultActiveKey={ activeAccordion }>
                            <Accordion.Item onClick={() => setActiveAccordion('general-information')} className={activeAccordion === 'general-information' ? 'active' : ''} eventKey={ 'general-information' }>
                                <Accordion.Header>
                                    <img src={ GeneralInformationImage } alt="" />
                                    <span>
                                        { t('General Information') }
                                    </span>
                                    <span onClick={ (e) => {
                                        e.stopPropagation();
                                        setShowEditGeneralInfosModal(true);
                                    } } className="edit-general-infos-btn">
                                        <i className="ri-pencil-line"></i>
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <Row className="mb-2">
                                        <Col sm="12" md="6">
                                            <Label className="form-label">
                                                {t('VAT ID')}:
                                            </Label>
                                            <p>
                                                { supplierInfos.vatNumber }
                                            </p>
                                        </Col>
                                        <Col sm="12" md="6">
                                            <Label className="form-label">
                                                {t('Website')}:
                                            </Label>
                                            <p>
                                                { supplierInfos.websiteUrl }
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col sm="12" md="6">
                                            <Label className="form-label">
                                                {t('Industries')}:
                                            </Label>
                                            <p>
                                                <div style={{ whiteSpace: 'pre-line' }}>
                                                    {
                                                        (supplierInfos.industries || [])
                                                            .map((industry) => industry.label)
                                                            .join('\n')
                                                    }
                                                </div>
                                            </p>
                                        </Col>

                                        <Col sm="12" md="6">
                                            <Label className="form-label">
                                                {t('Employee Size')}:
                                            </Label>
                                            <p>
                                                { `${CompanySizes[supplierInfos.employeeSize]?.title} ${t("Employees")}` }
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col sm="12">
                                            <Label className="form-label">
                                                {t('Description')}:
                                            </Label>
                                            <div dangerouslySetInnerHTML={{__html: supplierInfos.description}} />
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item onClick={() => setActiveAccordion('locations')} className={activeAccordion === 'locations' ? 'active' : ''} eventKey={ 'locations' }>
                                <Accordion.Header>
                                    <img src={ LocationImage } alt="location" />
                                    <span>
                                        { t('Location') }
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <Locations 
                                        supplierId={ supplierId }
                                    />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item onClick={() => setActiveAccordion('management-team')} className={activeAccordion === 'management-team' ? 'active' : ''} eventKey={ 'management-team' }>
                                <Accordion.Header>
                                    <img src={ ManagementTeamImage } alt="" />
                                    <span>
                                        { t('Management Team') }
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <ManagementTeam supplierId={supplierId} />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item onClick={() => setActiveAccordion('products-list')} className={activeAccordion === 'products-list' ? 'active' : ''} eventKey={ 'products-list' }>
                                <Accordion.Header>
                                    <img src={ ProductsListImage } alt="" />
                                    <span>
                                        { t('List Of Products') }
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <ProductList industryIds={ supplierInfos.industries.map(industry => industry.value) } supplierId={ supplierId } />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item onClick={() => setActiveAccordion('contact-person')} className={activeAccordion === 'contact-person' ? 'active' : ''} eventKey={ 'contact-person' }>
                                <Accordion.Header>
                                    <img src={ ContactPersonImage } alt="" />
                                    <span>
                                        { t('Contact Person') }
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <ContactPeople supplierId={ supplierId } />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item onClick={() => setActiveAccordion('shareholders')} className={activeAccordion === 'shareholders' ? 'active' : ''} eventKey={ 'shareholders' }>
                                <Accordion.Header>
                                    <img src={ ShareholderImage } alt="" />
                                    <span>
                                        { t('Shareholders') }
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <Shareholders supplierId={ supplierId } />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item onClick={() => setActiveAccordion('legalentities')} className={activeAccordion === 'legalentities' ? 'active' : ''} eventKey={ 'legalentities' }>
                                <Accordion.Header>
                                    <img src={ LegalEntitiesImage } alt="" />
                                    <span>
                                        { t('Connected Legal Entities') }
                                    </span>
                                </Accordion.Header>
                                
                                <Accordion.Body>
                                    <LegalEntites 
                                        defaultValues={(supplierInfos?.legalEntities || []).map((l) => l.id)} 
                                        supplierId={ supplierId } />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        { renderEditGeneralInfosModal() }

                        { renderIndustryDeletionErrorModal() }
                    </>
                ) : (
                    <>
                        { renderLoadingSection() }
                    </>
                )
            }
        </div>
    );
};

export default withNamespaces()(
    withRouter(memo(BasicInformation))
); 