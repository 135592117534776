import React, { useMemo, useState, useCallback, useRef } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
import MessageInput from "./MessageInput";
import Avatar from "./components/AvatarCard";
import MessageBoxHeader from "./MessageBoxHeader";
import PropTypes from "prop-types";
import { withNamespaces } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";
import EmptyMessageBox from "../../assets/images/empty-message-box.svg";
import moment from "moment";
import { FaFile } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import DragDropArea from "./components/DragDropArea";
import SendMessageWithDetails from "./components/SendMessageWithDetails";
import SendMessageCard from "./components/SendMessageCard";
import ReceiveMessageWithDetails from "./components/ReceiveMessageWithDetails";
import ReceiveMessageCard from "./components/ReceiveMessageCard";
import { connect } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { API_BASE_URL } from "../../constants";
import settings from "src/modules/3rd-party-management/pages/admin/administration/settings";
// import { API_BASE_URL } from 'src/modules/data-protection/constants/index';

const Index = ({
  t,
  token,
  settings: {
    messageListUrl,
    sendMessageUrl,
    attachmentDownloadUrl,
    attachmentUploadUrl,
    attachmentDeleteUrl,
    assetId,
    headerOptions,
    mention,
    payloadId
  },
  generateAIResponse,
  aiButtonDisabled,
  editorState
}) => {
  const [sendButtonDisable, setSendButtonDisable] = useState(false);
  const [messageFilter, setMessageFilter] = useState("");
  const [dragFiles, setDragFiles] = useState([]);
  const bodyRef = useRef();

  
  //fetch the List
  const {
    data: messageList,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["commentList", messageListUrl, assetId],
    queryFn: async () => {
      const requestData = {
        ...(payloadId ? { [payloadId]: assetId } : { software_id: assetId}),
      };
      const response = await axios.post(
        `${API_BASE_URL}/${messageListUrl}`,
          requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data?.data || [];
    },
  });

  const handlerSendMessage = async (commentContent, uploadedFiles, mentions = []) => {
    try {
      setSendButtonDisable(true);
      // Extracting only the ids from uploadedFiles
      const attachments = uploadedFiles.map((file) => file.id);
      // If there are files, pass the attachments along with the comment content

        if (commentContent.trim() === "") {
          toast(t("Message cannot be empty"), {
            type: "warning",
          });
          setSendButtonDisable(false);
          return;
        }
        const requestData = {
          ...(payloadId ? { [payloadId]: assetId } : { software_id: assetId}),
          comment_content: commentContent,
            files             : attachments,
            mentions          : mentions,
        };
        await axios.post(
          `${API_BASE_URL}/${sendMessageUrl}`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

      // If successful, refetch the comment list to update the UI
      refetch();

      setSendButtonDisable(false);
      return true; 
    } catch (error) {
      console.error("Error sending comment:", error);
      setSendButtonDisable(false);
      toast(t("An error occurred while sending the comment."), {
        type: "error",
      });
      return false;
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    setDragFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  const handleDownloadFile = async (attachmentId, fileName) => {
    axios
      .post(`${API_BASE_URL}/attachment/${attachmentId}/download`, null, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob.data], { type: "*" })
        );
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", `${fileName.split(".")[0]}.zip`);

        document.body.appendChild(link);

        link.click();
        link.remove();
      })
      .catch(() => {
        toast(t("An error occurred while downloading the file."), {
          type: "error",
        });
      });
  };


  const TodayDividerAdded = useMemo(() => {
    let added = false;
    return (messageDate) => {
      // Convert the backend timestamp to a Moment.js object
      const backendMoment = moment.unix(messageDate);
      const currentDate = moment().format("YYYY-MM-DD");
      // Get the backend date
      const backendDate = backendMoment.format("YYYY-MM-DD");
      // Compare dates
      const isToday = currentDate === backendDate;
      if (!added && isToday) {
        added = true;
        return (
          <li key="divider">
            <div className="chat-day-title">
              <span className="title">{t('Today')}</span>
            </div>
          </li>
        );
      }
      return null;
    };
  }, [t]);

  const messageItems = useMemo(() => {
    if (isLoading) {
      return (
        <div className="message-loading-container">
          <Spinner animation="border" variant="primary" size="md" />
        </div>
      );
    }

    const filteredMessages = (messageList || []).filter((message) =>
      message.content.includes(messageFilter)
    );

    if (filteredMessages.length === 0) {
      return (
        <div className="message-quote-box">
            <div className="empty-messagebox" style={{
                backgroundImage: `url(${EmptyMessageBox})`
            }}>
                <p>{t('You have no massage to be shown yet')}</p>
            </div>
        </div>
      );
    }

    return filteredMessages.map((message, index) => {
      const messageDate = moment(message.created_at);
      const isSender = !message.owner?.contactPerson;
      const messageKey = `message-${message.id || index}`; // Use a more unique key
      const prevMessage = index > 0 ? filteredMessages[index - 1] : null;
      const nextMessage =
        index < filteredMessages.length - 1
          ? filteredMessages[index + 1]
          : null;
      // Check if the previous message exists and has the same owner's full name
      const sameOwnerAsPrev =
        prevMessage &&
        prevMessage.owner?.id === message.owner?.id;
      const sameOwnerAsNext =
        nextMessage &&
        nextMessage.owner?.id === message.owner?.id;

      const RenderMessageComponent = isSender
        ? sameOwnerAsPrev
          ? SendMessageCard
          : SendMessageWithDetails
        : sameOwnerAsPrev
        ? ReceiveMessageCard
        : ReceiveMessageWithDetails;

      return (
        <React.Fragment key={messageKey}>
          {TodayDividerAdded(messageDate)}
          {message?.content && message?.content?.trim() !== "" && (
            <RenderMessageComponent
              message={message}
              sameOwnerAsNext={sameOwnerAsNext}
            />
          )}
        </React.Fragment>
      );
    });
  }, [isLoading, messageList, messageFilter, TodayDividerAdded]);

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card className="message-details-box">
          <MessageBoxHeader
            settings={headerOptions}
            handlerSearchMessage={(m) => {
              setMessageFilter(m);
            }}
          />
          <CardBody innerRef={(r) => (bodyRef.current = r)}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <DragDropArea isDragActive={isDragActive} t={t} />
              <div className="chat-widget">
                <div className="chat-conversation">
                  <SimpleBar
                    style={{
                      height: `${
                        bodyRef && bodyRef.current
                          ? bodyRef.current.offsetHeight + " px"
                          : "360px"
                      }`,
                    }}
                  >
                    <ul className="list-unstyled mb-0 pr-3">{messageItems}</ul>
                  </SimpleBar>
                </div>
              </div>
            </div>
          </CardBody>
          <MessageInput
            hasAi={true}
            generateAIResponse={generateAIResponse}
            handlerSendMessage={handlerSendMessage}
            sendButtonDisable={sendButtonDisable}
            uploadUrl={attachmentUploadUrl}
            downloadUrl={attachmentDownloadUrl}
            deleteFileUrl={attachmentDeleteUrl}
            userToken={token}
            dragFiles={dragFiles}
            mentionSettings={ mention }
            aiButtonDisabled={aiButtonDisabled}
            aiEditorState={editorState}
          />
        </Card>
      </Col>
    </React.Fragment>
  );
};

// Prop types definition
Index.propTypes = {
  settings: PropTypes.shape({
    messageListUrl: PropTypes.string.isRequired,
    sendMessageUrl: PropTypes.string.isRequired,
    attachmentDownloadUrl: PropTypes.string.isRequired,
    attachmentUploadUrl: PropTypes.string.isRequired,
    attachmentDeleteUrl: PropTypes.string.isRequired,
    assetId: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const { token } = state.Login;

  return {
    token,
  };
};

export default withNamespaces()(
  withRouter(connect(mapStateToProps, {})(Index))
);
