import axios from "axios";

import { API_BASE_URL } from "../constants";

export default class DataProtectionTomService {
  static instance;
  resource = `${API_BASE_URL}/tom/`;

  constructor() {}

  static getInstance() {
    if (!DataProtectionTomService.instance) {
      DataProtectionTomService.instance = new DataProtectionTomService();
    }

    return DataProtectionTomService.instance;
  }

  // Header Information
  async fetchHeaderInfo() {
    const response = await axios.get(`${this.resource}header`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });
    return response.data.data;
  }

  // TOM Details
  async fetchDetails() {
    const response = await axios.get(`${this.resource}detail`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });
    return response.data.data;
  }

   // TOM Restore
   async restore() {
    const response = await axios.post(`${this.resource}restore`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });
    return response.data.data;
  }

  // Change Analyst
  async changeAssignedAnalyst(data) {
    const response = await axios.post(`${this.resource}change_analyst`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });
    return response.data.data;
  }

  // Change Status
  async changeStatus(data) {
    const response = await axios.post(`${this.resource}change_status`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  // Change Revision
  async changeRevision(data) {
    const response = await axios.post(`${this.resource}change_revision`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  // Change Toggle Show PDF
  async changeToggleShowPDF(id) {
    const response = await axios.post(
      `${this.resource}subsection/${id}/toggle_show_pdf`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  // Update Information
  async updateInformation(data, id) {
    const response = await axios.post(
      `${this.resource}subsection/${id}/change_information`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  // Toggle Measure
  async toggleMeasure(id) {
    const response = await axios.post(
      `${this.resource}measure/${id}/toggle`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  // Add measure
  async addMeasure(data) {
    const response = await axios.post(
      `${this.resource}subsection/add_measure`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  // Update Description
  async updateDescription(data, id) {
    const response = await axios.post(
      `${this.resource}subsection/${id}/change_description`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  // Create subcontractor
  async createSubcontractor(data) {
    const response = await axios.post(
      `${this.resource}add_subcontractor`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  // Create subcontractor
  async editSubcontractor(data, id) {
    const response = await axios.post(
      `${this.resource}sub_contractor/${id}/edit`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }


  // Delete subcontractor
  async deleteSubcontractor(id) {
    const response = await axios.delete(
      `${this.resource}sub_contractor/${id}/delete`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
          )}`,
        },
      }
    );

    return response.data.data;
  }

  // Tasks
  async fetchTasks(data) {
    const response = await axios.post(`${this.resource}tasks`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });
    return response.data.data;
  }

  async fetchExportPDFData() {
    // ${this.resource}pdf/data
    const response = await axios.get(`${this.resource}pdf/data`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });
    return response.data.data;
  }
}
