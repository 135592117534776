import userLightIcon from "./assets/filter_user_light.svg";
import userBlueIcon from "./assets/filter_user_blue.svg";

import userSectionsLightIcon from "./assets/filter_sections_light.svg";
import userSectionsBlueIcon from "./assets/filter_sections_blue.svg.svg";

import calendarLightIcon from "./assets/filter_calendar_light.svg";
import calendarBlueIcon from "./assets/filter_calendar_blue.svg";

import activityLightIcon from "./assets/filter_activity_light.svg";
import activityBlueIcon from "./assets/filter_activity_blue.svg";

export const icons = {
  date: {
    default: calendarLightIcon,
    active: calendarBlueIcon,
  },
  user: {
    default: userLightIcon,
    active: userBlueIcon,
  },
  section: {
    default: userSectionsLightIcon,
    active: userSectionsBlueIcon,
  },
  actions: {
    default: activityLightIcon,
    active: activityBlueIcon,
  },
};

export const generateDescription = (t, action, section, hardwareType) => {
  let desc = "";
  switch (action) {
    case "create":
      desc = `${t("A new")} ${t(hardwareType?.toLowerCase())} ${t(
        "was added to the records."
      )}`;
      break;
    case "delete":
      desc = `Remove ${section?.toLowerCase()} devices from the system's records.`;
      break;
    case "handover":
      desc = `Document the transfer of ownership or responsibility for data or equipment.`;
      break;
    case "add_owner":
      desc = `Register new owners for specific devices or software.`;
      break;
    case "delete_owner":
      desc = `Remove ownership records from the system.`;
      break;
    case "edit":
      desc = `Modify and refresh existing ${section?.toLowerCase()} information.`;
      break;
    case "add_module":
      desc = `Module is added`;
      break;
    case "remove_module":
      desc = `Module is removed`;
      break;
    case "change_responsible_person":
      desc = `Responsible person has been changed`;
      break;
    case "change_company":
      desc = "Company has been changed";
      break;
    case "change_position":
      desc = "Position has been changed";
      break;
    case "change_email":
      desc = "Email has been changed";
      break;
    case "change_phone_number":
      desc = "Phone number has been changed";
      break;
    case "change_responsible_department":
      desc = "Responsible department has been changed";
      break;
    case "dpia":
      desc = `Revise and update Data Protection Impact Assessment (DPIA) records for ${section?.toLowerCase()}.`;
      break;
    case "create_processing_activity":
      desc = `A new processing activity was added to the records.`;
      break;
    case "change_processing_activity":
      desc = `Answers in processing activity changed `;
      break;
      case "remove_processing_activity":
        desc = `Remove processing activity from the records.`;
        break;
      case "update_processing_activity_module_name":
        desc = `Update the module name in the processing activity records.`;
        break;
    case "add_processing_activity_module_name":
        desc = `A new module name was added to the processing activity.`;
        break;
    default:
      desc = "";
      break;
  }

  return t(desc);
};

// Generating the Action
export const generateAction = (t, action) => {
    let desc = "";
    switch (action) {
      case "create":
        desc = t("Created");
        break;
      case "delete":
        desc = t("Deleted");
        break;
      case "handover":
        desc = t("Handover");
        break;
      case "add_owner":
        desc = t("Added a new owner");
        break;
      case "delete_owner":
        desc = t("Deleted an owner");
        break;
      case "edit":
        desc = t("Edit detail");
        break;
      case "add_module":
        desc = t("Module is added");
        break;
      case "remove_module":
        desc = t("Module is removed");
        break;
      case "change_responsible_person":
        desc = t("Responsible changed");
        break;
      case "change_company":
        desc = t("Company changed");
        break;
      case "change_position":
        desc = t("Position changed");
        break;
      case "change_email":
        desc = t("Email changed");
        break;
      case "change_phone_number":
        desc = t("Phone number changed");
        break;
      case "change_responsible_department":
        desc = t("Responsible department changed");
        break;
      case "dpia":
        desc = t("DPIA");
        break;
      case "create_processing_activity":
        desc = t("Create processing activity");
        break;
      case "change_processing_activity":
        desc = t("Update processing activity details");
        break;
      case "upload_csv":
        desc = t("Upload processing activity data from Excel file");
        break;
      case "download":
        desc = t("Download");
        break;
      case "toggle_flag":
        desc = t("Change flag");
        break;
      case "change_status":
        desc = t("Change Status");
        break;
      case "change_priority":
        desc = t("Change Priority");
        break;
      case "change_analyst":
        desc = t("Change Analyst Assigned");
        break;
      default:
        desc = "";
        break;
    }
  
    return desc;
  };
  