import { React, useState, memo, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { apiError, loginUserSuccessful, logoutUser, logoutUserSuccess } from '../store/actions';
import { API_URL_GET_USER_DETAIL } from '../common/constants';
import { connect } from 'react-redux';
import store from '../store';
import UrlUtils from '../services/utils/UrlUtils';
import { ROLE_ANALYST, ROLE_ANALYST_ADMIN, ROLE_EMPLOYEE, ROLE_THIRD_PARTY } from '../components/constants';
import axios from 'axios';

const BeforeEachRoute = (props) => {
	const urlUtils = new UrlUtils();
	const orgId = urlUtils.getOrganizationId();

	const [completed, setCompleted] = useState(false);

	const {
		modules	:	{
            isLoading   :   moduleSettingIsLoading,
			active	    :	selectedModuleName,
			list	    :   avModulesList
		},
		user,
		token
	} = props;

	const routeModules = props.routeModules || [];

	const handleFetchAuthUser = async () => {
		try {
			const response = await axios.post(
				API_URL_GET_USER_DETAIL,
				{ organizationId: orgId },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (response.status === 200) {
				loginUserSuccessful({
					...response.data.data,
				});
				return response.data.data;
			}
		} 
		catch (err) {}

		return false;
	};

	const validateRouteModules = () => {
		if (Array.isArray(routeModules) && routeModules.length > 0) {
			if (!routeModules.includes(selectedModuleName)) {
				const urlSegments = window.location.pathname.split('/');

				if (urlSegments.includes('third-party')) {
					if (!window.location.pathname.includes('/third-party/home')) {
						props.history.push(`/third-party/home`);
					}
					return false;
				} else if (urlSegments.includes('employee') || urlSegments.includes('admin')) {
					if (!window.location.pathname.includes('/admin/start')) {
						props.history.push(`/admin/start`);
					}
					return false;
				}
			}
			else{
				const path = props.history.location.pathname;
				const selectedModuleSettings = (avModulesList.find((module) => module.name === selectedModuleName))?.settings;

				if(
					(path.includes('/voice-report') 			&& !selectedModuleSettings?.isVoice) ||
					(path.includes('/arrange-meeting') 			&& !selectedModuleSettings?.isMeeting) || 
					(path.includes('/report-confidentially')	&& !selectedModuleSettings?.isConfidentially) ||
					(path.includes('/report-anonymously') 		&& !selectedModuleSettings?.isAnonymously) ||
					(path.includes('/e-learning') 				&& !selectedModuleSettings?.isElearning)
				){
					props.history.push(`/fatal-error`);
					return false;
				}
			}
		}

		return true;
	};

	const validateAuthProtectedRoutes = async () => {
		let currentUser = user;
		if (props.isAuthProtected) {
			if(!token){
				props.history.push(`/signin`);
				return false;
			}

			if(!user){
				currentUser = await handleFetchAuthUser();

				if(!currentUser){
					props.history.push(`/signin`);
					return false;
				}
			}

			switch (props.type) {
				case 'employee': {
					if(!currentUser?.roles?.includes(ROLE_EMPLOYEE)){
						store.dispatch(logoutUser());
						props.history.push(`/signin`);
						return false;
					}
					break;
				}
				case 'admin': {
					if (!currentUser?.roles?.includes(ROLE_ANALYST_ADMIN) && !currentUser?.roles?.includes(ROLE_ANALYST)) {
						store.dispatch(logoutUser());
						props.history.push(`/signin`);
						return false;
					}
					break;
				}
				case 'third-party': {
					if (currentUser && !currentUser?.roles?.includes(ROLE_THIRD_PARTY)) {
						store.dispatch(logoutUser());
						props.history.push(`/third-party/signin`);
						return false;
					}
					break;
				}
				default: {
					store.dispatch(logoutUser());
					props.history.push(`/signin`);
					return false;
				}
			}
		} 

		if (props.type === 'third-party' && token && Array.isArray(currentUser?.roles) && !currentUser.roles?.includes(ROLE_THIRD_PARTY)){
			store.dispatch(logoutUser());
			props.history.push(`/third-party/signin`);
			return false;
		}

		return true;
	}

	const handleInit = async () => {
		if(validateAuthProtectedRoutes() && validateRouteModules()){
			setCompleted(true);
		}
	};

	useEffect(() => {
        if(!moduleSettingIsLoading){
            handleInit();
        }
	}, [moduleSettingIsLoading, selectedModuleName, user, token]);

	return (
		<props.layout authProtected={ props.isAuthProtected }>
			{!completed || !props.workspaceLayout.isPreloader ? (
				<div className='card-loading-container'>
					<Spinner className='chat-messages-spinner-loading' color='info' />
				</div>
			) : (
				<props.component {...props.componentProps} />
			)}
		</props.layout>
	);
};

const mapStateToProps = (state) => {
	return {
		token 				:	state.Login.token,
		user				: 	state.Login.user,
		workspaceLayout		:	state.Layout,
		modules				:	state.Modules
	};
};

const mapDispachToProps = (dispach) => {
	return {
		logoutUserSuccess	: () => dispach(logoutUserSuccess()),
		loginUserSuccessful	: (user) => dispach(loginUserSuccessful(user)),
		apiError			: (error) => dispach(apiError(error)),
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispachToProps)(
		memo(BeforeEachRoute)
	)
);
