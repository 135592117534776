export const ROUTE_PATH_ADMIN_ROOT = "/admin/data-protection";
export const ROUTE_PATH_THIRDPARTY_ROOT = "/third-party/data-protection";
export const ROUTE_PATH_EMPLOYEE_ROOT = "/employee/data-protection";

/**
 * admin routes
 */
export const ROUTE_PATH_ADMIN_DASHBOARD                             =   ROUTE_PATH_ADMIN_ROOT + "/dashboard";
export const ROUTE_PATH_ADMIN_KANBAN_BOARD                          =   ROUTE_PATH_ADMIN_ROOT + "/kanban-board";
export const ROUTE_PATH_ADMIN_BREACH_INCIDENT_REQUEST               =   ROUTE_PATH_ADMIN_ROOT + "/incident-register";
export const ROUTE_PATH_ADMIN_DATA_SUBJECT_REQUEST                  =   ROUTE_PATH_ADMIN_ROOT + "/data-subject-request";
export const ROUTE_PATH_ADMIN_HARDWARES_LIST                        =   ROUTE_PATH_ADMIN_ROOT + "/hardwares";
export const ROUTE_PATH_ADMIN_SOFTWARES_LIST                        =   ROUTE_PATH_ADMIN_ROOT + "/softwares";
export const ROUTE_PATH_ADMIN_PROCESSING_DIRECTORIES_LIST           =   ROUTE_PATH_ADMIN_ROOT + "/processing-directory";
export const ROUTE_PATH_ADMIN_TOM                                   =   ROUTE_PATH_ADMIN_ROOT + "/tom";
export const ROUTE_PATH_EMAIL_INBOX                                 =   ROUTE_PATH_ADMIN_ROOT + "/email/inbox";
export const ROUTE_PATH_ADMIN_LATEST_NEWS_LIST                      =   ROUTE_PATH_ADMIN_ROOT + "/latest-news";
export const ROUTE_PATH_ADMIN_ADMINISTRATION_SETTINGS               =   ROUTE_PATH_ADMIN_ROOT + "/administration/settings";
export const ROUTE_PATH_ADMIN_ADMINISTRATION_QUESTIONAIRE           =   ROUTE_PATH_ADMIN_ROOT + "/administration/questionnaire";
export const ROUTE_PATH_ADMIN_ADMINISTRATION_CUSTOMIZATION          =   ROUTE_PATH_ADMIN_ROOT + "/administration/customization";
export const ROUTE_PATH_ADMIN_ADMINISTRATION_HISTORY_LOG            =   ROUTE_PATH_ADMIN_ROOT + "/administration/history-logs";
export const ROUTE_PATH_ADMIN_USER_LIST                             =   ROUTE_PATH_ADMIN_ROOT + "/administration/users";

/**
 * thirdparty routes
 */
export const ROUTE_PATH_THIRDPARTY_HOME                     =   "/third-party/home";
export const ROUTE_PATH_THIRD_PARTY_REPORTS_LIST            =   ROUTE_PATH_THIRDPARTY_ROOT + "/reports";
export const ROUTE_PATH_THIRD_PARTY_BREACH_INCIDENT_REQUEST =   ROUTE_PATH_THIRDPARTY_ROOT + "/data-breach-incident";
export const ROUTE_PATH_THIRD_PARTY_DATA_SUBJECT_REQUEST    =   ROUTE_PATH_THIRDPARTY_ROOT + "/data-subject-request";



/**
 * employee routes
 */
export const ROUTE_PATH_EMPLOYEE_HOME                     =   "/employee/home";
export const ROUTE_PATH_EMPLOYEE_REPORTS_LIST            =   ROUTE_PATH_EMPLOYEE_ROOT + "/reports";
export const ROUTE_PATH_EMPLOYEE_BREACH_INCIDENT_REQUEST =   ROUTE_PATH_EMPLOYEE_ROOT + "/data-breach-incident";
export const ROUTE_PATH_EMPLOYEE_DATA_SUBJECT_REQUEST    =   ROUTE_PATH_EMPLOYEE_ROOT + "/data-subject-request";

