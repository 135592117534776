import { withNamespaces } from "react-i18next";
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import { useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import Select from "react-select";
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { EMPTY_LIST } from "src/common/constants";
import SupplierLocationService from "src/modules/3rd-party-management/apis/SupplierLocationService";
import ThirdpartyUsersService from "src/modules/3rd-party-management/apis/ThirdpartyUsersService";

const Locations = ({
    t,
    supplierId
}) => {
    const [ locations, setLocations ] = useState([]);

    const [ showLocationModal, setShowLocationModal ] = useState(false);

    const [ editingLocationValues, setEditingLocationValues ] = useState(null);

    const avFormRef = useRef();

    const columns = [
        {
            text        :   t("Number"),
            sort        :   false,
            style       :   {
                width   :   '100px'
            },
            formatter   :   (cellContent, row, rowIndex) => {
                return rowIndex + 1;
            }
        },
        {
            dataField   :   "type",
            text        :   t("Type"),
            sort        :   true,
            style       :   {
                width   :   '120px'
            },
            formatter   :   (cellContent) => {
                return cellContent.toLowerCase() === 'office' ? t('Office') : t('Factory')
            }
        },
        {
            dataField   :   "country",
            text        :   t("Country"),
            sort        :   true,
            formatter   :   (cellContent, row) => {
                return t(row.country.name)
            }
        },
        {
            dataField   :   "city",
            text        :   t("City"),
            sort        :   false
        },
        {
            dataField   :   "address",
            text        :   t("Extra Information"),
            sort        :   false
        },
        {
            dataField   : "action",
            text        : t("Action"),
            sort        : false,
            formatter   :   (cellContent, row) => {
                return (
                    <div className="d-flex justify-content-start">
                        <Button onClick={ () => handleDeleteSupplierLocation(row) } disabled={ row.state.isDeletionInProcess } outline color="danger" size="md" className="border-0">
                            {
                                row.state.isDeletionInProcess ? (
                                    <Spinner animation="border" variant="danger" size="sm"/>
                                ) : (
                                    <i className="ri-delete-bin-line font-size-20"></i>
                                )
                            }
                        </Button>

                        <Button onClick={ () => showLocationEditingForm(row) } outline color="primary" size="md" className="border-0">
                            <i className="ri-pencil-line font-size-20"></i>
                        </Button>
                    </div>
                );
            }
        }
    ];

    const setLocationStateFieldsValue = (rowId, fieldsNames, fieldValue) => {
        const fields = Array.isArray(fieldsNames) ? fieldsNames : [fieldsNames];

        setLocations((locations) => {
            const result = [...locations].map((location) => {
                if(location.id === rowId){
                    const newstate = {};

                    fields.forEach((fieldName) => {
                        newstate[[fieldName]] = fieldValue;
                    });

                    return {
                        ...location,
                        state   :   {
                            ...location.state,
                            ...newstate
                        }
                    }
                }
    
                return location;
            });

            return result;
        });
    };

    const {
        data    :   countriesData
    } = useQuery({
		queryKey: ['3rd-party-management-fetch-coutries-list-query'],
		queryFn: async () => {
			const service = ThirdpartyUsersService.getInstance();

			return await service.fetchCountries();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: () => {
			toast(t('An error occurred while fetching countries list.'), {
				type: 'error',
			});
		}
	});

    const handleFetchSupplierLocationsListQuery = useQuery({
		queryKey: ['3rd-party-management-fetch-supplier-locations-list'],
		queryFn: async () => {
			const service = SupplierLocationService.getInstance();

            return await service.list(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: () => {
			toast(t('An error occurred while fetching supplier locations list.'), {
				type: 'error',
			});
		},
	});

    const handleCreateSupplierNewLocationMutation = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierLocationService.getInstance();

            return await service.create(payload);
        },
        onSuccess: () => {
            handleFetchSupplierLocationsListQuery.refetch();

            toast(t("New location created successfully."), {
                type: "success",
            });

            toggleLocationModal();
        },
        onError: () => {
            toast(t("An error occurred while creating new location."), {
                type: "error",
            });
        }
    });

    const handleUpdateSupplierLocationMutation = useMutation({
        mutationFn: async ({
            locationId,
            payload
        }) => {
            const service = SupplierLocationService.getInstance();
            return await service.update(supplierId, locationId, payload);
        },
        onSuccess: () => {
            handleFetchSupplierLocationsListQuery.refetch();

            toast(t("Location updated successfully."), {
                type: "success",
            });

            toggleLocationModal();
        },
        onError: () => {
            toast(t("An error occurred while updating location."), {
                type: "error",
            });
        }
    });

    const handleDeleteSupplierLocationMutation = useMutation({
        mutationFn: async (locationId) => {
            const service = SupplierLocationService.getInstance();

            setLocationStateFieldsValue(locationId, 'isDeletionInProcess', true);

            return await service.delete(supplierId, locationId);
        },
        onSuccess: () => {
            handleFetchSupplierLocationsListQuery.refetch();

            toast(t("Location deleted successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while deleting location."), {
                type: "error",
            });
        }
    });

    const countries = useMemo(() => {
        return (countriesData || []).map((country) => {
            return {
                value   :   country.id,
                label   :   t(country.name),
                code    :   country.code,
            };
        });
    }, [ countriesData, t ])

    const toggleLocationModal = () => setShowLocationModal(!showLocationModal);

    const getCountry = (id) => countries.find((country) => country.value === id)

    const NoDataIndication = () => {
        if(handleFetchSupplierLocationsListQuery.isFetched && !locations.length){
            return (
                <div className="alert m-0" role="alert">
                    <p style={{
                        textAlign: "center",
                        marginBottom: 0,
                    }}>
                        {t(EMPTY_LIST)}
                    </p>
                </div>
            );
        }

        return null;
    };

    const handleCreationModalSubmit = (e, values) => {
        if(editingLocationValues && editingLocationValues.locationId){
            handleUpdateSupplierLocationMutation.mutate({
                locationId  :   editingLocationValues.locationId,
                payload     :   values['location']
            });
        }
        else{
            handleCreateSupplierNewLocationMutation.mutate({
                ...values['location'],
                'supplier'      :   supplierId
            });
        }
    }

    const handleDeleteSupplierLocation = (person) => {
        handleDeleteSupplierLocationMutation.mutate(person.id, {
            onSettled   :   () => {
                setLocationStateFieldsValue(person.id, 'isDeletionInProcess', false);
            }
        });
    }

    const showLocationEditingForm = (location) => {
        setEditingLocationValues({
            'location[type]'    :   location.type,
            'location[country]' :   location.country.id,
            'location[city]'    :   location.city,
            'location[address]' :   location.address,
            'locationId'        :   location.id
        });
    }

    const renderModals = () => {
        const personFormInProcess = (
            handleCreateSupplierNewLocationMutation.isFetching || handleCreateSupplierNewLocationMutation.isLoading
        ) || (
            handleUpdateSupplierLocationMutation.isFetching || handleUpdateSupplierLocationMutation.isLoading
        );

        return (
            <Modal isOpen={ showLocationModal } onClosed={() => {
                editingLocationValues && setEditingLocationValues(null);
            }} backdrop="static" keyboard={ false } size="md" className="contact-person-creation-modal">
                
                <AvForm ref={ avFormRef } onValidSubmit={ handleCreationModalSubmit } className="needs-validation m-2" model={ editingLocationValues }>
                    <ModalHeader toggle={ toggleLocationModal }>
                        {t(editingLocationValues?.locationId ? "Edit Location" : "Add A New Location")}
                    </ModalHeader>

                    <ModalBody>
                        <Row className="mb-3">
                            <Col sm="12">
                                <Label for="new-location-type">
                                    {t("Type")}
                                </Label>
                                <AvRadioGroup 
                                    name="location[type]" 
                                    required 
                                    inline
                                    errorMessage={t("This field cannot be blank")}>
                                    <AvRadio label={t("Office")} value="office" />
                                    <AvRadio label={t("Factory")} value="manufacture" />
                                </AvRadioGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="6" sm="12">
                                <Label for="new-location-country">
                                    {t("Country")}
                                </Label>
                                <Select
                                    placeholder={t("Select") + "..."}
                                    classNamePrefix="select2-selection"
                                    id='new-location-country'
                                    options={ countries }
                                    menuPortalTarget={ document.body }
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    onChange={ (e) => {
                                        const countryInput = avFormRef.current._inputs['location[country]'];
                                        countryInput.value = e.value;
                                        countryInput.validate();
                                    } }
                                    defaultValue={editingLocationValues && getCountry(editingLocationValues['location[country]'])}
                                />
                                <AvField
                                    name="location[country]"
                                    type="hidden" 
                                    errorMessage={t("This field cannot be blank")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                />
                            </Col>

                            <Col md="6" sm="12">
                                <Label for="new-location-city">
                                    {t("City")}
                                </Label>
                                <AvField
                                    name="location[city]"
                                    type="text"
                                    errorMessage={t("This field cannot be blank")}
                                    className="form-control"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    id="new-location-city"
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md="12" sm="12">
                                <Label for="new-location-address">
                                    {t("Address")}
                                </Label>
                                <AvField
                                    name="location[address]"
                                    type="text"
                                    className="form-control"
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: t("This field cannot be blank"),
                                        }
                                    }}
                                    id="new-location-address"
                                />
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={ toggleLocationModal } color="primary" type="button" outline>
                            { t('Cancel') }
                        </Button>

                        <Button onClick={ () => avFormRef.current.submit() } disabled={ personFormInProcess } color="primary" type="button" size="md">
                            {
                                personFormInProcess ? (
                                    <Spinner className="me-2" animation="border" variant="white" size="sm"/>
                                ) : (
                                    <>
                                        {
                                            editingLocationValues?.locationId ? (
                                                <i className="ri-pencil-line align-middle me-2"></i>
                                            ) : (
                                                <i className="ri-add-line align-middle me-2"></i>
                                            )
                                        }
                                    </>
                                )
                                
                            }
                            { t(editingLocationValues?.locationId ? 'Update Location' : 'Add Location') }
                        </Button>
                    </ModalFooter>
                </AvForm>
            </Modal>
        )
    }

    useEffect(() => {
        const list = (handleFetchSupplierLocationsListQuery?.data?.locations || []).map((location) => {
            return {
                'id'            :   location.id,
                'city'          :   location.city,
                'country'       :   {
                    id      :   location.country,
                    name    :   location.countryName 
                },
                'type'          :   location.type,
                'address'       :   location.address,
                'state'         :   {
                    isDeletionInProcess :   false
                }
            }
        });

        setLocations(list);
    }, [ handleFetchSupplierLocationsListQuery.data ]);

    useEffect(() => {
        if(editingLocationValues){
            setShowLocationModal(true);
        }
    }, [ editingLocationValues ]);

    return (
        <>
            <div className="table-responsive products-list mb-4">
                <BootstrapTable
                    loading={ handleFetchSupplierLocationsListQuery.isFetching || handleFetchSupplierLocationsListQuery.isLoading }
                    bordered={ false }
                    keyField="id"
                    data={ locations }
                    columns={ columns }
                    noDataIndication={() => <NoDataIndication />}
                    overlay={overlayFactory({
                        spinner: (
                            <Spinner 
                                animation="border" 
                                variant="primary"
                                size="md"
                            />
                        ),
                        text: `${t('Loading')}...`,
                    })}
                />
            </div>

            <div className="d-flex justify-content-end align-items-center">
                <Button onClick={() => { setShowLocationModal(true) }} 
                    color="primary" 
                    outline>
                        <i className="ri-add-line align-middle me-2 font-size-20"></i>
                        {t('Add A New Location')}
                </Button>
            </div>
            { renderModals() }
        </>
    );
}

export default withNamespaces()(Locations);