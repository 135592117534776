import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  Button,
} from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Accordion } from "react-bootstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TbLoader } from "react-icons/tb";
import CustomizationService from "src/modules/3rd-party-management/apis/CustomizationService";
import IntroImage from "src/assets/images/intro.svg";
import { reloadOrganizationActiveModuleSettingsFromServer } from "src/store/actions";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertToRaw,
} from "draft-js";
import IntroModal from "./intro-modal";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';

const TPDDIntroCustomization = (props) => {
  const {
    t,
    fetchOrganizationDetails,
    reloadActiveModuleSettings,
    organization,
  } = props;
  const details = fetchOrganizationDetails.data;
  const [defaultLanguage, setDefaultLanguage] = useState(null);
  const [defaultTranslation, setDefaultTranslation] = useState();
  const [defaultPageTitle, setDefaultPageTitle] = useState("");
  const [translations, setTranslations] = useState(details?.translations);
  const [introModal, setIntroModal] = useState(false);
  const [langToDisplayOnModal, setLangToDisplayOnModal] = useState(null);

  /*********************************** FUNCTIONS ***************************************** */

  // Submit Information
  const handleSubmitMutation = useMutation({
    mutationFn: async () => {
      if (!defaultTranslation || !defaultTranslation.editorState) {
        throw new Error("EditorState is not defined");
      }

      const rawContentState = convertToRaw(
        defaultTranslation.editorState.getCurrentContent()
      );
      const introHtml = draftToHtml(rawContentState);
      const payload = {
        title: defaultPageTitle,
        intro: introHtml,
        language: defaultTranslation.language_code,
      };

      const service = CustomizationService.getInstance();
      return await service.editTranslation(payload);
    },
    onSuccess: () => {
      reloadActiveModuleSettings();
      toast(t("Data saved successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("Failed to save data."), {
        type: "error",
      });
    },
  });

  // Restore Information
  const restore = () => {
    setTranslations(details?.translations);
    setDefaultPageTitle(defaultTranslation?.title);
    resetDefaultTranslation();
  };

  // Fond and Set Default Language and Translation
  const findDefaultLanguage = () => {
    const { translations } = fetchOrganizationDetails.data;
    const defLang = translations.filter(
      (translation) => translation?.is_default
    )[0];

    let contentState;
    if (defLang.intro) {
      contentState = ContentState.createFromBlockArray(htmlToDraft(defLang.intro).contentBlocks)
    } else {
      contentState = ContentState.createFromText("");
    }

    setDefaultTranslation({
      ...defLang,
      editorState: EditorState.createWithContent(contentState),
    });

    setDefaultPageTitle(defLang?.title);
    const defLangCode = defLang.language_code;
    const defLangTitle = organization.languages.filter(
      (lang) => lang.language_code === defLangCode
    )[0]?.name;
    setDefaultLanguage(defLangTitle);
  };

  // On Editor State Change
  const handleEditorStateChange = (editorState) => {
    setDefaultTranslation((currentTrans) => ({
      ...currentTrans,
      editorState,
    }));
  };

  // Reset Default Translation
  const resetDefaultTranslation = () => {
    let contentState;
    const { intro } = defaultTranslation;
    if (intro) {
        contentState = ContentState.createFromBlockArray(htmlToDraft(intro).contentBlocks)
    } else {
      contentState = ContentState.createFromText("");
    }

    setDefaultTranslation({
      ...defaultTranslation,
      editorState: EditorState.createWithContent(contentState),
    });
  };

  /*************************************** USE EFFECTS ****************************************** */
  useEffect(() => {
    if (fetchOrganizationDetails.data?.translations) {
      findDefaultLanguage();
    }
  }, [fetchOrganizationDetails.data]);
  /*************************************** VIEW ****************************************** */
  return (
    <Row>
      {introModal && (
        <IntroModal
          modal={introModal}
          setModal={setIntroModal}
          fetchOrganizationDetails={fetchOrganizationDetails}
          translation={langToDisplayOnModal}
          closeModal={() => {
            setIntroModal(false);
            setLangToDisplayOnModal(null);
          }}
        />
      )}
      <div className="page-tpdd-customization">
        <Row>
          <Col className="d-flex align-items-start customization-image-col">
            <img src={IntroImage} alt="Intro" />
          </Col>
          <Col className="customization-accordion-col">
            <Accordion defaultActiveKey="0">
              <Accordion.Item
                className="border-none cusom-accordion-item"
                eventKey="0"
              >
                <Accordion.Header className="custom-accordion-header">
                  <div className="p-1 custom-div-header collapsed">
                    {`6. ${t("Intro")}`}{" "}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Label
                    style={{
                      width: "228px",
                      height: "28px",
                      gap: "0px",
                      opacity: "0px",
                      display: "block",
                    }}
                  >
                    {t("Intro And Captions")}
                  </Label>

                  <h6 className="mt-5 mb-3">
                    {t(
                      "Smart Integrity Platform - Reporting Channel Landing Page"
                    )}
                  </h6>
                  <p>
                    {t(
                      "This will appear on your landing page. Provide any information you would like for internal and external parties to understand about reporting on the Smart Integrity Platform."
                    )}
                  </p>
                  <Row className="mt-5">
                    <h4>{t("Default Language") + ": " + t(defaultLanguage)}</h4>
                  </Row>
                  <Row>
                    <Col lg="6" md="6" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="pageTitle">{t("Page Title") + ":"}</Label>
                        <Input
                          name="pageTitle"
                          type="text"
                          disabled={
                            handleSubmitMutation.isLoading ||
                            fetchOrganizationDetails.isLoading ||
                            fetchOrganizationDetails.isFetching
                          }
                          value={defaultPageTitle}
                          onChange={(e) => setDefaultPageTitle(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label for="pageTitle">{t("Intro Text") + ":"}</Label>
                      {defaultTranslation && (
                        <Editor
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          editorState={defaultTranslation.editorState}
                          onEditorStateChange={handleEditorStateChange}
                        />
                      )}
                    </Col>
                  </Row>


                  <Row hidden={!translations?.length}>
                    <Col className="mt-3">
                      <FormGroup>
                        <Label for="translationLanguages">
                          {t("Translation Languages") + ":"}
                        </Label>
                        <Row>
                          {translations
                            .filter((trans) => !trans.is_default)
                            ?.map((translation, index) => {
                              return index % 2 == 0 ? (
                                <Col
                                  lg="6"
                                  md="6"
                                  sm="12"
                                  className="d-flex mb-3"
                                >
                                  <Col lg="3" md="3" sm="12">
                                    <div className="langButton d-flex justify-content-center">
                                      {t(translation.language_name)}
                                    </div>
                                  </Col>
                                  <Col
                                    lg="3"
                                    md="3"
                                    className="ms-3 d-flex align-items-end"
                                  >
                                    <Label
                                      className="text-primary"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setLangToDisplayOnModal(translation);
                                        setIntroModal(true);
                                      }}
                                    >
                                      {t("View And Edit")}
                                    </Label>
                                  </Col>
                                </Col>
                              ) : (
                                <Col
                                  lg="6"
                                  md="6"
                                  sm="12"
                                  className="d-flex mb-3"
                                >
                                  <Col lg="3" md="3" sm="12">
                                    <div className="langButton d-flex justify-content-center">
                                      {t(translation.language_name)}
                                    </div>
                                  </Col>
                                  <Col
                                    lg="3"
                                    md="3"
                                    className="ms-3 d-flex align-items-end"
                                  >
                                    <Label
                                      className="text-primary"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setLangToDisplayOnModal(translation);
                                        setIntroModal(true);
                                      }}
                                    >
                                      {t("View And Edit")}
                                    </Label>
                                  </Col>
                                </Col>
                              );
                            })}
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>

                </Accordion.Body>
                <Row className="p-4">
                  <Col sm="12" className="d-flex justify-content-end">
                    <Button
                      onClick={restore}
                      color="primary"
                      type="button"
                      outline
                      className="me-2"
                      disabled={
                        handleSubmitMutation.mutating ||
                        fetchOrganizationDetails.isLoading ||
                        fetchOrganizationDetails.isFetching
                      }
                    >
                      {t("Restore")}
                    </Button>

                    <Button
                      color="primary"
                      onClick={handleSubmitMutation.mutate}
                      type="submit"
                      disabled={
                        handleSubmitMutation.mutating ||
                        fetchOrganizationDetails.isLoading ||
                        fetchOrganizationDetails.isFetching
                      }
                    >
                      {handleSubmitMutation.mutating ? <TbLoader /> : t("Save")}
                    </Button>
                  </Col>
                </Row>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </div>
    </Row>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const organization = state.Organization;
  const App = state.App;
  const modules = state.Modules;
  return { organization, token, App, modules };
};

const mapDispachToProps = (dispach) => {
  return {
    reloadActiveModuleSettings: () =>
      dispach(reloadOrganizationActiveModuleSettingsFromServer()),
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, mapDispachToProps)(TPDDIntroCustomization)
  )
);
