import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo } from "react";
import { useQuery } from "@tanstack/react-query";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import Spinner from "react-bootstrap/Spinner";
import Breadcrumbs from 'src/components/Common/Breadcrumb';

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import { toast } from "react-toastify";

import DateUtils from "src/services/utils/DateUtils";

import { EMPTY_LIST, INTERNATIONAL_DATE_FORMAT } from "src/common/constants";

import { Button, Col, Container, Row } from "reactstrap";

import {
  HistoryLogActions,
  HistoryLogSections,
  HistoryLogRoles,
} from "src/modules/data-protection/constants";

import TableFilter from "./components/table-filter";

import DetailsModal from "./components/details-modal";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import DataProtectionHistoryLogeService from "src/modules/data-protection/apis/DataProtectionHistoryLogService";

const DataProtectionHistoryLog = ({ t, assetId }) => {
  const [tableFilters, setTableFilters] = useState({
    pageIndex: 1,
    pageSize: 25,
    fromDate: null,
    user: [],
    roles: [],
    sections: [],
  });

  
  const [tableResultTotalCount, setTableResultTotalCount] = useState(0);

  const [logs, setLogs] = useState([]);

  const [selectedLog, setSelectedLog] = useState(null);

  const dateUtils = new DateUtils();

  const tableColumns = [
    {
      dataField: "secondary_id",
      text: "#",
      sort: true,
      key: 1,
      style: {
        width: 120,
      },
    },
    {
      dataField: "action",
      text: t("Activity"),
      sort: true,
      key: 2,
      style: {
        width: 180,
      },
      formatter: (cellContent, row) => {
        return  t(HistoryLogSections[row.section]);
      },
    },
    {
      dataField: "date_time",
      text: t("Date and time"),
      sort: true,
      key: 3,
      style: {
        width: 180,
      },
      formatter: (cellContent, row) => {
        return (
          <div>
            <span>
              {dateUtils.convertDateToDate(
                row.created_at,
                INTERNATIONAL_DATE_FORMAT
              )}
            </span>
            <br />
            <span className="text-muted">
              {dateUtils.convertDateToDate(row.created_at, "HH:mm")}
            </span>
          </div>
        );
      },
    },
    {
      dataField: "user_name",
      text: t("User"),
      sort: true,
      key: 4,
      style: {
        width: 180,
      },
    },
    {
      dataField: "user_role",
      text: t("User Role"),
      sort: true,
      key: 5,
      style: {
        width: 180,
      },
      formatter: (cellContent, row) => {
        return HistoryLogRoles[row.user_role];
      },
    },
    {
      dataField: "description",
      text: t("Description"),
      sort: true,
      key: 6,
      formatter: (cellContent, row) => {
        return t(HistoryLogActions[row?.section][row?.action]);
      },
    },
    {
      dataField: "actions",
      text: t("Action"),
      sort: false,
      key: 7,
      formatter: (cellContent, row) => {
        return (
          <div className="text-danger text-truncate text-capitalize text-center d-flex justify-content-start">
            <Button
              color="primary"
              onClick={() => setSelectedLog(row)}
              outline
              className="border-0"
            >
              <i className="ri-eye-line font-size-20"></i>
            </Button>
          </div>
        );
      },
    },
  ];

    const breadcrumbItems = [
      { title: 'SIP', link: '/' },
      { title: t('History Logs'), link: '#' },
    ];

  const NoDataIndication = () =>
    handleFetchHistoryLogQuery.isFetched &&
    !handleFetchHistoryLogQuery.length ? (
      <div className="alert m-0" role="alert">
        <p
          style={{
            textAlign: "center",
            marginBottom: 0,
          }}
        >
          {t(EMPTY_LIST)}
        </p>
      </div>
    ) : (
      <></>
    );

  const handleFetchHistoryLogQuery = useQuery({
    queryKey: ["data-protection-history-logs"],
    queryFn: async () => {
      const service = DataProtectionHistoryLogeService.getInstance();
      return await service.fetchLogs(tableFilters);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching supplier history logs."), {
        type: "error",
      });
    },
  });

  const handleTableChange = (type, { page, sizePerPage }) => {
    switch (type) {
      case "pagination":
        setTableFilters({
          ...tableFilters,
          pageIndex: page,
          pageSize: sizePerPage,
        });
        break;
      default:
        return false;
    }
  };

  /**
   * use this if you want to filter table on each filter change event
   */
  const onTableFilterChanged = (type, filters) => {
    switch (type) {
      case "fromDate":
      case "toDate":
        setTableFilters({
          ...tableFilters,
          [type]: dateUtils.convertDateToDate(
            new Date(filters[type]),
            "YYYY-MM-DD HH:mm:ss"
          ),
        });
        break;

      case "user":
      case "roles":
      case "sections":
        setTableFilters({
          ...tableFilters,
          [type]: filters[type],
        });
        break;

      default:
        return null;
    }
  };

  const onTableFilterOkButtonClicked = (filters) => {
    let result = {};

    for (const tableFilterKey in tableFilters) {
      if (tableFilterKey in filters) {
        if (tableFilterKey === "fromDate" || tableFilterKey === "toDate") {
          result[[tableFilterKey]] = dateUtils.convertDateToDate(
            new Date(filters[tableFilterKey]),
            "YYYY-MM-DD HH:mm:ss"
          );
        } else {
          result[[tableFilterKey]] = filters[tableFilterKey];
        }
      } else {
        result[[tableFilterKey]] = tableFilters[tableFilterKey];
      }
    }

    setTableFilters(result);
  };

  useEffect(() => {
    if (handleFetchHistoryLogQuery.data) {
      setLogs(handleFetchHistoryLogQuery.data.logs || []);

      setTableResultTotalCount(handleFetchHistoryLogQuery.data.itemsCount || 0);
    }
  }, [handleFetchHistoryLogQuery.data]);

  useEffect(() => {
    handleFetchHistoryLogQuery.refetch();
  }, [tableFilters]);

  return (
    <React.Fragment>
				<div className='page-content'>
					<Container fluid>
						<Breadcrumbs title={t('History Logs')} breadcrumbItems={breadcrumbItems} />
            <Row className="p-4">
              <Col sm="12">
                <PaginationProvider
                  pagination={paginationFactory({
                    custom: true,
                    page: tableFilters.pageIndex,
                    sizePerPage: tableFilters.pageSize,
                    totalSize: tableResultTotalCount,
                    withFirstAndLast: false,
                    alwaysShowAllBtns: true,
                    prePageText: (
                      <span>
                        <i className="ri-arrow-left-s-line"></i> {t("Back")}
                      </span>
                    ),
                    nextPageText: (
                      <span>
                        {t("Next")} <i className="ri-arrow-right-s-line"></i>
                      </span>
                    ),
                    prePageTitle: t("Pre page"),
                    firstPageTitle: t("Next page"),
                    showTotal: true,
                    hideSizePerPage: false,
                    sizePerPageList: [
                      {
                        text: "25",
                        value: 25,
                      },
                      {
                        text: "50",
                        value: 50,
                      },
                    ],
                  })}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <React.Fragment>
                      <Row className="mb-4">
                        <Col sm="12">
                          <TableFilter
                            assetId={assetId}
                            defaultValues={{
                              ...tableFilters,
                            }}
                            onOkButtonClicked={onTableFilterOkButtonClicked}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="12">
                          <BootstrapTable
                            remote={{
                              pagination: true,
                              filter: false,
                              sort: true,
                              cellEdit: false,
                              search: false,
                            }}
                            loading={
                              handleFetchHistoryLogQuery.isFetching ||
                              handleFetchHistoryLogQuery.isLoading
                            }
                            overlay={overlayFactory({
                              spinner: (
                                <Spinner
                                  animation="border"
                                  variant="primary"
                                  size="md"
                                />
                              ),
                              text: "Loading...",
                            })}
                            onTableChange={handleTableChange}
                            defaultSorted={[]}
                            keyField={"id"}
                            responsive
                            bordered={false}
                            data={logs}
                            striped={false}
                            columns={tableColumns}
                            wrapperClasses="table-responsive"
                            classes={"table tpdd-table"}
                            headerWrapperClasses={"thead-light"}
                            style={{
                              overflowX: "auto",
                            }}
                            noDataIndication={() => <NoDataIndication />}
                            {...paginationTableProps}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="12" md="6">
                          <div className="tpdd-pagination-style-1">
                            <PaginationListStandalone {...paginationProps} />

                            <SizePerPageDropdownStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </PaginationProvider>

                {selectedLog && (
                  <DetailsModal
                    assetId={assetId}
                    logId={selectedLog.id}
                    onClose={() => setSelectedLog(null)}
                  />
                )}
              </Col>
            </Row>
          </Container>
      </div>
    </React.Fragment>
  );
};

export default withNamespaces()(withRouter(memo(DataProtectionHistoryLog)));
