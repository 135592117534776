import { withNamespaces } from "react-i18next";

import DateUtils from "src/services/utils/DateUtils";

import { INTERNATIONAL_DATE_FORMAT } from "src/common/constants";

import ReactApexChart from "react-apexcharts";

import { useQuery } from "@tanstack/react-query";

import { toast } from "react-toastify";

import {
  AvatarGroup,
  AvatarItem,
} from "src/modules/data-protection/components/avatar-group";

import Score from "src/modules/data-protection/components/score";

import DataProtectionSoftwareService from "src/modules/data-protection/apis/DataProtectionSoftwareService";

import React, { useEffect, useImperativeHandle, useState } from "react";

import { Col, Row } from "reactstrap";

import OverviewHeaderContent from "src/modules/data-protection/components/overview-header";

import { createSVGFromText } from "src/modules/data-protection/helpers/common";

import {
  HiChevronDoubleDown,
  HiChevronDoubleUp,
  HiMenuAlt4,
} from "react-icons/hi";

import { Assets_STATUS_LIST } from "../../../constants"

const OverviewHeader = ({ t, assetId, actionsRef }) => {
  
  const [asset, setAsset] = useState(null);

  const dateUtils = new DateUtils();

  const handleFetchAssetBasicInformation = useQuery({
    queryKey: ["data-protection-supplier-overview-contnet"],
    queryFn: async () => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.details(assetId);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching overview content."), {
        type: "error",
      });
    },
  });

  const renderLoadingSection = () => {
    return (
      <Row>
        <Col sm="12">
          <div className="loading-section p-5">
            <Row className="mb-5">
              <Col sm="12" md="3">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "40%" }}
                ></div>
                <div
                  className="dt-field dt-skeleton"
                  style={{ width: "70%" }}
                ></div>
              </Col>
              <Col sm="12" md="3">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "40%" }}
                ></div>
                <div
                  className="dt-field dt-skeleton"
                  style={{ width: "70%" }}
                ></div>
              </Col>
              <Col sm="12" md="3">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "40%" }}
                ></div>
                <div
                  className="dt-field dt-skeleton"
                  style={{ width: "70%" }}
                ></div>
              </Col>
              <Col sm="12" md="3">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "40%" }}
                ></div>
                <div
                  className="dt-field dt-skeleton"
                  style={{ width: "70%" }}
                ></div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="4">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "100%" }}
                ></div>
              </Col>
              <Col sm="12" md="4">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "100%" }}
                ></div>
              </Col>
              <Col sm="12" md="4">
                <div
                  className="dt-field dt-skeleton mb-1"
                  style={{ width: "100%" }}
                ></div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  };

  const renderAssignedAnalysts = () => {
    const analysts = asset?.analyst_assigned_object || {};
    const result = [];

    // for (const index in analysts) {
    //   const analyst = analysts[index];
    const analyst = analysts;

    const initials = analyst?.name
      ?.split(" ")
      ?.map((part) => part.charAt(0))
      ?.join("");

    var xml = new XMLSerializer().serializeToString(
      // createSVGFromText(
      //   `${(analyst?.first_name || "").charAt(0)}${(
      //     analyst?.last_name || ""
      //   ).charAt(0)}`
      // )
      createSVGFromText(initials)
    );

    result.push(
      <AvatarItem
        //   id={`overview-analyst-avatar-${index}`}
        id={`overview-analyst-avatar`}
        title={`${analysts?.name}`}
        src={`data:image/svg+xml;charset=utf-8,${xml}`}
        alt=""
      />
    );
    // }
   if(analysts?.avatar && analysts?.avatar !==""){
    return (
      <div className="d-flex justify-content-center align-items-center flex-column">
    <div className="avatar-xs ">
    <span
      className="avatar-title bg-soft-primary text-primary rounded-circle text-capitalize"
      style={{ overflow: "hidden" }}
    >
      <img
        alt={analysts?.name}
        src={analysts?.avatar}
        style={{
          width: "40px",
          height: "40px",
          display: "block",
          objectFit: "cover",
        }}
      />
    </span>
  </div>
    <p className="fs-6">{analysts?.name} </p>
    </div>
    );
   }
    return <AvatarGroup max={4}>{result}</AvatarGroup>;
  };

  useEffect(() => {
    if (handleFetchAssetBasicInformation.data) {
      setAsset(handleFetchAssetBasicInformation.data);
    }
  }, [handleFetchAssetBasicInformation.data]);


  const handleRenderStatus = (status) => {
    const statusData = Assets_STATUS_LIST[status?.toUpperCase()];
  
    if (!statusData) {
      return "---";
    }
  
    return (
      <div
        style={{
          minWidth: "82px",
          fontSize: "10px",
          fontWeight: 700,
          padding: "3px 8px",
          background: statusData.backgroundColor,
          opacity: statusData.opacity || 1,
          color: "#1D1D1D",
          borderRadius: '7px',
          whiteSpace: 'nowrap',
          textAlign: 'center',
        }}
        className="dt-task-status"
      >
        {t(statusData.title)}
      </div>
    );
  };

  useImperativeHandle(actionsRef, () => {
    return {
        reload  :   () => {
            handleFetchAssetBasicInformation.refetch();
        }
    }
});


  
  return (
    <React.Fragment>
      {handleFetchAssetBasicInformation.isFetching ||
      handleFetchAssetBasicInformation.isLoading ||
      !asset ? (
        <>{renderLoadingSection()}</>
      ) : (
        <OverviewHeaderContent
          items={[
            [
              {
                title: t("Asset ID"),
                value: asset?.id,
              },
              {
                title: t("Service"),
                value: asset?.service,
              },
              {
                title: t("Version"),
                value: asset?.version,
              },
              {
                title: t("Operating System"),
                value: t(asset?.operating_system?.full_name),
              },
              {
                title: t("Service Provider"),
                value: t(asset?.provider_name),
              },
              {
                title: t("DPIA"),
                value: (
                  <>
                    <span style={{ display: "flex", justifyContent: "center" }}>
                      {asset?.DPIA_status === "NOT_NEEDED" ? t("Not needed") : t("Needs")}
                    </span>{" "}
                     {
                        asset?.DPIA_status === "NEEDED_AND_COMPLETED" ? <span style={{ display: "flex" }} className="text-success">
                          { t("Completed") }
                        </span> : asset?.DPIA_status === "NEEDED_AND_NOT_FILLED" ? <span style={{ display: "flex" }} className="text-danger">
                            { t("Has not been filled yet") }
                        </span> : ""}
                  </>
                ),
              },
              {
                title: t("DPA"),
                value: <span>{asset?.DPA_status ? t("Yes") : t("No")}</span>,
              },
              {
                title: t("Status"),
                value: (
                  <>
                    {asset?.status ? handleRenderStatus(asset.status) : ""}
                  </>
                ),
              },
              {
                title: t("Priority"),
                value: (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "5%" }}
                  >
                    {asset?.priority.toLowerCase() === "low" ? (
                      <HiChevronDoubleDown style={{ color: "#1CBB8C" }} />
                    ) : asset?.priority.toLowerCase() === "medium" ? (
                      <HiMenuAlt4 style={{ color: "#FCC34B" }} />
                    ) : (
                      <HiChevronDoubleUp style={{ color: "#DC354" }} />
                    )}
                    <span>
                      {t(
                        asset?.priority.charAt(0).toUpperCase() +
                          asset?.priority.slice(1).toLowerCase()
                      )}
                    </span>
                  </div>
                ),
              },
            ],
            [
              {
                title: "Assigned Analyst",
                value: renderAssignedAnalysts(),
              },
              {
                title: "Mitigated Risks",
                value: (
                   asset?.completedMitigatedTasks ? 
                   <>
                   <ReactApexChart
                      options={{
                        chart: {
                          sparkline: {
                            enabled: true,
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        colors: ["#FF4949"],
                        stroke: {
                          lineCap: "round",
                        },
                        plotOptions: {
                          radialBar: {
                            hollow: {
                              margin: 0,
                              size: "70%",
                            },
                            track: {
                              margin: 0,
                            },

                            dataLabels: {
                              show: false,
                            },
                          },
                        },
                      }}
                      series={[asset?.completedMitigatedTasks] }
                      type="radialBar"
                      height="60"
                    />
                    <p className="text-center">
                      {`${asset?.completedMitigatedTasks}%`}
                    </p>
                    </>
                    : 
                   <p>{t("No mitigated risk")}</p> 
                ),
              },
              {
                title: "Completed Tasks",
                value: (
                  asset?.completedTasks ?
                  <>
                    <ReactApexChart
                      options={{
                        chart: {
                          sparkline: {
                            enabled: true,
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        colors: ["#195A4D"],
                        stroke: {
                          lineCap: "round",
                        },
                        plotOptions: {
                          radialBar: {
                            hollow: {
                              margin: 0,
                              size: "70%",
                            },
                            track: {
                              margin: 0,
                            },

                            dataLabels: {
                              show: false,
                            },
                          },
                        },
                      }}
                      series={[asset?.completedTasks]}
                      type="radialBar"
                      height="60"
                    />
                    <p className="text-center">{asset?.completedTasks}%</p>
                  </>
                  :
                  <p>{t("No completed task")}</p> 
                ),
              },
              {
                title: "Privacy Risks",
                value: (
                  <>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor:
                          asset?.privacy_risk?.toLowerCase() === "high"
                            ? "#DC3545"
                            : asset?.privacy_risk?.toLowerCase() === "medium"
                            ? "#FCC34B"
                            : asset?.privacy_risk?.toLowerCase() === "low"
                            ? "#1CBB8C"
                            : "",
                        width: "148px",
                        height: "27px",
                        borderRadius: "10px",
                        paddingTop:'1px',
                        color:'#1D1D1D'
                      }}
                    >
                      {t(asset?.privacy_risk) || ""}
                    </span>
                  </>
                ),
              },
              {
                title: "Financial Operational Risk",
                value: (
                  <>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#" + asset?.financial_risk?.color,
                        width: "148px",
                        height: "27px",
                        borderRadius: "10px",
                        color:'#1D1D1D',
                        paddingTop:'1px'
                      }}
                    >
                      {asset?.financial_risk?.name
                        ? t(asset?.financial_risk?.name)
                        : ""}
                    </span>
                  </>
                ),
              },
            ],
          ]}
        />
      )}
    </React.Fragment>
  );
};

export default withNamespaces()(OverviewHeader);
