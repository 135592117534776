import { withNamespaces } from "react-i18next";
import { memo, useEffect, useMemo, useState } from "react";

import Select from "react-select";
import { connect } from "react-redux";

import {
  HiChevronDoubleUp,
  HiChevronDoubleDown,
  HiMenuAlt4,
} from "react-icons/hi";

import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

// Services
import CommonService from "../../../../../apis/CommonService";
import DataProtectionSoftwareService from "../../../../../apis/DataProtectionSoftwareService";

// styles
import "./style.scss";
import OverviewHeader from "../../../overview-header";
import InfoCards from "../info-cards";

const DataProtectionSoftwareInformation = ({ setAssetId, ...props }) => {
  const { softwareId } = useParams();
  const { t } = props;
  
  const [id, setId] = useState("");

  const [selectedStatus, setSelectedStatus] = useState(null);

  const [selectedAssignedAnalyst, setSelectedAssignedAnalyst] = useState(null);

  const [selectedPriority, setSelectedPriority] = useState(null);

  const [selectedProvider, setSelectedProvider] = useState(null);

  const [selectedAssetType, setSelectedAssetType] = useState(null);

  const [selectedOperatingSystem, setSelectedOperatingSystem] = useState(null);
  const [operatingSystems, setOperatingSystems] = useState([]);

  const [selectedHostingLocation, setSelectedHostingLocation] = useState(null);
  const [selectedNoneEUHostingLocation, setSelectedNoneEUHostingLocation] =
    useState(null);
  const [countries, setCountriesList] = useState([]);

  const [softwareVersion, setSoftwareVersion] = useState("");

  const [serviceName, setServiceName] = useState("");

  const [notice, setNotice] = useState("");

  const [updatingDetailsForm, setUpdatingDetailsForm] = useState(false);

  const [selectedModules, setSelectedModules] = useState([]);

  const [attachments, setAttachments] = useState([]);

  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [responsiblePerson, setResponsiblePerson] = useState("");

  const [legalEntitiesOptions, setLegalEntitiesOptions] = useState([]);
  const [selectedLegalEntities, setSelectedLegalEntities] = useState([]);

  const SoftwareStatuses = useMemo(() => {
    return [
        {
            value       :   'NEW',
            label       :   t('New'),
            baseLabel   :   'New'
        },
        {
            value       :   'IN_PROGRESS',
            label       :   t('In Progress'),
            baseLabel   :   'In Progress'
        },
        {
            value       :   'APPROVED',
            label       :   t('Approved'),
            baseLabel   :   'Approved'
        },
        {
            value       :   'ASSIGNED',
            label       :   t('Assigned'),
            baseLabel   :   'Assigned'
        },
        {
            value       :   'DELETED',
            label       :   t('Deleted'),
            baseLabel   :   'Deleted'
        },
    ]
  }, [t]);

  const Priorities = useMemo(() => {
    return [
        {
            value: "LOW",
            label: (
              <span>
                {<HiChevronDoubleDown className="text-info" />}
                {" " + t("Low")}
              </span>
            ),
          },
          {
            value: "MEDIUM",
            label: (
              <span>
                {<HiMenuAlt4 className="text-success" />}
                {" " + t("Medium")}
              </span>
            ),
          },
          {
            value: "HIGH",
            label: (
              <span>
                {<HiChevronDoubleUp className="text-danger" />}
                {" " + t("High")}
              </span>
            ),
          },
    ]
  }, [t]);

  const handleFetchSoftwareDetailsQuery = useQuery({
    queryKey: ["data-protection-software-details", softwareId],
    queryFn: async () => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.details(softwareId);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);
      toast(t("An error occurred while fetching software details."), {
        type: "error",
      });
    },
  });
  const handleFetchOperatingSystemsListQuery = useQuery({
    queryKey: ["data-protection-operating-systems-list"],
    queryFn: async () => {
      const service = DataProtectionSoftwareService.getInstance();

      return await service.operatingSystems();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occurred while fetching operating systems."), {
        type: "error",
      });
    },
  });

  const handleFetchCountriesListQuery = useQuery({
    queryKey: ["data-protection-countries-list"],
    queryFn: async () => {
      const service = CommonService.getInstance();

      return await service.countries();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occurred while fetching countries."), {
        type: "error",
      });
    },
  });

  const updateDetailsMutation = useMutation({
    mutationFn: async ({ payload }) => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.updateDetails(softwareId, payload);
    },
    onSuccess: () => {
      toast(t("Details updated successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("An error occurred while updating details."), {
        type: "error",
      });
    },
    onMutate: () => {
      setUpdatingDetailsForm(true);
    },
    onSettled: () => {
      setUpdatingDetailsForm(false);
    },
  });

  const submitEditDetailsForm = () => {
    updateDetailsMutation.mutate({
      payload: {
        service: serviceName,
        type: selectedAssetType.value,
        version: softwareVersion,
        provider: selectedProvider.label, // convert to baselabel after translation
        operatingSystem: selectedOperatingSystem.value,
        hostingLocation: selectedHostingLocation.value,
        noneEUHostingLocation: selectedNoneEUHostingLocation.value,
        notice: notice,
      },
    });
  };

  const AddEntityMutation = useMutation({
      mutationFn: async ( entity ) => {
          const service = DataProtectionSoftwareService.getInstance();
          return await service.AddEntity({
              software: softwareId,
              legalEntity: entity.id
          });
      },
      onSuccess: () => {
          toast(t("Legal entity successfully changed."), {
              type: "success",
          });
          handleFetchSoftwareDetailsQuery.refetch();
      },
      onError: () => {
          toast(t("An error occurred while updating legal entity."), {
              type: "error",
          });
      },
  });

  const RemoveEntityMutation = useMutation({
    mutationFn: async ( entity ) => {
        const service = DataProtectionSoftwareService.getInstance();
        return await service.RemoveEntity({
            software: softwareId,
            legalEntity: entity.id
        });
    },
    onSuccess: () => {
        toast(t("Legal entity successfully changed."), {
            type: "success",
        });
        
        handleFetchSoftwareDetailsQuery.refetch();
    },
    onError: () => {
        toast(t("An error occurred while updating legal entity."), {
            type: "error",
        });
    }
});

  const changeSelectedEntity = (newEntity) => {
      // Helper function to check if two entities are equal
      const areEntitiesEqual = (entity1, entity2) => entity1.id === entity2.id && entity1.title === entity2.title;

     // Check for added entities
     const addedEntities = newEntity.filter(newEnt => !selectedLegalEntities.some(selEnt => areEntitiesEqual(newEnt, selEnt)));

     // Check for removed entities
     const removedEntities = selectedLegalEntities.filter(selEnt => !newEntity.some(newEnt => areEntitiesEqual(newEnt, selEnt)));
 
     if (addedEntities.length > 0) {
         // Call AddEntityMutation for the added entities
         addedEntities.forEach(entity => {
             AddEntityMutation.mutate(entity);
         });

     } else if (removedEntities.length > 0) {
         // Call RemoveEntityMutation for the removed entities
         removedEntities.forEach(entity => {
             RemoveEntityMutation.mutate(entity);
         });
     }
  }

  // Fetch Legal Enteties Options
  const fetchLegalEntitiesOptions = useQuery({
		queryKey: ["data-protection-enteties-list"],
		queryFn: async () => {
		  const service = CommonService.getInstance();
	
		  return await service.legalEntities(props?.Organization?.id);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
		  if (process.env.NODE_ENV === "development") console.error(error);
	
		  toast(props.t("An error occurred while fetching legal entities."), {
			type: "error",
		  });
		},
	  });

  useEffect(() => {
    const entities = fetchLegalEntitiesOptions?.data;
    if(entities){
    setLegalEntitiesOptions(entities);}
    }, [fetchLegalEntitiesOptions?.data])


    useEffect(() => {
      const { data } = handleFetchSoftwareDetailsQuery;
      
      if (data) {
        const {
          id,
          asset_id,
          analyst_assigned_object,
          status,
          priority,
          provider,
          legal_entities,
          type,
          operating_system_name,
          hosting_location,
          none_e_u_hosting_location,
          version,
          notice: got_notice,
          service: got_service_name,
          modules: modules,
          attachments: attachments,
          company: company,
          position: position,
          phone_number: phone_number,
          email: email,
          responsible_department: responsible_department,
          responsible_person: responsible_person,
        } = data;

        setAssetId(asset_id);

        setId(id);

        if (legal_entities) {
          setSelectedLegalEntities(legal_entities);
      }

        if (attachments) {
          setAttachments(attachments);
        }

        if (company) {
          setCompany(company);
        }

        if (email) {
          setEmail(email);
        }

        if (responsible_person) {
          setResponsiblePerson({
            value: responsible_person?.id,
            label: responsible_person?.full_name,
          });
        }

        if (position) {
          setPosition(position);
        }

        if (responsible_department) {
          setDepartment(responsible_department);
        }

        if (phone_number) {
          setPhoneNumber(phone_number);
        }

        if (
          analyst_assigned_object &&
          !Array.isArray(data.analyst_assigned_object)
        ) {
          setSelectedAssignedAnalyst({
            value: analyst_assigned_object.id,
            label: analyst_assigned_object.name,
          });
        }

        if (status) {
          setSelectedStatus(
            SoftwareStatuses.find((statusOption) => {
              return statusOption.value.toLowerCase() === status.toLowerCase();
            }) || null
          );
        }

        if (priority) {
          setSelectedPriority(
            Priorities.find((priorityOption) => {
              return (
                priorityOption.value.toLowerCase() === priority.toLowerCase()
              );
            }) || null
          );
        }

        if (provider && provider.id) {
          setSelectedProvider({
            label: provider.name,
            value: provider.id,
          });
        }

        if (type) {
          setSelectedAssetType({
            label: type,
            value: type,
          });
        }

        if (operating_system_name) {
          setSelectedOperatingSystem({
            label: operating_system_name,
            value: operating_system_name.trim().toLowerCase(),
          });
        }

        if (hosting_location) {
          setSelectedHostingLocation({
            label: hosting_location.name,
            value: hosting_location.id,
          });
        }

        if (none_e_u_hosting_location) {
          setSelectedNoneEUHostingLocation({
            label: none_e_u_hosting_location.name,
            value: none_e_u_hosting_location.id,
          });
        }

        if (modules && modules?.length) {
          const result = modules.map((m) => ({
            label: m.name,
            value: m.id,
          }));
          setSelectedModules(result);
        }

        setSoftwareVersion(version || "");

        setNotice(got_notice || "");

        setServiceName(got_service_name || "");
      }
    }, [handleFetchSoftwareDetailsQuery.data]);

  useEffect(() => {
    if (handleFetchOperatingSystemsListQuery.data) {
      const { operationSystems } = handleFetchOperatingSystemsListQuery.data;

      if (operationSystems && Array.isArray(operationSystems)) {
        setOperatingSystems(
          operationSystems.map((os) => {
            return {
              label: os.name,
              value: os.name.trim().toLowerCase(),
            };
          })
        );
      }
    }
  }, [handleFetchOperatingSystemsListQuery.data]);

  useEffect(() => {
    const { data: gotCountryList } = handleFetchCountriesListQuery;

    if (gotCountryList) {
      setCountriesList(
        gotCountryList.map((country) => {
          return {
            label: country.name,
            value: country.id,
          };
        })
      );
    }
  }, [handleFetchCountriesListQuery.data]);

  return (
    <>
      <div className="p-4">
        {softwareId && <OverviewHeader t={t} assetId={softwareId} />}
        {softwareId && (
          <InfoCards
            // General
            t={t}
            assetId={softwareId}
            handleFetchSoftwareDetailsQuery={() =>
              {
                return handleFetchSoftwareDetailsQuery.refetch();
              }
            }
            // Analysts
            selectedAssignedAnalyst={selectedAssignedAnalyst}
            setSelectedAssignedAnalyst={setSelectedAssignedAnalyst}
            // Statuses
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            statuses={SoftwareStatuses}
            // Priorities
            selectedPriority={selectedPriority}
            setSelectedPriority={setSelectedPriority}
            priorities={Priorities}
            // Legal Entities
            changeSelectedEntity={changeSelectedEntity}
            selectedLegalEntities={selectedLegalEntities}
            legalEntitiesOptions={legalEntitiesOptions}
            fetchLegalEntitiesOptions={fetchLegalEntitiesOptions}
            // Providers
            selectedProvider={selectedProvider}
            setSelectedProvider={setSelectedProvider}
            // Types
            selectedAssetType={selectedAssetType}
            setSelectedAssetType={setSelectedAssetType}
            // OS
            selectedOperatingSystem={selectedOperatingSystem}
            setSelectedOperatingSystem={setSelectedOperatingSystem}
            // Version
            version={softwareVersion}
            // Service
            service={serviceName}
            // Modules
            selectedModules={selectedModules}
            // Attachments
            attachments={attachments}
            // Company
            selectedCompany={company}
            // Position
            selectedPosition={position}
            // Phone Number
            selectedPhoneNumber={phoneNumber}
            // Email
            selectedEmail={email}
            // Department
            selectedDepartment={department}
            // Responsible Person
            selectedResponsiblePerson={responsiblePerson}
          />
        )}
      </div>
    </>
  );
};

const mapStatetoProps = (state) => {

  const { Organization } = state;

  return {
    Organization
  };
};
export default withNamespaces()((connect(mapStatetoProps)(memo(DataProtectionSoftwareInformation))));
