import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import Dropzone from "react-dropzone";
import { withNamespaces } from "react-i18next";
import AttachmentCard from "./components/AttachmentCard";
import axios from "axios";
// import { API_BASE_URL } from "src/modules/3rd-party-management/constants/ApiRoutes";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../../constants";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';


const MessageInput = ({
  t,
  sendButtonDisable,
  handlerSendMessage,
  uploadUrl,
  userToken,
  deleteFileUrl,
  dragFiles,
  downloadUrl,
  mentionSettings,
  hasAi,
  generateAIResponse,
  aiButtonDisabled,
  aiEditorState,
}) => {

  const [isDisable, setIsDisable] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handlerMessage = async () => {
    // Perform your actual action with the input value
    const commentContent = convertToRaw(editorState.getCurrentContent());
    const plainText = EditorState.createWithContent(convertFromRaw(commentContent)).getCurrentContent().getPlainText();
    const mentions = getMentionsId(commentContent);

    const messageSent = await handlerSendMessage(plainText, uploadedFiles, mentions);

    if (messageSent) {
      setEditorState(EditorState.createEmpty());
      setUploadedFiles([]);
    }
    // Scroll the chat conversation down after a short delay to allow for DOM update
  };


  // Helper function to check if editor state is empty
  const isEditorStateEmpty = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlocksAsArray();
    return blocks.every(block => !block.getText().trim());
  };
  
  useEffect(() => {
    // Check if aiEditorState has content
    if (aiEditorState && !isEditorStateEmpty(aiEditorState)) {
      setEditorState(aiEditorState);
    }
  }, [aiEditorState]);
  

  useEffect(() => {
    setIsDisable(sendButtonDisable);
  }, [sendButtonDisable]);

  useEffect(() => {
    if (dragFiles && dragFiles?.length > 0) {
      handleAcceptedFiles(dragFiles);
    }
  }, [dragFiles]);

  useEffect(() => {
    // Check if there are any files in progress
    const isInProgress = uploadedFiles.some(
      (file) => file.status === "uploading" || file.status === "deleting"
    );

    // If there are files in progress or if sendButtonDisable is true, disable the button
    setIsDisable(isInProgress || sendButtonDisable);
  }, [uploadedFiles, sendButtonDisable]);



  const onUploadProgress = (e, file, setUploadedFiles) => {
    const { total, loaded, lengthComputable } = e;

    if (lengthComputable) {
      const uploadedPercent = (loaded / total) * 100;

      setUploadedFiles((uploadedFiles) => {
        const index = uploadedFiles.findIndex(
          (f) => f.preview === file.preview
        );

        if (index > -1) {
          uploadedFiles[index].uploadedPercent = uploadedPercent;

          return [...uploadedFiles];
        }

        return [...uploadedFiles];
      });
    }
  };

  const handleAcceptedFiles = (files, id) => {
    const headers = {
      headers: {},
      onUploadProgress: null,
    };

    if (userToken) {
      headers.headers.Authorization = `Bearer ${userToken}`;
    }

    files.map((file) => {
      const formData = new FormData();

      formData.append("file", file);

      file = {
        ...file,
        id: null,
        status: "uploading",
        name: file.name,
        type: file.type,
        questionId: id,
        uploadedPercent: 0,
        preview: URL.createObjectURL(file),
        formattedSize: file.size,
      };

      setUploadedFiles((uploadedFiles) => {
        return [...uploadedFiles, { ...file }];
      });

      axios
        .post(`${API_BASE_URL}/${uploadUrl}`, formData, {
          ...headers,
          onUploadProgress: (e) => {
            onUploadProgress(e, file, setUploadedFiles);
          },
        })
        .then((response) => {
          setUploadedFiles((uploadedFiles) => {
            const index = uploadedFiles.findIndex(
              (f) => f.preview === file.preview
            );

            if (index > -1) {
              if (response.status === 200) {
                const fileData = response.data.data;

                uploadedFiles[index].status = "upload_succeed";

                uploadedFiles[index].id = fileData.id;
              } else {
                uploadedFiles[index].status = "upload_failed";
              }

              return [...uploadedFiles];
            }

            return [...uploadedFiles];
          });
        })
        .catch((error) => {
          setUploadedFiles((uploadedFiles) => {
            const index = uploadedFiles.findIndex(
              (f) => f.preview === file.preview
            );

            if (index > -1) {
              uploadedFiles[index].status = "upload_failed";

              return [...uploadedFiles];
            }

            return [...uploadedFiles];
          });

          toast(t(error.response.data.message), {
            type: "error",
          });
        });
    });
  };

  const handleClickDeleteFiles = (file) => {
    const url = `${API_BASE_URL}/${deleteFileUrl}${file.id}/delete`;

    const headers = {
      headers: {},
    };

    if (userToken) {
      headers.headers.Authorization = `Bearer ${userToken}`;
    }

    const deletedFile = {
      file: file.name,
      software_id: file.id,
    };

    setUploadedFiles((uploadedFiles) => {
      const index = uploadedFiles.findIndex((f) => f.id === file.id);

      if (index > -1) {
        uploadedFiles[index].status = "deleting";

        return [...uploadedFiles];
      }

      return [...uploadedFiles];
    });

    axios
      .post(url, deletedFile, headers)
      .then(() => {
        setUploadedFiles([
          ...uploadedFiles.filter((f) => {
            return f.id !== file.id;
          }),
        ]);
      })
      .catch((error) => {

        setUploadedFiles([
          ...uploadedFiles.filter((f) => {
            return f.id !== file.id;
          }),
        ]);
        toast(t(error?.response?.data.error), {
          type: "error",
        });
      });
  };

  const getMentionsId = (commentContent) => {
    let myMention = commentContent.entityMap;

    let element = [];

    for (let i = 0; i < Object.keys(myMention).length; i++) {
      element.push(myMention[i].data.url);
    }
    return element;
  }
  
  return (
    <React.Fragment>
      <div className="p-3 border-top w-100">
        <div className="d-flex gap-4 align-items-start">
          <Dropzone
            onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="dropzone tpdd">
                <input {...getInputProps()} />
                <Button
                  style={{
                    background: "#fff",
                    border: "none",
                    fontSize: "20px",
                    paddingRight: 0,
                  }}
                  color="#505D69"
                >
                  <i className="ri-attachment-line"></i>
                </Button>
              </div>
            )}
          </Dropzone>

          <div className="w-100 mt-1">
            <div className="message-input-container">
              <Editor
                handleReturn={(e) => {
                  if (e.shiftKey) {
                    return 'insert-newline'; // Return a string to indicate inserting a new line
                  } else {
                    handlerMessage(); // Call the handlerMessage function
                    return 'handled'; // Return a string to indicate handling the action
                  }
                }}
                placeholder={`${t("Enter Message")}...`}
                wrapperClassName="message-input"
                editorState={editorState || aiEditorState} 
                onEditorStateChange={ (state) => setEditorState(state) }
                toolbarHidden={true}
                {
                  ...{
                    mention : (!!mentionSettings?.enable && Array.isArray(mentionSettings?.suggestionList)) ? {
                      separator   : ' ',
                      trigger     : '@',
                      suggestions : mentionSettings.suggestionList
                    } : undefined
                  }
                }
                style={{ overflowWrap: 'break-word' }}
              />
              {uploadedFiles.length > 0 ? (
                <Col xs={12} className="p-3">
                  <div className="d-flex flex-wrap gap-2">
                    {uploadedFiles.map((file, index) => (
                      <AttachmentCard
                        key={index}
                        attachment={file}
                        handleClickDeleteFile={handleClickDeleteFiles}
                        downloadUrl={downloadUrl}
                        t={t}
                      />
                    ))}
                  </div>
                </Col>
              ) : null}
            </div>
          </div>
          
          <Button
            color="primary"
            type="submit"
            className="mt-1 chat-send w-md waves-effect waves-light"
            onClick={handlerMessage}
            disabled={isDisable}
          >
            <span className="d-none d-sm-inline-block me-2">{t("Send")}</span>
            <i className="mdi mdi-send"></i>
          </Button>

          {hasAi && false && 
            <Button
              color="primary"
              type="submit"
              className="mt-1 chat-send w-25 waves-effect waves-light text-nowrap me-2"
              onClick={generateAIResponse}
              disabled={aiButtonDisabled}
              // style={{
              //   whiteSpace: "nowrap",
              //   width: "auto",
              //   maxWidth: "100%"
              // }}
            >
              <span className="d-none d-sm-inline-block">{t("Generate AI Response")}</span>
            </Button>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default withNamespaces()(MessageInput);
