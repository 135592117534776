import { withNamespaces } from "react-i18next";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import Select from 'react-select';

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Spinner
} from "reactstrap";

import { Form } from "react-bootstrap";

import SupplierContactPersonService from "src/modules/3rd-party-management/apis/SupplierContactPersonService";
import SupplierService from "src/modules/3rd-party-management/apis/SupplierService";
import DateUtils from "src/services/utils/DateUtils";


const NewReportRequestModal = ({
    t,
    supplierId,
    show,
    toggle,
    onSuccess
}) => {
    const twoWeeksLaterDate = new Date();
    twoWeeksLaterDate.setDate(twoWeeksLaterDate.getDate() + 14);

    const [ formValidated, setFormValidated ] = useState(false);
    const [ people, setPeople ] = useState([]);
    const [ deadlineDate, setDeadlineDate ] = useState(twoWeeksLaterDate);
    const [ person, setPerson ] = useState(null);
    const [ creationFormInProcess, setCreationFormInProcess ] = useState(false);
    const [ faildValidations, setFaildValidations ] = useState([]); 

    const dateUtils = new DateUtils();

    const handleFetchPeopleList = useQuery({
		queryFn: async () => {
			const service = SupplierContactPersonService.getInstance();

            return await service.list(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching supplier contact list.'), {
				type: 'error',
			});
		},
	});

    const handleCreateNewReportRequestMutation = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierService.getInstance();
            
            setCreationFormInProcess(true);

            return await service.createReportRequest(payload);
        },
        onSuccess: () => {
            onSuccess && onSuccess();

            toast(t("New report request created successfully."), {
                type: "success",
            });

            toggle();
        },
        onError: () => {
            toast(t("An error occurred while creating request."), {
                type: "error",
            });
        },
        onSettled: () => {
            setCreationFormInProcess(false);
        }
    });

    useEffect(() => {
        setPeople((handleFetchPeopleList?.data?.contactPersons || []).map((person) => {
            return {
                value   :   person.id,
                label   :   `${person.name} ${person.lastName}`
            }
        }));
    }, [ handleFetchPeopleList.data ]);

    useEffect(() => {
        if(!show){
            setPerson(null);
            setDeadlineDate(twoWeeksLaterDate);
            setFormValidated(false);
            setFaildValidations([]);
        }
    }, [show])

    const handleCreateRequest = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setFormValidated(true);

        const faildInputs = [];

        if(!person){
            faildInputs.push('person');
        }

        if(!deadlineDate){
            faildInputs.push('deadlineDate');
        }

        if(faildInputs.length > 0){
            setFaildValidations(faildInputs);
            return;
        }

        handleCreateNewReportRequestMutation.mutate({
            supplier            :   supplierId,
            responsiblePerson   :   person.value,
            deadlineDate        :   dateUtils.convertDateToDate(deadlineDate, 'YYYY-MM-DD')
        });
    }

    const peopleListLoading = handleFetchPeopleList.isFetching || handleFetchPeopleList.isLoading;

    const isResponsiblePersonInputInValid = faildValidations.includes('person') || (formValidated && !person);
    const isDeadlineInputInValid = faildValidations.includes('deadline') || (formValidated && !deadlineDate);

    return (
        <Modal size="md" isOpen={ show }>
            <Form noValidate onSubmit={handleCreateRequest}>
                <ModalHeader className="border-0" toggle={ toggle }>
                    {t("Request A New Report")}
                </ModalHeader>
                
                <ModalBody>
                    <Form.Group className="mb-3" controlId="responsiblePerson">
                        <Form.Label>
                            { t('Responsible person') }
                        </Form.Label>

                        <Select
                            placeholder={t('Select...')}
                            name="responsiblePerson"
                            isDisabled={ peopleListLoading }
                            isLoading={ peopleListLoading }
                            classNamePrefix='select2-selection'
                            options={ people }
                            value={ person }
                            onChange={(e) => {
                                setPerson(e);
                                faildValidations.splice(faildValidations.findIndex((item) => item === 'person'), 1)
                            }} 
                        />

                        <Form.Control.Feedback type="invalid" style={{ display: `${isResponsiblePersonInputInValid ? 'block' : 'none'}`}}> 
                            { t('Responsible person not selected') }
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="deadline">
                        <Form.Label>
                            { t('Deadline') }
                        </Form.Label>

                        <DatePicker 
                            name="deadline"
                            id="deadline"
                            className="form-control"
                            selected={ deadlineDate }
                            showTimeSelect={ false }
                            minDate={new Date()}
                            onChange={(date) => {
                                setDeadlineDate(date);
                            }}
                        /> 

                        <Form.Control.Feedback type="invalid" style={{ display: `${isDeadlineInputInValid ? 'block' : 'none'}`}}>
                            { t('Deadline is required') }
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                </ModalBody>

                <ModalFooter>
                    <Button color="danger" onClick={toggle} disabled={ creationFormInProcess }> 
                        { t('Cancel') }
                    </Button>

                    <Button type="submit" color="primary" disabled={ creationFormInProcess }>
                        {
                            creationFormInProcess && (
                                <Spinner animation="border" variant="primary" size="sm"/>
                            )
                        }
                        { t('Submit') }
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
};

export default withNamespaces()(NewReportRequestModal);