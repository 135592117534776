import {
    ROLE_PARTNER,
    ROLE_ADMIN,
    ROLE_ANALYST,
    ROLE_ANALYST_ADMIN
} from "src/components/constants";

import {
    ROUTE_PATH_ADMIN_HOME,
    ROUTE_PATH_ADMIN_USER_LIST,
    ROUTE_PATH_ADMIN_REPORT_ANONYMOUSLY,
    ROUTE_PATH_ADMIN_REPORT_CONFIDENTLY,
    ROUTE_PATH_ADMIN_SETTINGS,
    ROUTE_PATH_ADMIN_CUSTOMIZATION,
    ROUTE_PATH_ADMIN_DOCUMENTS,
    ROUTE_PATH_ADMIN_KANBAN_BOARD,
    ROUTE_PATH_ADMIN_QUESTION_GENERATOR,
    ROUTE_PATH_ADMIN_E_LEARNING,
    ROUTE_PATH_ADMIN_DASHBOARD,
    ROUTE_PATH_ADMIN_TUTORIAL,
    ROUTE_PATH_ADMIN_HISTORY_LOG,
    ROUTE_PATH_PARTNER_CLIENT_MANAGEMENT,
    ROUTE_PATH_ADMIN_RISK_MANAGMENT,
    ROUTE_PATH_ADMIN_ENTITY_MANAGMENT
} from "src/common/constants";

const AdminMenuItems = [
    {
        titleKey    :   "Dashboard",
        path        :   ROUTE_PATH_ADMIN_DASHBOARD,
        icon        :   "ri-home-4-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Cases",
        path        :   ROUTE_PATH_ADMIN_HOME,
        icon        :   "ri-archive-fill",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Kanban Board",
        path        :   ROUTE_PATH_ADMIN_KANBAN_BOARD,
        icon        :   "ri-bar-chart-2-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [
            'kanban-board'
        ],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Administration",
        path        :   null,
        icon        :   "ri-admin-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null,
        items: [
            {
                titleKey    :   "Settings",
                path        :   ROUTE_PATH_ADMIN_SETTINGS,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    :   "Customisation",
                path        :   ROUTE_PATH_ADMIN_CUSTOMIZATION,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    :   "History logs",
                path        :   ROUTE_PATH_ADMIN_HISTORY_LOG,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [
                    "history-logs"
                ],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    :   "Documents",
                path        :   ROUTE_PATH_ADMIN_DOCUMENTS,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [
                    'Documents'
                ],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    :   "User Management",
                path        :   ROUTE_PATH_ADMIN_USER_LIST,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    :   "Questionnaire Designer",
                path        :   ROUTE_PATH_ADMIN_QUESTION_GENERATOR,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [
                    "question-generator"
                ],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    :   "Partner Client Management",
                path        :   ROUTE_PATH_PARTNER_CLIENT_MANAGEMENT,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [],
                roles           :   [
                    ROLE_PARTNER
                ],
                customValidator :   null
            }
        ]
    },
    {
        titleKey    :   "Add a new Case",
        path        :   null,
        icon        :   "ri-mail-send-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null,
        items: [
            {
                titleKey    :   "Report anonymously",
                path        :   ROUTE_PATH_ADMIN_REPORT_ANONYMOUSLY,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   "isAnonymously",
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    :   "Report confidentially",
                path        :   ROUTE_PATH_ADMIN_REPORT_CONFIDENTLY,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   "isConfidentially",
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            }
        ],
    },
    // {
    //     titleKey    :   "Entity Management",
    //     path        :   ROUTE_PATH_ADMIN_ENTITY_MANAGMENT,
    //     icon        :   "ri-error-warning-line",
    //     linkType    :   "react-router",
    //     authProtected   :   true,
    //     settingKey      :   null,
    //     moduleFeatures  :   [],
    //     roles           :   [
    //         ROLE_ADMIN,
    //         ROLE_ANALYST_ADMIN
    //     ],
    //     customValidator :   null
    // },
    {
        titleKey    :   "Risk Management",
        path        :   ROUTE_PATH_ADMIN_RISK_MANAGMENT,
        icon        :   "ri-error-warning-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [
            'risk-assessment'
        ],
        roles           :   [
            ROLE_ADMIN,
            ROLE_ANALYST,
            ROLE_ANALYST_ADMIN
        ],
        customValidator :   null
    },
    {
        titleKey    :   "E-Learning",
        path        :   ROUTE_PATH_ADMIN_E_LEARNING,
        icon        :   "ri-video-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   "isElearning",
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Tutorial",
        path        :   ROUTE_PATH_ADMIN_TUTORIAL,
        icon        :   "ri-video-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    }
];

export default AdminMenuItems;