import React, { useEffect, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Dropdown, DropdownToggle, DropdownMenu, Button } from "reactstrap";

import Form from "react-bootstrap/Form";
import { Nav, NavItem, NavLink } from "reactstrap";

import { icons} from "./constants";

import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import { DateRangePicker, defaultInputRanges, defaultStaticRanges } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';



const TableFilter = ({
  t,
  onFilterChanged,
  onOkButtonClicked,
  defaultValues,
  type,
  categories,
  loading,
  analystAdminsList,
  ownersList,
  ...rest
}) => {
  const [filters, setFilters] = useState({
    fromDate    :   defaultValues.fromDate || null,
    toDate      :   defaultValues.toDate || null,
    owner       :    defaultValues.owner || null,
    manager     :    defaultValues.manager || null,
    sortBy      :    defaultValues.sortBy || null
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("Analyst");
  const dateInputRef = useRef();

  const staticRangesLabels = {
    Today: t("Today"),
    Yesterday: t("Yesterday"),
    "This Week": t("This Week"),
    "Last Week": t("Last Week"),
    "This Month": t("This Month"),
    "Last Month": t("Last Month")
  };
  
  const inputRangesLabels = {
    "days up to today": t("days up to today"),
    "days starting today": t("days starting today")
  };
  function translateRange(dictionary) {
    return (item) =>
      dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item;
  }
  
  const staticRanges = defaultStaticRanges.map(
    translateRange(staticRangesLabels)
  );
  const inputRanges = defaultInputRanges.map(translateRange(inputRangesLabels));


  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const sortOptions = [
    {
        value   :   'priority',
        label   :   (
            <span>
                { t('Priority') }
            </span>
        ),
    },
    {
        value   :   'deadline',
        label   :   (
            <span>
                { t('Deadline') }
            </span>
        )
    },
];

const renderSortByList = () => {
  const result = [];

  for (const option of sortOptions) {
      result.push(
          <div className="filter-item" key={option.value}>
              <Form.Check
                  type="radio"
                  id={`sort-${option.value}-filter`}
                  checked={filters.sortBy === option.value}
                  onChange={() => {
                      setFilters((prevFilters) => ({
                          ...prevFilters,
                          sortBy: option.value,
                      }));
                  }}
                  label={option.label}
              />
          </div>
      );
  }

  return result;
};

const renderAnalystAdminsFilterList = () => {
  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="primary" size="md" />
      </div>
    );
  }

  return (analystAdminsList || []).map((item) => {
    return (
      <div className="filter-item" key={item.value}>
        <Form.Check
          type="radio"
          id={`analysts-${item.value}-filter`}
          checked={filters.manager === item.value}
          onChange={() => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              manager: item.value,
            }));
          }}
          label={`${item.label}`}
        />
      </div>
    );
  });
};


const renderTaskOwnerFilterList = () => {
  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="primary" size="md" />
      </div>
    );
  }

  return (ownersList || []).map((item) => {
    return (
      <div className="filter-item" key={item.value}>
        <Form.Check
          type="radio"
          id={`analysts-${item.value}-filter`}
          checked={filters.owner === item.value}
          onChange={() => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              owner: item.value,
            }));
          }}
          label={`${item.label}`}
        />
      </div>
    );
  });
};
  
  const renderDateFilter = () => {
    return (
      <DateRangePicker
          locale={ locales[rest?.lng] || locales["enUS"]}
          scroll={{ enabled: true }}
          ranges={[
                {
                  startDate: filters.fromDate,
                  endDate: filters.toDate,
                  key: "selection",
                },
              ]}
          onChange={(range) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  fromDate: range.selection.startDate,
                  toDate: range.selection.endDate,
                }));
              }}
          staticRanges={staticRanges}
          inputRanges={inputRanges}
          startDatePlaceholder={t("Early")}
          endDatePlaceholder={t("Continuous")}
          rangeColors={["#5664d2"]}
      />
    );
}


  const renderNavContent = () => {
    switch (activeTab) {
      case 'date':
        return renderDateFilter();

      case "SortBy":
        return renderSortByList();

      case "Analyst":
        return renderAnalystAdminsFilterList();

      case "owner":
        return renderTaskOwnerFilterList();

      default:
        return null;
    }
  };

  useEffect(() => {
    if(activeTab === 'date' && dateInputRef?.current){
        dateInputRef.current.focus();
    }
}, [ activeTab ]);

  return (
    <div className="custom-table-filter kanban-filter">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="secondary" outline>
          <i className="ri-filter-3-fill"></i>
          <span>{t("Filter")}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <div className="filters-container kanban-naviItem">

            <div className="navs-container d-flex justify-content-center ">
              <Nav tabs>
              <NavItem onClick={() => setActiveTab('date')} className={`${activeTab === 'date' && 'active'} `}>
                    <NavLink >
                      <img src={activeTab === 'date' ? icons.date.active : icons.date.default} alt="" />
                      <span>{t('Date')}</span>
                    </NavLink>
                  </NavItem>

                  <NavItem
                  onClick={() => {
                    setActiveTab("SortBy");
                  }}
                  className={`${activeTab === "SortBy" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "SortBy"
                          ? icons.Category.active
                          : icons.Category.default
                      }
                      alt="category-filter"
                    />
                    <span className="mt-1">{t("Sort By")}</span>
                  </NavLink>
                </NavItem>

                <NavItem
                  onClick={() => {
                    setActiveTab("Analyst");
                    // handleFetchRisksManagers.refetch();
                  }}
                  className={`${activeTab === "Analyst" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "Analyst"
                          ? icons.Analyst.active
                          : icons.Analyst.default
                      }
                      alt="analyst-filter"
                    />
                    <span>{t("Task Manager")}</span>
                  </NavLink>
                </NavItem>



                  {type !== "deleted-reports" && 
                <NavItem
                  onClick={() => setActiveTab("owner")}
                  className={`${activeTab === "owner" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "owner"
                          ? icons.Status.active
                          : icons.Status.default
                      }
                      alt="status-filter"
                    />
                    <span>{t("Task Owner")}</span>
                  </NavLink>
                </NavItem>
                }

              

              </Nav>
            </div>

            <div className="navs-content-container">{renderNavContent()}</div>

            <div className="actions-container d-flex align-items-center justify-content-end">
            <Button
                color="secondry"
                size="sm"
                onClick={() => {
                 setFilters(  {  status: null,
                  category: null,
                  risk: null,
                  analystAssigned: null
                })}}
              >
                {t("Reset")}
              </Button>

              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  onOkButtonClicked && onOkButtonClicked(filters);
                  toggle();
                }}
              >
                {t("Ok")}
              </Button>

            </div>
          </div>
        </DropdownMenu>
      </Dropdown>

    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;

  return {
    token
  };
};


export default withNamespaces()(
    connect(mapStatetoProps)(TableFilter)
);
