import { withNamespaces } from 'react-i18next';

import { memo, useEffect, useState } from 'react';

import { withRouter } from "react-router-dom";

import { Container, Col, Row, Card, CardBody, Alert} from 'reactstrap';

import { connect } from 'react-redux';

import axios from 'axios';

import { useQuery } from '@tanstack/react-query';

import { toast } from 'react-toastify';

import { errorNotification, successNotification } from '../../../../../store/actions';

import { API_BASE_URL } from '../../../constants';

import Questionar from '../../../components/Questionar';
import QuestionarConfirmForm from '../../../components/Questionar/ConfirmForm';
import QuestionarService from '../../../components/Questionar/service.js';

import { TwitterWizard, TwitterWizardNavItem } from 'src/modules/data-protection/components/TwitterWizard';

import Breadcrumbs from '../../../../../components/Common/Breadcrumb';

import FollowUpForm from '../../../components/FollowUpForm/index.jsx';

const DataBreachIncident = (props) => {
	const [questionareFilledData, setQuestionareFilledData] = useState(null);

	const [isCreateNewFormSubmiting, setIsCreateNewFormSubmiting] = useState(false);

	const [activeTab, setActiveTab] = useState("fill-questions-step");

	const [submitResult, setSubmitResult] = useState(null);

	const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [prevLocationKey] = useState(props.location.key);

    const [resetFormModel, setResetFormModel] = useState(false);

	const questionareSettings = {
		confirmFilledInputsForm	:	{
			enabled	:	false
		},
		buttons	:	{
			submit	:	{
				enabled	:	true,
				title	:	"Next"
			}
		}
	};

	const handleFetchQuestionsQuery = useQuery({
		queryKey: ['data-protection-thirdparty-data-breach-incident-request'],
		queryFn: async () => {
			const service = QuestionarService.getInstance();
	
			return await service.questions('data_breach_incident', props.Organization.id, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			if (process.env.NODE_ENV === 'development') console.error(error);
	
			toast(props.t('An error occurred while fetching questions.'), {
				type: 'error',
			});
		}
	});

	useEffect(() => {
		if(questionareFilledData){
			setActiveTab('review-filled-answers');
		}
	}, [questionareFilledData]);

	const handleSubmit = () => {
		setIsCreateNewFormSubmiting(true);

		axios.post(`${API_BASE_URL}/report.create`, questionareFilledData, {
			headers: {
				Authorization: `Bearer ${props.token}`,
			}
		})
		.then(() => {
			setSubmitResult('succeed');

			props.successNotification({
				code: props.t("Request Submitted"),
				message: props.t("Request Submitted"),
			});
		})
		.catch(() => {
			setSubmitResult('failed');

			props.errorNotification({
				code: 'validation_error',
				message: props.t('Error!'),
			});
		})
		.finally(() => {
			setIsCreateNewFormSubmiting(false);
		});
	}

	useEffect(() => {
		if(props.token && activeTab === 'finalize-report'){
			handleSubmit();
		}
	}, [props.token, activeTab]);

    useEffect(() => {
        if(props.location.key !== prevLocationKey){
            setResetFormModel(true);
            setActiveTab('fill-questions-step');
            handleFetchQuestionsQuery.refetch();
        }
    }, [ props.location.key ])

	return (
		<div className='page-content'>
			<Container fluid>
				<Breadcrumbs
					title={props.t("Data breach incident")}
					breadcrumbItems={[
						{ title: "SIP", link: "/" },
        				{ title: props.t("Data breach incident"), link: "#" }
					]}
				/>
				<Row>
					<Col sm={12}>
						<Card>
							<CardBody>
								<TwitterWizard activeTab={activeTab}>
									<TwitterWizardNavItem uniqueKey={'fill-questions-step'}>
										<span className="step-number">01</span>
										<span className="step-title">
											{props.t("Report")}
										</span>
									</TwitterWizardNavItem>

									<TwitterWizardNavItem uniqueKey={'review-filled-answers'}>
										<span className="step-number">02</span>
										<span className="step-title">
											{props.t("Review your report")}
										</span>
									</TwitterWizardNavItem>

									{
										!props.user && (
											<TwitterWizardNavItem uniqueKey={'follow-up'}>
												<span className="step-number">03</span>
												<span className="step-title">
													{props.t("Follow-Up")}
												</span>
											</TwitterWizardNavItem>
										)    
									}

									<TwitterWizardNavItem uniqueKey={'finalize-report'}>
										<span className="step-number">04</span>
										<span className="step-title">
											{props.t("Finalize")}
										</span>
									</TwitterWizardNavItem>
								</TwitterWizard>
							</CardBody>
						</Card>
					</Col>
				</Row>

				<Row>
					<Col sm={12}>
						<Card>
							<CardBody>
								<Questionar 
									questionnaireId={`data_breach_incident`}
									questionnaireType={'data_breach_incident'}
									loading={handleFetchQuestionsQuery.isLoading || handleFetchQuestionsQuery.isFetching}
									questions={handleFetchQuestionsQuery.data || []}
									onValidSubmit={ (payload) => setQuestionareFilledData(payload) }
									isFormSubmiting={isCreateNewFormSubmiting}
									user={{
										token: props.token,
										...props.user
									}}
									settings={ questionareSettings }
									className={`${(activeTab !== 'fill-questions-step') && 'd-none'}`}
                                    resetFormModel={resetFormModel}
								/>

								{
									activeTab === 'review-filled-answers' && (
										<QuestionarConfirmForm 
											questions={ handleFetchQuestionsQuery.data || [] }
											answers={ questionareFilledData }
											onConfirmButtonClicked={() => {
												if(!props.user){
													setActiveTab('follow-up');
												}
												else{
													setActiveTab('finalize-report');
												}
											}}
											onBackButtonClicked={() => {
												setActiveTab('fill-questions-step');
											}}
											settings={{
												buttons : {
													confirm	:	{
														title	:	"Next"
													},
													back	:	{
														title	:	"Back"
													}
												}
											}}
										/>
									)
								}

								{
									activeTab === 'follow-up' && (
										<FollowUpForm 
											onValidSubmit={ () => setActiveTab('finalize-report') }
											onBackButtonClicked={() => setActiveTab("review-filled-answers") }
											setUsername={setUsername}
											username={username}
											password={password}
											setPassword={setPassword}
											settings={{
												buttons: {
													back : {
														title : "Back"
													},
													confirm: {
														title : "Submit"
													}
												}
											}}
										/>
									)
								}

								{
									activeTab === 'finalize-report' && isCreateNewFormSubmiting && (
										<p>
											{props.t(
												"Thank you for your patience while your request is being processed."
											)}
										</p>
									)
								}

								{
									activeTab === 'finalize-report' && !isCreateNewFormSubmiting && (
										submitResult === 'succeed' ? (
											<Alert color="success"
												className="alert-dismissible fade show"
												role="alert">
													<i className="mdi mdi-check-all me-2"></i>
													<b>
														{props.t("Report Submitted")}
													</b>
													<p>
														{props.t(
															"If you have any questions, please contact the technical support by sending an email to support@diss-co.tech."
														)}
													</p>
													<p>
														{props.t(
															"If you receive error messages or have a technical questions, please make a screenshot of the error message or your view including the URL and send your request by email to support@diss-co.tech"
														)}
													</p>
											</Alert>
										) : (
											<Alert color="danger"
												className="alert-dismissible fade show"
												role="alert">
													<i className="mdi mdi-check-all me-2"></i>
													<b>
														{props.t("Error!")}
													</b>
													<p>
														{props.t(
															"An error occurred while submitting the form"
														)}
													</p>
											</Alert>
										)
									)
								}
								
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const { Organization } = state;
	
	return {
		token,
		user,
		Organization
	};
};

export default withNamespaces()(
	connect(mapStatetoProps, {
		errorNotification,
		successNotification
	})(
		withRouter(memo(DataBreachIncident))
	)
);
