import React, {
  useState,
  Fragment,
  useCallback,
  useEffect,
} from "react";
import { withNamespaces } from "react-i18next";
import { Row, Col, Button, Container } from "reactstrap";
import DateUtils from "../../../../../services/utils/DateUtils";
import DataProtectionPorcessingDirectoryService from "../../../apis/ProcessingDirectoryService";
import { useQuery } from "@tanstack/react-query";
import ViewProcessingDirectoryModal from "../modal/view";
import StringUtils from "../../../../../services/utils/StringUtils";
import { toast } from "react-toastify";
import "./Style.css";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { EMPTY_LIST, INTERNATIONAL_DATE_FORMAT } from "src/common/constants";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Navigator from "src/modules/data-protection/components/navigator";
import TableFilter from "./components/table-filter";
import { Link } from "react-router-dom";
import { generateDescription, generateAction} from "./components/table-filter/constants"

const DataProtectionProcessingDirectoryList = (props) => {
  const [tableFilters, setTableFilters] = useState({
    pageIndex: 1,
    pageSize: 25,
    fromDate: null,
    toDate: null,
    user: null,
    section: null,
    action: null,
  });
  const [tableResultTotalCount, setTableResultTotalCount] = useState(0);
  const [processDirectoryList, setProcessDirectoryList] = useState([]);

  const dateUtils = new DateUtils();
  const stringUtils = new StringUtils();
  const { t } = props;
  const [selectedActivity, setSelectedActivity] = useState(undefined);
  const [modal, setModal] = useState(false);

  const tableColumns = [
    {
      dataField: "secondary_id",
      text: "#",
      sort: true,
      key: 1,
      style: {
        width: 10,
      },
      formatter: (cellContent, row) => {
        if(row?.metadata.software_id){
          return (
            <Link
            className="text-dark"
            to={`softwares/${row?.metadata.software_id}?tab=processing-activity`}
  
          >
               {cellContent}
          </Link>
          );}
          else{
            return (
          <p
            className="text-truncate text-capitalize text-left"
            style={{ cursor: "pointer" }}
            onClick={() => onViewClick(row)}
          >
            {cellContent}
          </p>)}
        
      },
    },
    {
      dataField: "metadata",
      text: t("Service Name"),
      sort: true,
      key: 2,
      style: {
        width: 160,
      },
      formatter: (cellContent, row) => {
        return (
          <div>
            <span className="subItem">
             
                {t(cellContent?.module_name)
                }
            </span>
          </div>
        );
      },
    },
    // {
    //   dataField: "action",
    //   text: t("Activity"),
    //   sort: false,
    //   key: 2,
    //   style: {
    //     width: 180,
    //   },
    //   formatter: (cellContent, row) => {
    //     return (
    //       <p
            
    //         style={{ cursor: "pointer" }}
    //         onClick={() => onViewClick(row)}
    //       >
    //         {t(generateAction(t, row?.action))}
    //       </p>
    //     );
    //   },
    // },
    {
      dataField: "service",
      text: t("Software Name"),
      sort: true,
      key: 3,
      style: {
        width: 180,
      },
      formatter: (cellContent, row) => {
        return (
          <div>
            <span className="subItem">
             
                {t(stringUtils?.capitalizeFirstLetter(cellContent))
                }
            </span>
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: t("Description"),
      sort: true,
      key: 4,
      style: {
        width: '35%',
      },
      formatter: (cellContent, row) => {
        return (
          <div>
          {t(generateDescription(t, row?.action, row?.section, row?.assetType))}
          </div>
        );
      },
    },
    
    {
      dataField: "user_name",
      text: t("Asset Owner"),
      sort: true,
      key: 5,
      style: {
        width: 220,
      },
      formatter: (cellContent, row) => {
        if (!cellContent || Array.isArray(cellContent)) return null;
        if (cellContent?.avatar && cellContent?.avatar !== "") {
          // avatar exists and is not an empty string
          return (
            <div className="d-flex gap-1 align-items-center">
              <div className="avatar-xs ">
                <span
                  className="avatar-title bg-soft-primary text-primary rounded-circle text-capitalize"
                  style={{ overflow: "hidden" }}
                >
                  <img
                    alt={cellContent?.first_name}
                    src={cellContent?.avatar}
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "block",
                      objectFit: "cover",
                    }}
                  />
                </span>
              </div>{" "}
              {`${cellContent?.first_name} ${cellContent?.last_name}`}
            </div>
          );
        }
        return (
          <div>
            <span className="dt-list-col-bullet me-2">{cellContent[0]}</span>
            {""}
            {`${cellContent}`}
          </div>
        );
      },
    },
    {
      dataField: "created_at",
      text: t("Date"),
      sort: true,
      key: 6,
      formatter: (cellContent, row) => {
        return (
          <div>
            {row.created_at
              ? dateUtils.convertTimeStampToDate(
                  row?.created_at,
                  INTERNATIONAL_DATE_FORMAT
                )
              : null}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: t("Action"),
      sort: false,
      key: 7,
      formatter: (cellContent, row) => {
        return (
          <div className="actions">
            <Button
              color="primary"
              onClick={() => onViewClick(row)}
              outline
              className="border-0"
            >
              <i className="ri-eye-line"></i>
            </Button>
          </div>
        );
      },
    },
  ];

  const onTableFilterOkButtonClicked = (filters) => {
    let result = {};
    for (const tableFilterKey in tableFilters) {
      if (tableFilterKey in filters) {
        if (tableFilterKey === "fromDate") {
          result[tableFilterKey] =
            filters[tableFilterKey] !== null
              ? dateUtils.convertDateToDate(
                  new Date(filters[tableFilterKey]),
                  "YYYY-MM-DD 00:00:00"
                )
              : null;
        }
        else if(tableFilterKey === "toDate"){
            result[tableFilterKey] =
            filters[tableFilterKey] !== null
              ? dateUtils.convertDateToDate(
                  new Date(filters[tableFilterKey]),
                  "YYYY-MM-DD 23:59:59"
                )
              : null;
        } else {
          result[[tableFilterKey]] = filters[tableFilterKey];
        }
      } else {
        result[[tableFilterKey]] = tableFilters[tableFilterKey];
      }
    }
    
    setTableFilters(result);
  };



  const processingActivitiesQuery = useQuery({
    queryKey: ["processingActivities", tableFilters],
    queryFn: async () => {
      const service = DataProtectionPorcessingDirectoryService.getInstance();
      const response = await service.list(tableFilters);
      return response.data;
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError(error) {
      if (process.env.NODE_ENV === "development") console.error(error);
      toast(t("error while fetchinig processing activities"), {
        type: "error",
      });
    },
  });

  useEffect(() => {
    if (processingActivitiesQuery?.data) {
      setProcessDirectoryList(processingActivitiesQuery?.data?.data || []);
      setTableResultTotalCount(processingActivitiesQuery.data?.itemsCount || 0);
    }
  }, [processingActivitiesQuery.data]);

  
  const onViewClick = useCallback((activity) => {
    setSelectedActivity(activity);
    setModal(true);
  }, []);

  const NoDataIndication = () =>
    processingActivitiesQuery.isFetched &&
    !processingActivitiesQuery?.data?.data.length ? (
      <div className="alert m-0" role="alert">
        <p
          style={{
            textAlign: "center",
            marginBottom: 0,
          }}
        >
          {t(EMPTY_LIST)}
        </p>
      </div>
    ) : (
      <></>
    );

  const handleTableChange = (type, data) => {
    switch (type) {
      case "pagination":
        const { page, sizePerPage } = data;
        setTableFilters({
          ...tableFilters,
          pageIndex: page,
          pageSize: sizePerPage,
        });
        break;

      case "sort": {
        const { sortField, sortOrder } = data;

        const sortedList = [...processDirectoryList].sort((a, b) => {
          if (a[sortField] < b[sortField]) return sortOrder === "asc" ? -1 : 1;
          if (a[sortField] > b[sortField]) return sortOrder === "asc" ? 1 : -1;
          return 0;
        });
        setProcessDirectoryList(sortedList);
        break;
      }
      default:
        return false;
    }
  };

  return (
    <div className="page-content ">
      <Container fluid>
        <Row>
          <Col lg="12" className="mb-4">
            <Navigator
              backButtonOptions={() => {
                return {
                  //enable: true,
                  // onClick: () => handleBack(),
                  title: props.t("Back"),
                  icon: <i className="ri-arrow-left-fill"></i>,
                };
              }}
              breadCrumbs={[
                { title: "SIP", link: "/" },
                {
                  title: "Processing Directory",
                  link: `/admin/data-protection/processing-directory`,
                },
              ]}
            />
          </Col>

          <Col lg="12" className="mb-4">
            <div
              className="bg-white"
              style={{
                borderRadius: "7px",
                boxShadow: "0px 0px 4px 0px #4F666E40",
                marginBottom: "40px",
              }}
            >
              <div className="p-4" style={{ marginBottom: "300px" }}>
                <ViewProcessingDirectoryModal
                  t={t}
                  modal={modal}
                  setModal={setModal}
                  activity={selectedActivity}
                />

                <PaginationProvider
                  pagination={paginationFactory({
                    custom: true,
                    page: tableFilters.pageIndex,
                    sizePerPage: tableFilters.pageSize,
                    totalSize: tableResultTotalCount,
                    withFirstAndLast: false,
                    alwaysShowAllBtns: true,
                    prePageText: (
                      <span>
                        <i className="ri-arrow-left-s-line"></i> {t("Back")}
                      </span>
                    ),
                    nextPageText: (
                      <span>
                        {t("Next")} <i className="ri-arrow-right-s-line"></i>
                      </span>
                    ),
                    prePageTitle: t("Pre page"),
                    firstPageTitle: t("Next page"),
                    showTotal: true,
                    hideSizePerPage: false,
                    sizePerPageList: [
                      {
                        text: "25",
                        value: 25,
                      },
                      {
                        text: "50",
                        value: 50,
                      },
                    ],
                  })}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <React.Fragment>
                      <Row className="mb-5 mt-3">
                        <Col sm="12">
                          <div className="d-flex gap-4 justify-content-end">
                            <TableFilter
                              defaultValues={{
                                ...tableFilters,
                              }}
                              onOkButtonClicked={onTableFilterOkButtonClicked}
                              type={props.type}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <BootstrapTable
                            remote={{
                              pagination: true,
                              filter: false,
                              sort: true,
                              cellEdit: false,
                              search: false,
                            }}
                            loading={
                              processingActivitiesQuery.isFetching ||
                              processingActivitiesQuery.isLoading
                            }
                            overlay={overlayFactory({
                              spinner: (
                                <Spinner
                                  animation="border"
                                  variant="primary"
                                  size="md"
                                />
                              ),
                              text: "Loading...",
                            })}
                            onTableChange={handleTableChange}
                            defaultSorted={[]}
                            keyField={"id"}
                            responsive
                            bordered={false}
                            data={processDirectoryList}
                            striped={true}
                            columns={tableColumns}
                            wrapperClasses="table-responsive"
                            classes={"table tpdd-table incident-table"}
                            headerWrapperClasses={"thead-light"}
                            style={{
                              overflowX: "auto",
                            }}
                            noDataIndication={() => <NoDataIndication />}
                            {...paginationTableProps}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="12" md="6">
                          <div className="tpdd-pagination-style-1">
                            <PaginationListStandalone {...paginationProps} />

                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </PaginationProvider>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
const mapStatetoProps = (state) => {
  const { token } = state.Login;
  return {
    token,
  };
};
export default withNamespaces()(
  withRouter(connect(mapStatetoProps)(DataProtectionProcessingDirectoryList))
);
