import HorizontalLayout from '../../../components/HorizontalLayout';

import DataDeletionRequest from '../pages/employee/data-deletion-request';

import DataBreachIncident from '../pages/employee/data-breach-incident';

import DSRRequest from '../pages/employee/dsr';

import ReportDetails from '../pages/reporting/ReportDetails';
import NewMyReportList from "../pages/reporting/MyReport/index";

const dataProtectionRoutes = [
	{
		path: '/employee/data-protection/report/detail',
		component: ReportDetails,
		layout: HorizontalLayout,
		type: 'employee',
		modules: ["gd"]
	},
	{
		path: '/employee/data-protection/reports',
		component: NewMyReportList,
		layout: HorizontalLayout,
		type: 'employee',
		modules: ["gd"],
		title: 'List Reports | SIP',
	},
	{
		path: '/employee/data-protection/data-deletion-request',
		component: DataDeletionRequest,
		layout: HorizontalLayout,
		type: 'employee',
		modules: ["gd"],
		title: 'Data Deletion Request | SIP',
	},
    {
		path: '/employee/data-protection/data-breach-incident',
		component: DataBreachIncident,
		layout: HorizontalLayout,
		type: 'employee',
		modules: ["gd"],
		title: 'Data Breach Incident Request | SIP',
	},
	{
		path			:	'/employee/data-protection/data-subject-request',
		component		:	DSRRequest,
		layout			:	HorizontalLayout,
		type			:	'employee',
		modules         :   ["gd"],
		title			:	'DSR Request | SIP',
	},
];

export default dataProtectionRoutes;
