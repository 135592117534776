import { withNamespaces } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { locals } from "./locals";
import { useEffect, useState } from "react";

const LocalizedPhoneInput = (props) => {
    const [lngChanged, setLngChanged] = useState(false);

    useEffect(() => {
        // this is for PhoneInput component bug
        setLngChanged(true);
        setTimeout(() => {
            setLngChanged(false);
        }, 100);
    }, [ props.t ])

    return (
        !lngChanged && <PhoneInput
            {...props}
            localization={locals[props.lng]}
        />
    )
};

export default withNamespaces()(LocalizedPhoneInput);