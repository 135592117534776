import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import React, { useState, useEffect, memo, useMemo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { successNotification, errorNotification } from "src/store/actions.js";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import {
  HiChevronDoubleUp,
  HiChevronDoubleDown,
  HiMenuAlt4,
} from "react-icons/hi";

import DeleteTaskModal from "src/modules/data-protection/pages/reporting/Components/TaskComponents/DeleteTaskModal";
import CreateTaskModal from "src/modules/data-protection/pages/tom/tabs-content/task-manager/create";

import DateUtils from "src/services/utils/DateUtils";
import { GDPR_REPORT_STATUS_LIST } from "src/modules/data-protection/constants/Common";
import { EMPTY_LIST, INTERNATIONAL_DATE_FORMAT } from "src/common/constants";
import { withRouter, useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import TableFilter from "./table-filter";
import { formatTaskAndRiskId } from "src/modules/data-protection/constants/Common";
import TaskManagerService from "src/modules/data-protection/apis/TaskManagerService";
import DataProtectionTomService from "src/modules/data-protection/apis/DataProtectionTomService";

const TaskManager = (props) => {
  const { t, assetId, onTaskSelected } = props;

  const TaskService = TaskManagerService.getInstance();

  const location = useLocation();

  const [tableFilters, setTableFilters] = useState({
    pageIndex: 1,
    pageSize: 25,
    status: null,
    priority: null,
    owner: null,
  });
  const [tableResultTotalCount, setTableResultTotalCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [deleteTaskId, setDeleteTaskId] = useState(null);
  const [owners, setOwners] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [categories, setCategories] = useState([]);

  const [createTaskModalStatus, setCreateTaskModalStatus] = useState(false);
  const [deleteTaskModalStatus, setDeleteTaskModalStatus] = useState(false);

  const dateUtils = new DateUtils();

  const priorities = useMemo(() => {
    return [
      {
        value: "low",
        baseLabel: "Low",
        label: (
          <>
            <HiChevronDoubleDown className="text-info" />
            <span>{t("Low")}</span>
          </>
        ),
      },
      {
        value: "medium",
        baseLabel: "Medium",
        label: (
          <>
            <HiMenuAlt4 className="text-success" />
            <span>{t("Medium")}</span>
          </>
        ),
      },
      {
        value: "high",
        baseLabel: "High",
        label: (
          <>
            <HiChevronDoubleUp className="text-danger" />
            <span>{t("High")}</span>
          </>
        ),
      },
    ];
  }, [t]);

  const statuses = useMemo(() => {
    return [
      { value: "NEW", label: t("New") },
      { value: "IN_PROGRESS", label: t("In Progress") },
      { value: "REVIEW", label: t("Review") },
      { value: "CLOSED", label: t("Closed") },
      { value: "DELETED", label: t("Deleted") },
    ];
  }, [t]);

  const handleFetchTasksQuery = useQuery({
    queryKey: [
      "fetch-tasks",
      pageIndex,
      pageSize,
    ],
    queryFn: async () => {
      const { status, priority, owner } = tableFilters;
      const param = {
        ...(status !== null && { status }), // Add status if not null or undefined
        ...(priority !== null && { priority }), // Conditionally add risk if not null
        ...(owner !== null && { owner }), // Conditionally add analystAssigned if not null
        pageIndex: tableFilters.pageIndex,
        pageSize: tableFilters.pageSize,
      };
      const service = DataProtectionTomService.getInstance()
      return await service.fetchTasks({
        ...param,
      });
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching supplier tasks."), {
        type: "error",
      });
    },
  });

  const handleFetchSupplierTasksOwners = useQuery({
    queryKey: ["3rd-party-management-fetch-supplier-tasks-owners"],
    queryFn: async () => {
       return await TaskService.fetchOwners(assetId);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching owners."), {
        type: "error",
      });
    },
  });

  const handleFetchSupplierTasksCategories = useQuery({
    queryKey: ["3rd-party-management-fetch-supplier-tasks-categories"],
    queryFn: async () => {
      return await TaskService.fetchCategories(assetId);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching categories."), {
        type: "error",
      });
    },
  });

  const handleDeleteSupplierTaskMutation = useMutation({
    mutationFn: async (taskId) => {
      return await TaskService.deleteTask(assetId, taskId);
    },
    onSuccess: () => {
      setDeleteTaskModalStatus(false);

      handleFetchTasksQuery.refetch();

      toast(t("Task deleted successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("Failed to delete task."), {
        type: "error",
      });
    },
  });

  useEffect(() => {
    handleFetchTasksQuery.refetch();
  }, [tableFilters])

  useEffect(() => {
    if (handleFetchTasksQuery.data) {
      setTasks(handleFetchTasksQuery.data.tasks);
      setTableResultTotalCount(
        handleFetchTasksQuery.data?.itemsCount || 0
      );
    }
  }, [handleFetchTasksQuery.data]);

  useEffect(() => {
    if (handleFetchSupplierTasksOwners.data) {
      setOwners(
        handleFetchSupplierTasksOwners.data.map((item) => {
          return {
            value: item.id,
            label: `${item.first_name} ${item.last_name}`,
          };
        })
      );
    }
  }, [handleFetchSupplierTasksOwners.data]);

  useEffect(() => {
    if (handleFetchSupplierTasksCategories.data) {
      setCategories(
        handleFetchSupplierTasksCategories.data.map((item) => {
          return {
            value: item.id,
            baseLabel: item.name,
            label: t(item.name),
          };
        })
      );
    }
  }, [handleFetchSupplierTasksCategories.data]);

  const taskSelectedToShow = (id) => {
    onTaskSelected &&
      onTaskSelected({
        categories: categories,
        owners: owners,
        authToken: props.token,
        taskId: id,
        t: t,
        successNotification: props.successNotification,
        errorNotification: props.errorNotification,
        deleteTask: async (taskId) => {
          try {
            await handleDeleteSupplierTaskMutation.mutateAsync(taskId);
            return true;
          } catch (e) {
            return false;
          }
        },
        secondaryId: id,
        currentUser: props.user,
        taskStatuses: statuses,
        priorities: priorities,
        module: "tpdd",
      });
  };

  useEffect(() => {
    const taskId = new URLSearchParams(location.search).get("taskId");

    if (taskId && owners.length > 0 && categories.length > 0) {
      taskSelectedToShow(taskId);
    }
  }, [categories, owners, location.search]);


  const renderModals = () => {
    return (
      <React.Fragment>
        <CreateTaskModal
          t={t}
          authToken={props.token}
          isOpen={createTaskModalStatus}
          close={() => setCreateTaskModalStatus(false)}
          currentUser={props.user}
          owners={owners}
          initialTaskStatus={{ value: "NEW", label: t("New") }}
          priorities={priorities}
          riskId={null}
          reportId={assetId}
          reportIdKey={"software"}
          successCreation={() => handleFetchTasksQuery.refetch()}
          successNotification={props.successNotification}
          errorNotification={props.errorNotification}
          assetId={assetId}
          module={"gdpr"}
        />

        <DeleteTaskModal
          t={t}
          isOpen={deleteTaskModalStatus}
          taskId={deleteTaskId}
          close={() => {
            setDeleteTaskId(null);
            setDeleteTaskModalStatus(false);
          }}
          acceptDeletion={(id) => {
            handleDeleteSupplierTaskMutation.mutate(id);
          }}
        />
      </React.Fragment>
    );
  };
  const handleRenderReportStatus = (statusId) => {
    let statutTitle = statusId === "IN_PROGRESS" ? "IN PROGRESS" : statusId;
    const status = GDPR_REPORT_STATUS_LIST.find(
      (item) => item.titleKey.toLocaleUpperCase() === statutTitle
    );
    return (
      <div
        style={{
          fontSize: "10px",
          fontWeight: 700,
          padding: "3px 8px",
          background: status?.backgroundColor,
          opacity: status?.opacity || 1,
          color: "#1D1D1D",
          borderRadius: "7px",
          whiteSpace: "nowrap",
        }}
        className={"dt-task-status "}
      >
        {props.t(status?.titleKey)}
      </div>
    );
  };

  const tableColumns = [
    {
      key: 1,
      dataField: "id",
      text: props.t("Task ID"),
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div>
            <div
              className="dt-task-id"
              style={{
                cursor: !row.deleted_at ? "pointer" : "default",
              }}
              onClick={() => !row.deleted_at && taskSelectedToShow(row.id)}
            >
              {formatTaskAndRiskId(
                "task",
                row?.case_secondary_id,
                row?.risk_secondary_id ?? row.secondary_id,
                row?.is_mitigation,
                row?.questionnaire,
                row.secondary_id
              )}
            </div>{" "}
          </div>
        );
      },
    },
    {
      key: 2,
      dataField: "name",
      text: props.t("Title"),
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div>
            <div
              className={`dt-task-title ${row.deleted_at ? "" : "hoverable"}`}
              style={{
                cursor: !row.deleted_at ? "pointer" : "default",
              }}
              onClick={() => !row.deleted_at && taskSelectedToShow(row.id)}
            >
              {row.name}
            </div>
          </div>
        );
      },
    },
    {
      key: 3,
      dataField: "case_id",
      text: props.t("Creation Date"),
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div>
            <div className="dt-task-creation-date">
              {dateUtils.convertTimeStampToDate(
                row.created_at,
                INTERNATIONAL_DATE_FORMAT
              )}
            </div>
          </div>
        );
      },
    },
    {
      key: 4,
      dataField: "ended_at",
      text: props.t("Deadline"),
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div>
            <div className="dt-task-deadline-date">
              {dateUtils.convertTimeStampToDate(
                row.ended_at,
                INTERNATIONAL_DATE_FORMAT
              )}
            </div>
          </div>
        );
      },
    },
    {
      key: 5,
      dataField: "manager",
      text: props.t("Task Manager"),
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex align-items-center">
            <span className="dt-list-col-bullet">{row.manager[0]}</span>{" "}
            <span className="dt-list-col-bullet-text">{row.manager}</span>
          </div>
        );
      },
    },
    {
      key: 6,
      dataField: "analyst",
      sort: true,
      text: props.t("Task owner"),
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex align-items-center">
            <span className="dt-list-col-bullet">
              {row?.analyst?.full_name[0]}
            </span>{" "}
            <span className="dt-list-col-bullet-text">
              {row?.analyst?.full_name}
            </span>
          </div>
        );
      },
    },
    {
      key: 7,
      dataField: "priority",
      text: props.t("Priority"),
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div className="dt-task-priority">
            {row.priority === "high".toLowerCase() ? (
              <HiChevronDoubleUp className="text-danger" />
            ) : row.priority.toLowerCase() === "medium" ? (
              <HiMenuAlt4 className="text-success" />
            ) : (
              <HiChevronDoubleDown className="text-info" />
            )}
            <span style={{ marginLeft: "4px" }}>{t(row.priority)}</span>
          </div>
        );
      },
    },
    {
      key: 8,
      dataField: "status",
      text: props.t("Status"),
      sort: true,
      formatter: (cellContent, row) => {
        return <div>{handleRenderReportStatus(row.status)}</div>;
      },
    },
    {
      key: 9,
      dataField: "deleted_at",
      text: props.t("Deletion date"),
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div>
            {row.deleted_at
              ? dateUtils.convertTimeStampToDate(
                  row.deleted_at,
                  INTERNATIONAL_DATE_FORMAT
                )
              : null}
          </div>
        );
      },
    },
    {
      key: 9,
      dataField: "actions",
      formatter: (cellContent, row) => {
        return (
          <div>
            {!row.deleted_at ? (
              <div
                style={{
                  fontSize: 20,
                  textAlign: "center",
                  display: "block",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setDeleteTaskId(row.id);
                  setDeleteTaskModalStatus(true);
                }}
              >
                <i className="ri-delete-bin-line text-danger"></i>
              </div>
            ) : (
              <div
                style={{
                  fontWeight: 300,
                  textAlign: "center",
                  display: "block",
                  color: "red",
                }}
              >
                {t("Deleted")}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const onTableFilterOkButtonClicked = (filters) => {
    let result = {};

    for (const tableFilterKey in tableFilters) {
      if (tableFilterKey in filters) {
        if (tableFilterKey === "dateFrom" || tableFilterKey === "dateTo") {
          result[tableFilterKey] =
            filters[tableFilterKey] !== null
              ? dateUtils.convertDateToDate(
                  new Date(filters[tableFilterKey]),
                  "YYYY-MM-DD"
                )
              : null;
        } else {
          result[[tableFilterKey]] = filters[tableFilterKey];
        }
      } else {
        result[[tableFilterKey]] = tableFilters[tableFilterKey];
      }
    }
    setTableFilters(result);
  };

  const NoDataIndication = () =>
    handleFetchTasksQuery.isFetched &&
    !handleFetchTasksQuery.length ? (
      <div className="alert m-0" role="alert">
        <p
          style={{
            textAlign: "center",
            marginBottom: 0,
          }}
        >
          {props.t(EMPTY_LIST)}
        </p>
      </div>
    ) : (
      <></>
    );

  const handleTableChange = (type, { page, sizePerPage }) => {
    switch (type) {
      case "pagination":
        setTableFilters({
          ...tableFilters,
          pageIndex: page,
          pageSize: sizePerPage,
        });
        break;
      default:
        return false;
    }
  };

  return (
    <div className="p-4" style={{ marginBottom: "300px" }}>
      {renderModals()}
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page: tableFilters.pageIndex,
          sizePerPage: tableFilters.pageSize,
          totalSize: tableResultTotalCount,
          withFirstAndLast: false,
          alwaysShowAllBtns: true,
          prePageText: (
            <span>
              <i className="ri-arrow-left-s-line"></i> {props.t("Back")}
            </span>
          ),
          nextPageText: (
            <span>
              {props.t("Next")} <i className="ri-arrow-right-s-line"></i>
            </span>
          ),
          prePageTitle: props.t("Pre page"),
          firstPageTitle: props.t("Next page"),
          showTotal: true,
          hideSizePerPage: false,
          sizePerPageList: [
            {
              text: "25",
              value: 25,
            },
            {
              text: "50",
              value: 50,
            },
          ],
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <React.Fragment>
            <Row className="mb-5 mt-3">
              <Col sm="12">
                <div className="d-flex gap-4 justify-content-end">
                  <TableFilter
                    defaultValues={{
                      ...tableFilters,
                    }}
                    categories={categories}
                    onOkButtonClicked={onTableFilterOkButtonClicked}
                    assetId={assetId}
                  />
                  <Button
                    color="primary"
                    style={{
                      display: "block",
                    }}
                    onClick={() => setCreateTaskModalStatus(true)}
                    outline
                    // disabled={isDeleted || deletedByName}
                  >
                    {t("Create Task")}
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <BootstrapTable
                  remote={{
                    pagination: true,
                    filter: false,
                    sort: true,
                    cellEdit: false,
                    search: false,
                  }}
                  loading={
                    handleFetchTasksQuery.isFetching ||
                    handleFetchTasksQuery.isLoading ||
                    handleFetchSupplierTasksOwners.isLoading ||
                    handleFetchSupplierTasksOwners.isFetching
                  }
                  overlay={overlayFactory({
                    spinner: (
                      <Spinner animation="border" variant="primary" size="md" />
                    ),
                    text: "Loading...",
                  })}
                  onTableChange={handleTableChange}
                  defaultSorted={[]}
                  keyField={"id"}
                  responsive
                  bordered={false}
                  data={tasks}
                  striped={true}
                  columns={tableColumns}
                  wrapperClasses="table-responsive"
                  classes={"table tpdd-table incident-table"}
                  headerWrapperClasses={"thead-light"}
                  style={{
                    overflowX: "auto",
                  }}
                  noDataIndication={() => <NoDataIndication />}
                  {...paginationTableProps}
                />
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="6">
                <div className="tpdd-pagination-style-1">
                  <PaginationListStandalone {...paginationProps} />

                  <SizePerPageDropdownStandalone {...paginationProps} />
                </div>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </PaginationProvider>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const { Organization } = state;
  return {
    user: state.Login.user,
    token,
    organization: Organization,
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      successNotification,
      errorNotification,
    })(memo(TaskManager))
  )
);
