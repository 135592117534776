import {
  ROLE_ANALYST,
  ROLE_ANALYST_ADMIN,
} from "../../../components/constants";

// layouts
import VerticalLayout from "./../../../components/VerticalLayout";

// components
import DataProtectionSoftwareMainPage from './../pages/software';
import DataProtectionSoftwareCreate from "./../pages/software/create";
import DataProtectionSoftwareDetails from "./../pages/software/details";
import DataProtectionHardwareList from "./../pages/hardware";
import DataProtectionHardwareCreate from "./../pages/hardware/create";
import DataProtectionHardwareDetails from "./../pages/hardware/details";
import DataProtectionListReports from "../pages/admin/reports/list";
import ReportDetails from "../pages/reporting/ReportDetails";
import DataProtectionProcessingDirectoryList from "./../pages/processing-directory/list";
import NewsPage from "../pages/news/tabs-content/latest-news";
import DataProtectionQuestionnairesList from "../pages/admin/administration/questionaire";
import DataProtectionAdministrationSettings from "../pages/admin/administration/settings";
import DataProtectionAdministrationCustomization from "../pages/admin/administration/customization";
import DataProtectionHistoryLog from "../pages/admin/administration/history-log";
import LatestNewsDetails from "./../pages/software/details/tabs-content/latest-news/details";
import incidentRegister from '../pages/admin/incident-register';
import AddNewIncident from "../pages/admin/data-breach-incident-register";
import IncidentDetails from '../pages/admin/incident-register/details';
import DataSubjectRequest from '../pages/admin/data-subject-request';
import DataSubjectDetails from "../pages/admin/data-subject-request/details"
import Dashboard from "../pages/admin/dashboard";
import policy from "../pages/admin/policy";
import news from "../pages/news";
import IndexKanban from '../pages/admin/kanban-board';
import ListUsers from 'src/pages/Admin/administration/userMAnagment/ListUsers';
import TOM from "src/modules/data-protection/pages/tom";
import Mailer from "../pages/mailer";

const dataProtectionRoutes = [
  {
    path: "/admin/data-protection/dashboard",
    component: Dashboard,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN, ROLE_ANALYST],
    modules: ["gd"],
    title: "Dashboard | SIP",
  },
  {
		path: '/admin/data-protection/kanban-board',
		component: IndexKanban,
		layout: VerticalLayout,
		type: 'admin',
		modules: ["gd"]
	},

  {
		path: '/admin/data-protection/data_privacy',
		component: policy,
		layout: VerticalLayout,
		type: 'admin',
		modules: ["gd"]
	},

  {
		path: '/admin/data-protection/user_policy',
		component: policy,
		layout: VerticalLayout,
		type: 'admin',
		modules: ["gd"]
	},

  // softwares
  {
    path: "/admin/data-protection/softwares/create",
    component: DataProtectionSoftwareCreate,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN, ROLE_ANALYST],
    modules: ["gd"],
    title: "Create Software | SIP",
  },
  {
    path: "/admin/data-protection/softwares/:softwareId",
    component: DataProtectionSoftwareDetails,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN, ROLE_ANALYST],
    modules: ["gd"],
    title: "Software Details | SIP"
  },
  {
    path: "/admin/data-protection/softwares",
    // component: DataProtectionSoftwareList,
		component: DataProtectionSoftwareMainPage,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN, ROLE_ANALYST],
    modules: ["gd"],
    title: "Software List | SIP",
  },

  // hardware
  {
    path: "/admin/data-protection/hardwares/create",
    component: DataProtectionHardwareCreate,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN, ROLE_ANALYST],
    modules: ["gd"],
    title: "Create Hardware | SIP",
  },
  {
    path: "/admin/data-protection/hardwares/:hardwareId",
    component: DataProtectionHardwareDetails,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN, ROLE_ANALYST],
    modules: ["gd"],
    title: "Hardware Details | SIP"
  },
  {
    path: "/admin/data-protection/hardwares",
    component: DataProtectionHardwareList,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN, ROLE_ANALYST],
    modules: ["gd"],
    title: "Hardware List | SIP",
  },

  // reports
  {
    path: "/admin/data-protection/reports",
    component: DataProtectionListReports,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN, ROLE_ANALYST],
    modules: ["gd"],
    title: "Case Management | SIP",
  },
  {
    path: "/admin/data-protection/report/detail",
    component: ReportDetails,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN, ROLE_ANALYST],
    modules: ["gd"],
    title: "Case Management | SIP",
  },


  // processing directory
  {
    path: "/admin/data-protection/processing-directory",
    component: DataProtectionProcessingDirectoryList,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN, ROLE_ANALYST],
    modules: ["gd"],
    title: "Processing Directory | SIP",
  },

  {
    path: "/admin/data-protection/administration/customization",
    component: DataProtectionAdministrationCustomization,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN],
    modules: ["gd"],
    title: "Cistomization | SIP",
  },
  {
    path: "/admin/data-protection/administration/history-logs",
    component: DataProtectionHistoryLog,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN],
    modules: ["gd"],
    title: "History logs | SIP",
  },
  {
    path: "/admin/data-protection/administration/settings",
    component: DataProtectionAdministrationSettings,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN],
    modules: ["gd"],
    title: "Settings | SIP",
  },
  {
    path: "/admin/data-protection/administration/users",
    component: ListUsers,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN],
    modules: ["gd"],
    title: "User Management",
  },
  {
    path: "/admin/data-protection/administration/questionnaire",
    component: DataProtectionQuestionnairesList,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN],
    modules: ["gd"],
    title: "Questionnaire | SIP"
  },

  {
    path: "/admin/data-protection/tom",
    component: TOM,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN],
    modules: ["gd"],
    title: "TOM | SIP",
  },

  {
    path: "/admin/data-protection/email/inbox",
    component: Mailer,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN],
    modules: ["gd"],
    title: "Email | SIP",
  },

 // news
  {
    path: "/admin/data-protection/news",
    component: NewsPage,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN],
    modules: ["gd"],
    title: "News | SIP",
  },


  // Latest News
  {
    path: "/admin/data-protection/latest-news/details/:id",
    component: LatestNewsDetails,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN, ROLE_ANALYST],
    modules: ["gd"],
    title: "Latest News | SIP",
  },
  {
    path: "/admin/data-protection/latest-news",
    component: news,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN, ROLE_ANALYST],
    modules: ["gd"],
    title: "Latest News | SIP",
  },

  // requests
  {
    path: "/admin/data-protection/incident-register/details/:caseId",
    component: IncidentDetails,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN],
    modules: ["gd"],
    title: "Incident Register | SIP",
  },
  {
    path: "/admin/data-protection/incident-register/create",
    component: AddNewIncident,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN],
    modules: ["gd"],
    title: "Incident Register  SIP",
  },
  {
    path: "/admin/data-protection/incident-register",
    component: incidentRegister,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN],
    modules: ["gd"],
    title: "Incident Register  SIP",
  },

  // data subject requests
  {
    path: "/admin/data-protection/data-subject-request/details/:caseId",
    component: DataSubjectDetails,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN],
    modules: ["gd"],
    title: "Data Subject | SIP",
  },
  {
    path: "/admin/data-protection/data-subject-request",
    component: DataSubjectRequest,
    layout: VerticalLayout,
    type: "admin",
    roles: [ROLE_ANALYST_ADMIN],
    modules: ["gd"],
    title: "Data Subject | SIP",
  },
];

export default dataProtectionRoutes;
