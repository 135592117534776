import { withNamespaces } from "react-i18next";
import { Row, Col, CardBody, Card, CardTitle } from "reactstrap";
import { useEffect, useState } from "react";
import Select from 'react-select';
import { useQuery } from "@tanstack/react-query";
import DashboardService from "../../../../../apis/DashboardService";

import DownloadStatistics from '../../components/DownloadStatistics';
import Widget from "../../components/Widget";
import { DonutChart, LineChart } from "../../components/ApexChart";
import Knob from "../../components/Knob";

import { taskStatuses } from "../../../../reporting/TaskManager/constants";
import LatestCases from "../../components/LatestCases";
import { toast } from "react-toastify";

const TabProcessingActivityContent = (props) => {
    const {
        t
    } = props;


	const [ widgetsList, setWidgetsList ] = useState([]);
	const [ statusesList, setStatusesList ] = useState([]);
	const [ assetsByDateList, setAssetsByDateList ] = useState([]);
	const [ assignedToMe, setAssignedToMe ] = useState([]);
	const [ myHardware, setMyHardware ] = useState([]);
	const [ mySoftware, setMySoftware] = useState([]);
	const [ myTasks, setMyTasks] = useState([]);
	const [ totalCases ,setTotalCases ] = useState(0);


	const [dateFilter, setDateFilter] = useState(null);

    const handleFetchDashboardQuery = useQuery({
		queryKey: ["data-protection-admin-incidents-dashboard"],
		queryFn: async () => {
		  const service = DashboardService.getInstance();
	
		  return await service.list({
			date				: 	dateFilter?.value,
			questionnaire_name	:	"processing_activity"	
		  });
		},
		cacheTime: 0,
		onError: (error) => {
		  if (process.env.NODE_ENV === 'development') console.error(error);
	
		  toast(t("An error occurred while fetching dashboard data."), {
			type: "error",
		  });
		},
	});

    const rendeDateFilterOptions = () => {
		const filterOptions = [
			{
				value       :   'last_year',
				label       :   t('Last year'),
                baseLabel   :   'Last year',
			},
			{
				value       :   'this_year',
				label       :   t('This year'),
                baseLabel   :   'This year',
			},
			{
				value       :   'last_three_month',
				label       :   t('Last three month'),
                baseLabel   :   'Last three month',
			},
			{
				value       :   'this_month',
				label       :   t('This month'),
                baseLabel   :   'This month',
			},
		];

		return (
			<Col lg={4}>
				<Select
					required
					isClearable={true}
					name='sortByDate'
					classNamePrefix='select2-selection'
					options={filterOptions}
					value={dateFilter && {...dateFilter, label: t(dateFilter?.baseLabel)}}
					onChange={(e) => setDateFilter(e)}
					placeholder={t('Filter by date')}
				/>
			</Col>
		);
	}

    useEffect(() => {
		if(dateFilter){
			handleFetchDashboardQuery.refetch()
		}
	}, [dateFilter]);

	useEffect(() => {
		const { data } = handleFetchDashboardQuery;
		if(data){
			const {
				statuses		: 	gotStatuses,
				assetsByDate	: 	gotAssetsByDate,
				assignedToMe 	: 	gotAssignedToMe,
				myHardware 		:	gotHardware,
				mySoftware 		:	gotSoftware,
				totalCases		: 	gotTotalCases,
				myTasks			:	gotTasks
			} = handleFetchDashboardQuery.data;

			const widgets = [];
			const statuses = [];
			const hardwares = [];
			const softwares = [];
			const tasks = [];

			/**
			 * widgets list
			 */
			widgets.push({
				amount	:	gotTotalCases,
				percent	: 	100,
				title	: 	'Total',
				icon	: 	'ri-stack-line',
				displayChart	: false,
			});
			/**-------------------------- */

			/**
			 * statuses list and widgets list
			 */
			for (const item in gotStatuses) {
				statuses.push({
					amount	:	gotStatuses[item].amount,
					percent	:	gotStatuses[item].percent,
					title	:	gotStatuses[item].title,
				});

				if (!['assigned', 'deleted'].includes(gotStatuses[item].title)) {
					widgets.push({
						amount	:	gotStatuses[item].amount,
						title	: 	gotStatuses[item].title,
						icon	: 	null,
						chart: {
							show	:	true,
							percent : 	gotStatuses[item].percent
						}
					});
				}
			}
			/**--------------------------- */

			/**
			 * hardwares
			 */
			for (const item in (gotHardware || [])) {
				// console.log("Item", item)
				// console.log("GIT", gotHardware)
				const title = gotHardware[item]?.title;
				// const color = 
				// 	title === "NEW" ? "rgba(28, 187, 140, 1" : 
				// 	title === "APPROVED" ? "rgba(133, 248, 255, 1)" : 
				// 	title === "ASSIGNED" ? "rgba(74, 163, 255, 1)" : 
				// 	title === "DELETED" ? "rgba(255, 61, 96, 1)" :
				// 	title === "IN_PROGRESS" ? "rgba(86, 100, 210, 1)" : 
				// 	title === "REJECTED" ? "rgba(116, 120, 141, 1)" : ""
				hardwares.push({
					// color	: 	gotHardware[item].color,
					// color: color,
					amount	: 	gotHardware[item].amount,
					title	: 	gotHardware[item].title,
					percent	:	gotHardware[item].percent,
				});
			}
			/**-------------------------------- */

			/**
			 * softwares
			 */
			for (const item in (gotSoftware || [])) {
				softwares.push({
					color	: 	gotSoftware[item].color,
					amount	: 	gotSoftware[item].amount,
					title	: 	gotSoftware[item].title,
					percent	:	gotSoftware[item].percent,
				});
			}
			/**-------------------------------- */

			/**
			 * tasks
			 */
			for (const item in (gotTasks || [])) {
				if(!['DELETED'].includes(item)){
					tasks.push({
						color	: 	gotTasks[item]?.color,
						amount	: 	gotTasks[item]?.amount,
						title	: 	t((taskStatuses.find((s) => s.value === item))?.label),
						percent	:	gotTasks[item]?.percent,
					});
				}
			}
			/**-------------------------------- */

			setWidgetsList(widgets);
			setStatusesList(statuses);
			setAssetsByDateList(gotAssetsByDate);
			setAssignedToMe(gotAssignedToMe);
			setMyHardware(hardwares);
			setMySoftware(softwares);
			setMyTasks(tasks);
			setTotalCases(gotTotalCases);
			
		}
	}, [handleFetchDashboardQuery.data]);
    return (
        <>
            <Row className="mb-4 mt-4">
                <Col lg={8}>
                    <Row>
                        { rendeDateFilterOptions() }
                    </Row>
                </Col>
                {/* <Col lg={4}>
                    <DownloadStatistics filterQuery={null} />
                </Col> */}
            </Row>

			<Row>
				<Col lg={8}>
					<Row>
						{
							widgetsList.map((widgetData) => {
								return (
									<Col lg={4}>
										<Widget {...widgetData} />
									</Col>
								)
							})
						}
					</Row>

					<Row>
						<Col lg={12}>
							<LineChart title="Amount of assets" data={ assetsByDateList } />
						</Col>
					</Row>
				</Col>

				<Col lg={4}>
					<Card className="card_box_Shadow">
						<CardBody>
							<div className="mb-5">
								<CardTitle>
									{t("Assets assigned to me")}
								</CardTitle>
							</div>
							<div className="text-center" style={{ marginBottom: 84 }}>
								<Knob value={ assignedToMe?.percent } width={254} height={255} fgColor="#5664d2" lineCap="butt" readOnly={true} thickness={0.2} />
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>

			<Row>
				<Col xl={4} lg={6} md={12} sm={12}>
					<DonutChart title={t('My hardware')} categories={ myHardware } />
				</Col>
				<Col xl={4} lg={6} md={12} sm={12}>
					<DonutChart title={t('My software')} categories={ mySoftware } />
				</Col>
				<Col xl={4} lg={6} md={12} sm={12}>
					<DonutChart title={t('My Tasks')} categories={ myTasks } />
				</Col>
			</Row>
{/* 
			<Row>
				<Col lg={12}>
					<LatestCases orgIsAll={false} type={"processing_activity"} />
				</Col>
			</Row> */}
        </>
    );
}

export default withNamespaces()(TabProcessingActivityContent);