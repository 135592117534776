import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Row, Col, Label, Input, FormGroup, Button } from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Accordion } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { TbLoader } from "react-icons/tb";
import CustomizationService from "src/modules/3rd-party-management/apis/CustomizationService";
import LogoImage from "src/assets/images/logo-icon.svg";
import Dropzone from "react-dropzone";
import { reloadOrganizationActiveModuleSettingsFromServer } from "src/store/actions";

const TPDDCompanyLogoCustomization = (props) => {
  const { t, fetchOrganizationDetails, reloadActiveModuleSettings } = props;
  const details = fetchOrganizationDetails.data;
  const [coloredLogo, setColoredLogo] = useState(details?.primary_logo || []);
  const [whiteLogo, setWhiteLogo] = useState(details?.secondary_logo || []);
  const [reducedMenuIcon, setReducedMenuIcon] = useState(
    details?.small_logo || []
  );
  const [coloredLogoSize, setColoredLogoSize] = useState(
    details?.primary_logo_size
  );
  const [whiteLogoSize, setWhiteLogoSize] = useState(
    details?.secondary_logo_size
  );
  const [reducedMenuIconSize, setReducedMenuIconSize] = useState(
    details?.small_logo_size
  );

  const dropzoneStyle = {
    background: "#fff",
    border: "none",
    fontSize: "20px",
    paddingRight: 0,
    fontWeight: "400",
  };

  const prevImageStyle = {
    marginTop: "10px",
    maxWidth: "100%",
    maxHeight: "200px",
  };

  /*********************************** FUNCTIONS ***************************************** */

  // Submit Information
  const handleSubmitMutation = useMutation({
    mutationFn: async () => {
      const formData = new FormData();
      // Append logos to formData

      if (coloredLogo.length > 0 && typeof coloredLogo[0] === "object") {
        formData.append("primaryLogo", coloredLogo[0]);
      }
      if (whiteLogo.length > 0 && typeof whiteLogo[0] === "object") {
        formData.append("secondaryLogo", whiteLogo[0]);
      }

      if (
        reducedMenuIcon.length > 0 &&
        typeof reducedMenuIcon[0] === "object"
      ) {
        formData.append("smallLogo", reducedMenuIcon[0]);
      }

      // Append sizes to formData
      formData.append("primaryLogoSize", coloredLogoSize);
      formData.append("secondaryLogoSize", whiteLogoSize);
      formData.append("smallLogoSize", reducedMenuIconSize);

      const service = CustomizationService.getInstance();
      return await service.editLogos(formData);
    },
    onSuccess: () => {
      reloadActiveModuleSettings();
      fetchOrganizationDetails.refetch();
      toast(t("Data saved successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(t("Failed to save data."), {
        type: "error",
      });
    },
  });

  // Restore Information
  const restore = () => {
    const { primary_logo, secondary_logo, small_logo } = details;
    setColoredLogo(primary_logo || []);
    setWhiteLogo(secondary_logo || []);
    setReducedMenuIcon(small_logo || []);
  };

  // Submiting files
  const handleAcceptedFiles = (type, acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      return type === "colored"
        ? setColoredLogo([file])
        : type === "white"
        ? setWhiteLogo([file])
        : type === "reduced"
        ? setReducedMenuIcon([file])
        : null;
    }
  };

  /*************************************** VIEW ****************************************** */
  return (
    <Row>
      <div className="page-tpdd-customization">
        <Row>
          <Col className="d-flex align-items-start customization-image-col">
            <img src={LogoImage} alt="Logo" />
          </Col>
          <Col className="customization-accordion-col">
            <Accordion defaultActiveKey="0">
              <Accordion.Item
                className="border-none cusom-accordion-item"
                eventKey="0"
              >
                <Accordion.Header className="custom-accordion-header">
                  <div className="p-1 custom-div-header">
                    {`4. ${t("Logo")}`}{" "}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    {/* Colored Logo */}
                    <Col lg="6" md="6" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="fontFamily">
                          {t("Colored Logo") + ":"}
                        </Label>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            handleAcceptedFiles("colored", acceptedFiles)
                          }
                          maxFiles={1}
                          multiple={false}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="dropzone tpdd">
                              <input {...getInputProps()} />
                              <Button style={dropzoneStyle} color="#505D69">
                                <i className="ri-attachment-line"></i>
                              </Button>
                            </div>
                          )}
                        </Dropzone>

                        {coloredLogo.length > 0 &&
                          (typeof coloredLogo === "string" ? (
                            <img src={coloredLogo} style={prevImageStyle} />
                          ) : (
                            <div
                              style={{ marginTop: "10px", fontSize: "14px" }}
                            >
                              <strong className="p-5">
                                {coloredLogo[0].name}
                              </strong>
                            </div>
                          ))}
                      </FormGroup>
                    </Col>

                    {/* White Transparent Logo */}
                    <Col lg="6" md="6" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="fontFamily">
                          {t("White Transparent Logo") + ":"}
                        </Label>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            handleAcceptedFiles("white", acceptedFiles)
                          }
                          maxFiles={1}
                          multiple={false}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="dropzone tpdd">
                              <input {...getInputProps()} />
                              <Button style={dropzoneStyle} color="#505D69">
                                <i className="ri-attachment-line"></i>
                              </Button>
                            </div>
                          )}
                        </Dropzone>

                        {whiteLogo.length > 0 &&
                          (typeof whiteLogo === "string" ? (
                            <img src={whiteLogo} style={prevImageStyle} />
                          ) : (
                            <div
                              style={{ marginTop: "10px", fontSize: "14px" }}
                            >
                              <strong className="p-5">
                                {whiteLogo[0].name}
                              </strong>
                            </div>
                          ))}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    {/* Colored Logo Size */}
                    <Col lg="6" md="6" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="coloredLogoSize">
                          {t("Logo Size") + ":"}
                        </Label>
                        <Input
                          name="coloredLogoSize"
                          type="text"
                          disabled={
                            handleSubmitMutation.isLoading ||
                            fetchOrganizationDetails.isLoading ||
                            fetchOrganizationDetails.isFetching
                          }
                          value={coloredLogoSize}
                          onChange={(e) => setColoredLogoSize(e.target.value)}
                        />
                      </FormGroup>
                    </Col>

                    {/* White Logo Size */}
                    <Col lg="6" md="6" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="whiteLogoSize">
                          {t("Logo Size") + ":"}
                        </Label>
                        <Input
                          name="whiteLogoSize"
                          type="text"
                          disabled={
                            handleSubmitMutation.isLoading ||
                            fetchOrganizationDetails.isLoading ||
                            fetchOrganizationDetails.isFetching
                          }
                          value={whiteLogoSize}
                          onChange={(e) => setWhiteLogoSize(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    {/* Reduced Menu Icon */}
                    <Col lg="6" md="6" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="fontFamily">
                          {t("Reduced Menu Icon") + ":"}
                        </Label>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            handleAcceptedFiles("reduced", acceptedFiles)
                          }
                          maxFiles={1}
                          multiple={false}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="dropzone tpdd">
                              <input {...getInputProps()} />
                              <Button style={dropzoneStyle} color="#505D69">
                                <i className="ri-attachment-line"></i>
                              </Button>
                            </div>
                          )}
                        </Dropzone>

                        {reducedMenuIcon.length > 0 &&
                          (typeof reducedMenuIcon === "string" ? (
                            <img src={reducedMenuIcon} style={prevImageStyle} />
                          ) : (
                            <div
                              style={{ marginTop: "10px", fontSize: "14px" }}
                            >
                              <strong className="p-5">
                                {reducedMenuIcon[0].name}
                              </strong>
                            </div>
                          ))}
                      </FormGroup>
                    </Col>

                    {/* Reduced Menu Icon Size */}
                    <Col lg="6" md="6" sm="12" className="mt-3">
                      <FormGroup>
                        <Label for="reducedMenuIconSize">
                          {t("Icon Size") + ":"}
                        </Label>
                        <Input
                          name="reducedMenuIconSize"
                          type="text"
                          disabled={
                            handleSubmitMutation.isLoading ||
                            fetchOrganizationDetails.isLoading ||
                            fetchOrganizationDetails.isFetching
                          }
                          value={reducedMenuIconSize}
                          onChange={(e) =>
                            setReducedMenuIconSize(e.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Accordion.Body>
                <Row className="p-4">
                  <Col sm="12" className="d-flex justify-content-end">
                    <Button
                      onClick={restore}
                      color="primary"
                      type="button"
                      outline
                      className="me-2"
                      disabled={
                        handleSubmitMutation.mutating ||
                        fetchOrganizationDetails.isLoading ||
                        fetchOrganizationDetails.isFetching
                      }
                    >
                      {t("Restore")}
                    </Button>

                    <Button
                      color="primary"
                      onClick={handleSubmitMutation.mutate}
                      type="submit"
                      disabled={
                        handleSubmitMutation.mutating ||
                        fetchOrganizationDetails.isLoading ||
                        fetchOrganizationDetails.isFetching
                      }
                    >
                      {handleSubmitMutation.mutating ? <TbLoader /> : t("Save")}
                    </Button>
                  </Col>
                </Row>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </div>
    </Row>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  const organization = state.Organization;
  const App = state.App;
  const modules = state.Modules;
  return { organization, token, App, modules };
};

const mapDispachToProps = (dispach) => {
  return {
    reloadActiveModuleSettings: () =>
      dispach(reloadOrganizationActiveModuleSettingsFromServer()),
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, mapDispachToProps)(TPDDCompanyLogoCustomization)
  )
);
