import axios from 'axios';

import {
    API_BASE_URL
} from '../constants/index';

export default class SupplierService {
	static instance;

	static getInstance() {
		if (!SupplierService.instance) {
			SupplierService.instance = new SupplierService();
		}

		return SupplierService.instance;
	}

    async fetchBasicInfos(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/organization/third_party/${supplierId}/detail`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data.data
    }

    async updateGeneralInfos(payload){
        const response = await axios.post(`${API_BASE_URL}/supplier/edit_general_information`, payload, {
            params: {},
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
        });
  
        return response.data?.data;
    }

    async fetchOverviewContent(supplierId, params = {}){
        const response = await axios.get(`${API_BASE_URL}/supplier/${supplierId}/overview/header`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });
        return response.data.data;
    }

    async createReportRequest(payload){
        const response = await axios.post(`${API_BASE_URL}/supplier/report/request`, payload, {
            params: {},
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
        });
  
        return response.data?.data;
    }

    async updateStatus(payload){
        const response = await axios.put(`${API_BASE_URL}/supplier/update_status`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data?.data || null;
    }

    async updateAssignedAnalyst(payload){
        const response = await axios.put(`${API_BASE_URL}/supplier/update_analyst_admin`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
  
        return response.data?.data || null;
    }

    async toggleFlag(supplierId, payload){
        const response = await axios.put(`${API_BASE_URL}/supplier/${supplierId}/toggle_flag`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
              },
            }
        );
    
        return response.data.data;
    }

    async updateLegals(payload){
        const response = await axios.put(`${API_BASE_URL}/supplier/update_legals`, payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
              },
            }
        );
  
        return response.data?.data || null;
    }

    async validateIndustryDeletion(payload){
        const response = await axios.post(`${API_BASE_URL}/supplier/edit_general_information/check_validation`, payload, {
            params: {},
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`,
            },
        });
  
        return response.data?.data;
    }
}
