import React from "react";

import { Document, Page, Text, View, StyleSheet, Font, Image, Svg, Path } from "@react-pdf/renderer";

import { withNamespaces } from "react-i18next";

import { 
    FONT_OPEN_SANS_BOLD, 
    FONT_OPEN_SANS_REGULAR
} from "src/components/constants";

import DateUtils from "src/services/utils/DateUtils";

import { isTag, isText } from "domhandler";

import { TasksStatuses, TasksPriorities } from "src/modules/3rd-party-management/constants";

import { parseDocument } from "htmlparser2";

import { INTERNATIONAL_DATE_FORMAT } from "src/common/constants";

import { now } from "moment";

import {
    RisksRates
} from "src/modules/3rd-party-management/constants";

Font.register({
    family: "Open Sans",
    fonts: [{
            src: FONT_OPEN_SANS_REGULAR,
            fontWeight: 400,
            fontStyle: "normal",
        },
        {
            src: FONT_OPEN_SANS_REGULAR,
            fontWeight: 400,
            fontStyle: "italic",
        },
        {
            src: FONT_OPEN_SANS_BOLD,
            fontWeight: 600,
            fontStyle: "normal",
        },
        {
            src: FONT_OPEN_SANS_BOLD,
            fontWeight: 600,
            fontStyle: "italic",
        },
    ],
});

const styles = StyleSheet.create({
    firstPage: {
        backgroundColor: 'white',
        fontSize: 10,
        fontFamily: 'Open Sans'
    },
    page: {
        backgroundColor: 'white',
        fontSize: 10,
        paddingTop: 70,
        fontFamily: 'Open Sans'
    },
    ml1: {
        marginLeft: 4
    },
    fixedLogoHeader: {
        width: '100%',
        height: 70,
        backgroundColor: 'transparent',
        position: 'absolute',
        left: 0,
        top: 0
    },
    logo: {
        backgroundColor: '#d1d2d6',
        height: 40,
        left: 20,
        top: 15,
        position: "absolute"
    },
    headerLogo: {
        backgroundColor: '#d1d2d6',
        height: 40,
        marginLeft: 40,
        marginBottom: 8
    },
    note: {
        fontSize: 9,
        color: '#4472c4',
        padding: '0px 35px',
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start'
    },
    header: {
        backgroundColor: '#4472c4',
        width: '100%',
        height: 120,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-start"
    },
    headerText: {
        fontSize: 18,
        fontWeight: "bold",
        color: "white",
        marginBottom: 6,
        marginLeft: 40
    },
    generalInfosRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 12
    },
    generalInfosRowCol: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
    generalInfosTitle: {
        fontWeight: 'bold'
    },
    generalInfosValue: {
        color: '#4472c4',
        fontWeight: 'normal'
    },
    aiGeneralDescription: {
        lineHeight: 1.5
    },
    sections: {
        padding: "0px 30px",
    },
    sectionSeparator: {
        width: '100%',
        backgroundColor: '#4472c4',
        fontWeight: 600,
        fontSize: 13,
        color: 'white',
        padding: "4px 20px",
        marginBottom: 20
    },
    generalInfos: {
        marginBottom: 20
    },
    factories: {
        marginBottom: 20
    },
    products: {
        marginBottom: 20
    },
    contacts: {
        marginBottom: 20
    },
    management: {
        marginBottom: 20
    },
    shareholders: {
        marginBottom: 20
    },
    findings: {
        marginBottom: 20
    },
    risks: {
        marginBottom: 20
    },
    tasks: {
        marginBottom: 20
    },
    alphListItem: {
        color: "blue",
        marginBottom: 12
    },
    table: {
        border: '1px solid #4472c4',
        marginBottom: 12
    },
    tableHeader: {
        backgroundColor: "#4472c4",
        color: "white"
    },
    tableRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    tableCol: {
        padding: 6,
        textAlign: 'left'
    },
    tableColxs: {
        width: '40px'
    },
    tableColsm: {
        width: '60px'
    },
    tableColmd: {
        width: '80px'
    },
    tableCollg: {
        width: '100px'
    },
    tableColxl: {
        width: '140px'
    },
    findingsLanguages: {
        margin: '12px 0px'
    }
});

const Supplier = (props) => {
	const { 
        t,
        organization,
        supplierData
    } = props;
	
    const dateUtils = new DateUtils();

	if (!supplierData) {
		return (
			<Document>
				<Page size="A4" style={styles.page1}></Page>
			</Document>
		);
	}

    const HandIcon = () => (
        <Svg style={{ width: 17, height: 24 }}>
            <Path d="M14.5 8C14.33 8 14.16 8.02 14 8.05V4.5C14 3.12 12.88 2 11.5 2C11.31 2 11.13 2.02 10.96 2.06C10.75 0.89 9.73 0 8.5 0C7.44 0 6.54 0.66 6.17 1.59C5.96 1.53 5.73 1.5 5.5 1.5C4.12 1.5 3 2.62 3 4V4.55C2.84 4.52 2.67 4.5 2.5 4.5C1.12 4.5 0 5.62 0 7V15.5C0 20.19 3.81 24 8.5 24C13.19 24 17 20.19 17 15.5V10.5C17 9.12 15.88 8 14.5 8ZM15 15.5C15 19.09 12.09 22 8.5 22C4.91 22 2 19.09 2 15.5V7C2 6.72 2.22 6.5 2.5 6.5C2.78 6.5 3 6.72 3 7V12H5V4C5 3.72 5.22 3.5 5.5 3.5C5.78 3.5 6 3.72 6 4V11H8V2.5C8 2.22 8.22 2 8.5 2C8.78 2 9 2.22 9 2.5V11H11V4.5C11 4.22 11.22 4 11.5 4C11.78 4 12 4.22 12 4.5V13.42C10.23 14.19 9 15.95 9 18H11C11 16.34 12.34 15 14 15V10.5C14 10.22 14.22 10 14.5 10C14.78 10 15 10.22 15 10.5V15.5Z" fill="#4472c4" />
        </Svg>
    )

    const getTagClassName = (className) => {
		switch (className) {
			case "ql-indent-1":
				return { paddingLeft: 15 };
			case "ql-indent-2":
				return { paddingLeft: 30 };
			case "ql-indent-3":
				return { paddingLeft: 45 };
			case "ql-indent-4":
				return { paddingLeft: 60 };
			case "ql-indent-5":
				return { paddingLeft: 75 };
			case "ql-indent-6":
				return { paddingLeft: 90 };
			case "ql-indent-7":
				return { paddingLeft: 105 };
			case "ql-indent-8":
				return { paddingLeft: 120 };
			case "ql-indent-9":
				return { paddingLeft: 135 };
			case "ql-indent-10":
				return { paddingLeft: 150 };
			default:
				return {};
		}
	};

    const renderNodeData = (node, keys) => {
		const data = node.data;

		return <Text key={keys.toString()}>{data}</Text>;
	};

    const renderListTag = (nodes, keys, isOrderedNumber) => {
		const renderListPrefix = (index) => {
			if (isOrderedNumber) {
				return <Text>{index + 1 + ". "}</Text>;
			}

			return <Text style={styles.dotPrefix}>{"· "}</Text>;
		};

		return nodes.map((node, index) => {
			if (isTag(node)) {
				keys.push(index);
				const children = node.children;
				const className = getTagClassName(node.attribs.class);

				return (
					<View style={[styles.listItem, className]} key={keys.toString()}>
						{renderListPrefix(index)}

						<View>{renderNodes(children, keys)}</View>
					</View>
				);
			}

			return null;
		});
	};

    const renderTag = (tag, keys) => {
		const name = tag.name;
		const children = tag.children;
		const className = getTagClassName(tag.attribs.className);

		switch (name) {
			case "br":
				return (
					<View key={keys.toString()} style={className}>
						{renderNodes(children, keys)}
					</View>
				);
			case "p":
				if (Array.isArray(children) && children.length > 0) {
					const firstNode = children[0];

					if (isText(firstNode)) {
						return (
							<View key={keys.toString()} style={className}>
								<Text>{renderNodes(children, keys)}</Text>
							</View>
						);
					}
				}

				return (
					<View key={keys.toString()} style={className}>
						{renderNodes(children, keys)}
					</View>
				);
			case "b":
			case "strong":
				return (
					<Text key={keys.toString()} style={{ fontWeight: 600 }}>
						{renderNodes(children, keys)}
					</Text>
				);

			case "em":
				return (
					<Text key={keys.toString()} style={{ fontStyle: "italic" }}>
						{renderNodes(children, keys)}
					</Text>
				);
			case "u":
				return (
					<Text key={keys.toString()} style={{ textDecoration: "underline" }}>
						{renderNodes(children, keys)}
					</Text>
				);
			case "ul":
				return (
					<View style={[styles.list, className]} key={keys.toString()}>
						{renderListTag(children, keys, false)}
					</View>
				);
			case "ol":
				return (
					<View style={[styles.list, className]} key={keys.toString()}>
						{renderListTag(children, keys, true)}
					</View>
				);
			default:
				return null;
		}
	};

    const renderNodes = (nodes, keys) => {
		if (!nodes || nodes.length === 0) {
			return null;
		}

		const newKeys = keys ? keys : [];

		return (
			<View>
				{nodes.map((node, index) => {
					newKeys.push(index);

					if (isTag(node)) {
						return renderTag(node, newKeys);
					}

					if (isText(node)) {
						return renderNodeData(node, newKeys);
					}

					return null;
				})}
			</View>
		);
	};

	const renderHTMLContent = (content) => {
		const domContent = parseDocument(content);
		const children = domContent.children;

		return renderNodes(children);
	};

    const renderGeneralInfosSection = () => {
        const {
            supplierDetail
        } = supplierData;
        return (
            <>
                <View style={styles.sections}>
                    <View style={styles.generalInfos}>
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Name')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.name}</Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('SIP ID')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.id}</Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Address')}:</Text>
                                <Text style={styles.generalInfosValue}>
                                    {t(supplierDetail?.country)}, {supplierDetail?.city}
                                </Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Phone')}:</Text>
                                <Text style={styles.generalInfosValue}></Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Website')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.websiteUrl}</Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Industry')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.industry?.name}</Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Employee Size')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.employeeSize}</Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Vat ID')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.vatNumber}</Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Overall Risk Rating')}:</Text>
                                <Text style={styles.generalInfosValue}>
                                    {t(RisksRates[supplierDetail?.highestRisk?.toLowerCase()]?.title)}
                                </Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Supplier Score')}:</Text>
                                <Text style={styles.generalInfosValue}>{supplierDetail?.score}/10</Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Last Update')}:</Text>
                                <Text style={styles.generalInfosValue}>
                                    {supplierDetail?.lastUpdate && dateUtils.convertDateToDate(supplierDetail?.lastUpdate, INTERNATIONAL_DATE_FORMAT)}
                                </Text>
                            </View>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Report Printed')}:</Text>
                                <Text style={styles.generalInfosValue}>
                                    {dateUtils.convertDateToDate(now(), INTERNATIONAL_DATE_FORMAT)}
                                </Text>
                            </View>
                        </View>
                
                        <View style={styles.generalInfosRow}>
                            <View style={styles.generalInfosRowCol}>
                                <Text style={styles.generalInfosTitle}>{t('Assigned Analyst')}:</Text>
                                <Text style={styles.generalInfosValue}>
                                    {`${supplierDetail?.assignedAnalyst?.firsName} ${supplierDetail?.assignedAnalyst?.lastName}`}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            
                <View style={styles.sectionSeparator}>
                    <Text>
                        {t('GENERAL INFORMATION')}
                    </Text>
                </View>
            
                <View style={styles.note}>
                    <HandIcon />
                    <Text style={styles.ml1}>
                        {t('This summary is created by an AI and may be incomplete.')}
                    </Text>
                </View>
            
                <View style={styles.sections}>
                    <View style={styles.aiGeneralDescription}>
                        {renderHTMLContent(supplierDetail.description)}
                    </View>
                </View>
            </>
        )
    }

    const renderLocations = () => {
        const {
            supplierDetail
        } = supplierData;
        return (
            <View style={styles.factories}>
                <Text style={styles.alphListItem}>
                    {`${t('a')}) ${t('Factory Sites / Branches')}`}
                </Text>
        
                <View style={styles.table}>
                    <View style={styles.tableHeader}>
                        <View style={styles.tableRow}>
                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColmd,
                                }}
                            >
                                <Text>{t('Type')}</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColmd,
                                }}
                            >
                                <Text>{t('Location')}</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColxl,
                                }}
                            >
                                <Text>{t('Address')}</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableCollg,
                                }}
                            >
                                <Text>{t('Added On')}</Text>
                            </View>
                        </View>
                    </View>
        
                    <View style={styles.tableBody}>
                        {(supplierDetail?.locations || []).map((location, index) => {
                            return (
                                <View key={index} style={styles.tableRow}>
                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColmd,
                                        }}
                                    >
                                        <Text>{location.type === 'manufacture' ? t('Factory') : t('Office')}</Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColmd,
                                        }}
                                    >
                                        <Text>{location.city}, {t(location.countryName)}</Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColxl,
                                        }}
                                    >
                                        <Text>{location.address}</Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableCollg,
                                        }}
                                    >
                                        <Text>
                                            {location.createdAt && dateUtils.convertDateToDate(location.createdAt, INTERNATIONAL_DATE_FORMAT)}
                                        </Text>
                                    </View>
                                </View>     
                            )
                        })}
                    </View>
                </View>
            </View>
        )
    }

    const renderProducts = () => {
        const {
            products
        } = supplierData;

        return (
            <View style={styles.products}>
                <Text style={styles.alphListItem}>
                    {`${t('b')}) ${t('List Of Products')}`}
                </Text>
        
                <View style={styles.table}>
                    <View style={styles.tableHeader}>
                        <View style={styles.tableRow}>
                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColxs,
                                }}
                            >
                                <Text>#</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableCollg,
                                }}
                            >
                                <Text>{t('Name')}</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColxl,
                                }}
                            >
                                <Text>{t('Description')}</Text>
                            </View>
                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColmd,
                                }}
                            >
                                <Text>{t('Nace Code')}</Text>
                            </View>
                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColmd,
                                }}
                            >
                                <Text>{t('ERP ID')}</Text>
                            </View>
                        </View>
                    </View>
        
                    <View style={styles.tableBody}>
                        {(products || []).map((product, index) => {
                            return (
                                <View key={index} style={styles.tableRow}>
                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColxs,
                                        }}
                                    >
                                        <Text>{index + 1}</Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableCollg,
                                        }}
                                    >
                                        <Text>
                                            {product.name?.name}
                                        </Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColxl,
                                        }}
                                    >
                                        <Text>{product?.description}</Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColmd,
                                        }}
                                    >
                                        <Text>{product?.name?.naceCode}</Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColmd,
                                    }}
                                    >
                                        <Text></Text>
                                    </View>
                                </View>
                            )
                        })}
                    </View>
                </View>
            </View>
        )
    }

    const renderContactPeople = () => {
        const {
            contactPersons
        } = supplierData;

        return (
            <View style={styles.contacts}>
                <Text style={styles.alphListItem}>
                    {`${t('c')}) ${t('Contact Person')}`}
                </Text>
        
                <View style={styles.table}>
                    <View style={styles.tableHeader}>
                        <View style={styles.tableRow}>
                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableCollg,
                                }}
                            >
                                <Text>{t('Name')}</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableCollg,
                                }}
                            >
                                <Text>{t('Position')}</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColxl,
                                }}
                            >
                                <Text>{t('Email')}</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableCollg,
                                }}
                            >
                                <Text>{t('Phone')}</Text>
                            </View>
                        </View>
                    </View>
        
                    <View style={styles.tableBody}>
                        {(contactPersons || []).map((person, index) => {
                            return (
                                <View key={index} style={styles.tableRow}>
                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableCollg,
                                        }}
                                    >
                                        <Text>{`${t(person.title)} ${person.name} ${person.lastName}`}</Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableCollg,
                                        }}
                                    >
                                        <Text>{person.position}</Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColxl,
                                        }}
                                    >
                                        <Text>{person.email}</Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableCollg,
                                        }}
                                    >
                                        <Text>{person.phoneNumber}</Text>
                                    </View>
                                </View>
                            )
                        })}
                    </View>
                </View>
            </View>
        )
    }

    const renderManagementTeam = () => {
        const {
            teamManagement
        } = supplierData;

        return (
            <View style={styles.management}>
                <Text style={styles.alphListItem}>
                    {`${t('d')}) ${t('Management')}`}
                </Text>
        
                <View style={styles.table}>
                    <View style={styles.tableHeader}>
                        <View style={styles.tableRow}>
                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableCollg,
                                }}
                            >
                                <Text>{t('Name')}</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableCollg,
                                }}
                            >
                                <Text>{t('Position')}</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColxl,
                                }}
                            >
                                <Text>{t('Email')}</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableCollg,
                                }}
                            >
                                <Text>{t('Phone')}</Text>
                            </View>
                        </View>
                    </View>
        
                    <View style={styles.tableBody}>
                        {(teamManagement || []).map((person, index) => {
                            return (
                                <View key={index} style={styles.tableRow}>
                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableCollg,
                                        }}
                                    >
                                        <Text>{person.name}</Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableCollg,
                                    }}
                                    >
                                        <Text>{person.position}</Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColxl,
                                        }}
                                    >
                                        <Text>
                                            {person.email}
                                        </Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableCollg,
                                        }}
                                    >
                                        <Text>{person.phoneNumber}</Text>
                                    </View>
                                </View>
                            )
                        })}
                    </View>
                </View>
            </View>
        )
    }

    const renderShareholders = () => {
        const {
            shareHolders
        } = supplierData;

        return (
            <View style={styles.shareholders}>
                <Text style={styles.alphListItem}>
                    {`${t('e')}) ${t('Shareholders')}`}
                </Text>
        
                <View style={styles.table}>
                    <View style={styles.tableHeader}>
                        <View style={styles.tableRow}>
                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColxl,
                                }}
                            >
                                <Text>{t('Name')}</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColmd,
                                }}
                            >
                                <Text>{t('Share in')} %</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColmd,
                                }}
                            >
                                <Text>{t('Date of birth')}</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColmd,
                                }}
                            >
                                <Text>{t('Country of Residence')}</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColmd,
                                }}
                            >
                                <Text>{t('UBO')}(Y/N)</Text>
                            </View>
                        </View>
                    </View>
        
                    <View style={styles.tableBody}>
                        {(shareHolders?.individual || []).map((shareholder, index) => {
                            return (
                                <View key={index} style={styles.tableRow}>
                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColxl,
                                        }}
                                    >
                                        <Text>
                                            {`${shareholder.title} ${shareholder.firstName} ${shareholder.lastName}`}
                                        </Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColmd,
                                        }}
                                    >
                                        <Text>{shareholder.percentage}</Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColmd,
                                        }}
                                    >
                                        <Text>
                                            {dateUtils.convertDateToDate(shareholder.birthDay, INTERNATIONAL_DATE_FORMAT)}
                                        </Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColmd,
                                        }}
                                    >
                                        <Text>
                                            {shareholder.residenceCountry.name}
                                        </Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColmd,
                                        }}
                                    >
                                        <Text>{shareholder.isUbo ? 'Y' : 'N'}</Text>
                                    </View>
                                </View>
                            )
                        })}
                    </View>
                </View>
        
                <View style={styles.table}>
                    <View style={styles.tableHeader}>
                        <View style={styles.tableRow}>
                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColxl,
                                }}
                            >
                                <Text>{t('Company Name')}</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColmd,
                                }}
                            >
                                <Text>{t('Share in')} %</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColmd,
                                }}
                            >
                                <Text>{t('Founded On')}</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColmd,
                                }}
                            >
                                <Text>{t('Country')}</Text>
                            </View>

                            <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableColmd,
                                }}
                            >
                                <Text>{t('Ultimate Parent')} (Y/N)</Text>
                            </View>
                        </View>
                    </View>
        
                    <View style={styles.tableBody}>
                        {(shareHolders.company || []).map((shareholder, index) => {
                            return (
                                <View key={index} style={styles.tableRow}>
                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColxl,
                                        }}
                                    >
                                        <Text>{shareholder.companyName}</Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColmd,
                                        }}
                                    >
                                        <Text>{shareholder.percentage}</Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColmd,
                                        }}
                                    >
                                        <Text>
                                            {dateUtils.convertDateToDate(shareholder.foundingDate, INTERNATIONAL_DATE_FORMAT)}
                                        </Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColmd,
                                        }}
                                    >
                                        <Text>{shareholder.country.name}</Text>
                                    </View>

                                    <View
                                        style={{
                                            ...styles.tableCol,
                                            ...styles.tableColmd,
                                        }}
                                    >
                                        <Text>{shareholder.isUbo ? 'Y' : 'N'}</Text>
                                    </View>
                                </View>
                            )
                        })}
                    </View>
                </View>
            </View>
        )
    }

    const renderFindingsSection = () => {
        const {
            news
        } = supplierData;

        return (
            <View style={styles.sections}>
                <Text style={styles.findingsLanguages}>
                    {t('Language')}: English, Italian
                </Text>
                
                <View style={styles.findings}>
                    <View style={styles.table}>
                        <View style={styles.tableHeader}>
                            <View style={styles.tableRow}>
                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableColmd,
                                    }}
                                >
                                    <Text>{t('Source')}</Text>
                                </View>

                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableColxl,
                                    }}
                                >
                                    <Text>{t('Description')}</Text>
                                </View>

                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableColmd,
                                    }}
                                >
                                    <Text>{t('Source Scoring')}</Text>
                                </View>

                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableColmd,
                                    }}
                                >
                                    <Text>{t('Relevant')} (Y/N/Unknown)</Text>
                                </View>
                            </View>
                        </View>
            
                        <View style={styles.tableBody}>
                            {(news || []).map((finding, index) => {
                                return (
                                    <View key={index} style={styles.tableRow}>
                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableColmd,
                                            }}
                                        >
                                            <Text>{finding.news_agency}</Text>
                                        </View>

                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableColxl,
                                            }}
                                        >
                                            {renderHTMLContent(finding.body)}
                                        </View>

                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableColmd,
                                            }}
                                        >
                                            <Text></Text>
                                        </View>

                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableColmd,
                                            }}
                                        >
                                            <Text>{finding.relevancyScore || 0}</Text>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    </View>
                </View>
            </View>
        )
    }

    const renderRisksSection = () => {
        const { 
            risks
        } = supplierData;
        
        return (
            <View style={styles.sections}>
                <View style={styles.risks}>
                    <View style={styles.table}>
                        <View style={styles.tableHeader}>
                            <View style={styles.tableRow}>
                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableColsm,
                                    }}
                                >
                                    <Text>{t('Rating')}</Text>
                                </View>

                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableColsm,
                                    }}
                                >
                                    <Text>#</Text>
                                </View>

                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableCollg,
                                    }}
                                >
                                    <Text>{t('Category')}</Text>
                                </View>

                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableCollg,
                                    }}
                                >
                                    <Text>{t('Subcategory')}</Text>
                                </View>

                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableCollg,
                                    }}
                                >
                                    <Text>{t('Risk Manager')}</Text>
                                </View>

                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableCollg,
                                    }}
                                >
                                    <Text>{t('Risk Owner')}</Text>
                                </View>

                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableCollg,
                                    }}
                                >
                                    <Text>{t('Mitigation Tasks')}</Text>
                                </View>

                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableColmd,
                                    }}
                                >
                                    <Text>{t('Created On')}</Text>
                                </View>
                            </View>
                        </View>
            
                        <View style={styles.tableBody}>
                            {(risks || []).map((risk, index) => {
                                return (
                                    <View key={index} style={styles.tableRow}>
                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableColsm,
                                            }}
                                        >
                                            <Text></Text>
                                        </View>

                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableColsm,
                                            }}
                                        >
                                            <Text>R{risk.secondary_id}</Text>
                                        </View>

                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableCollg,
                                            }}
                                        >
                                            <Text>{risk.category.name}</Text>
                                        </View>

                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableCollg,
                                            }}
                                        >
                                            <Text>{risk.category?.parent?.name}</Text>
                                        </View>

                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableCollg,
                                            }}
                                        >
                                            <Text>{risk.manager}</Text>
                                        </View>

                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableCollg,
                                            }}
                                        >
                                            <Text>{risk.owner_name}</Text>
                                        </View>

                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableCollg,
                                            }}
                                        >
                                            <Text>
                                                {risk.tasks.map((t) => `T${t.secondary_id}`).join(', ')}
                                            </Text>
                                        </View>

                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableColmd,
                                            }}
                                        >
                                            <Text>
                                                {dateUtils.convertTimeStampToDate(risk.created_at, INTERNATIONAL_DATE_FORMAT)}
                                            </Text>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    </View>
                </View>
            </View>
        )
    }

    const renderTasksSection = () => {
        const {
            tasks
        } = supplierData;

        return (
            <View style={styles.sections}>
                <View style={styles.tasks}>
                    <View style={styles.table}>
                        <View style={styles.tableHeader}>
                            <View style={styles.tableRow}>
                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableColsm,
                                    }}
                                >
                                    <Text>#</Text>
                                </View>

                                <View
                                style={{
                                    ...styles.tableCol,
                                    ...styles.tableCollg,
                                }}
                                >
                                    <Text>{t('Title')}</Text>
                                </View>

                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableColmd,
                                    }}
                                >
                                    <Text>{t('Created On')}</Text>
                                </View>

                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableColmd,
                                    }}
                                >
                                    <Text>{t('Deadline')}</Text>
                                </View>

                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableCollg,
                                    }}
                                >
                                    <Text>{t('Task Manager')}</Text>
                                </View>

                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableCollg,
                                    }}
                                >
                                    <Text>{t('Task Owner')}</Text>
                                </View>

                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableColmd,
                                    }}
                                >
                                    <Text>{t('Priority')}</Text>
                                </View>

                                <View
                                    style={{
                                        ...styles.tableCol,
                                        ...styles.tableColmd,
                                    }}
                                >
                                    <Text>{t('Status')}</Text>
                                </View>
                            </View>
                        </View>
            
                        <View style={styles.tableBody}>
                            {(tasks || []).map((task, index) => {
                                return (
                                    <View key={index} style={styles.tableRow}>
                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableColsm,
                                            }}
                                        >
                                            <Text>T{task.secondary_id}</Text>
                                        </View>

                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableCollg,
                                            }}
                                        >
                                            <Text>{task.name}</Text>
                                        </View>

                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableColmd,
                                            }}
                                        >
                                            <Text>
                                                {dateUtils.convertTimeStampToDate(task.created_at, INTERNATIONAL_DATE_FORMAT)}
                                            </Text>
                                        </View>

                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableColmd,
                                            }}
                                        >
                                            <Text>
                                                {dateUtils.convertTimeStampToDate(task.ended_at, INTERNATIONAL_DATE_FORMAT)}
                                            </Text>
                                        </View>

                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableCollg,
                                            }}
                                        >
                                            <Text>{task.manager}</Text>
                                        </View>

                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableCollg,
                                            }}
                                        >
                                            <Text>{task.analyst?.full_name}</Text>
                                        </View>

                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableColmd,
                                            }}
                                        >
                                            <Text>{t(TasksPriorities[task.priority]?.title)}</Text>
                                        </View>

                                        <View
                                            style={{
                                                ...styles.tableCol,
                                                ...styles.tableColmd,
                                            }}
                                        >
                                            <Text>{t(TasksStatuses[task.status]?.title)}</Text>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    </View>
                </View>
            </View>
        )
    }

    return (
        <Document>
            <Page size="A4" style={styles.firstPage}>
                <View style={styles.header}>
                    <Image
                        style={styles.headerLogo}
                        src="https://new-customer-portal.dev.smartintegrityplatform.com/resources/assets/1/a6dd310c66dd4d96f5b18e14c18c603a.png"
                    />
                    <Text style={styles.headerText}>
                        {t('THIRD PARTY DUE DILIGENCE REPORT')}
                    </Text>
                </View>

                {renderGeneralInfosSection()}
            </Page>
        
            <Page size="A4" style={styles.page}>
                <View style={styles.fixedLogoHeader} fixed>
                    <Image
                    cache={false}
                    style={styles.logo}
                    src={organization.logo}
                    />
                </View>
            
                <View style={styles.sections}>
                    { renderLocations() }
            
                    { renderProducts() }

                    { renderContactPeople() }
            
                    { renderManagementTeam() }
            
                    { renderShareholders() }
                </View>
            
                <View style={styles.sectionSeparator}>
                    <Text>{t('FINDINGS')}</Text>
                </View>
            
                <View style={styles.note}>
                    <HandIcon />
                    <Text style={styles.ml1}>
                        {t('Please note that Open Source Findings are displayed here.')}
                    </Text>
                </View>
            
                { renderFindingsSection() }
            
                <View style={styles.sectionSeparator}>
                    <Text>{t('RISKS')}</Text>
                </View>
            
                { renderRisksSection() }
            
                <View style={styles.sectionSeparator}>
                    <Text>{t('TASKS')}</Text>
                </View>
            
                { renderTasksSection() }
            </Page>
        </Document>
    );
      
};


export default withNamespaces()(
    Supplier
);
