import React, { useEffect, useMemo, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import { 
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Button
} from "reactstrap";

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {Nav, NavItem, NavLink } from 'reactstrap';

import {
    icons
} from './constants';

import {
    HistoryLogSections,
    HistoryLogActions
} from "../../../../../../constants/Common";

import { useQuery } from "@tanstack/react-query";

import Spinner from 'react-bootstrap/Spinner';

import { toast } from "react-toastify";

import SupplierHistoryLogService from "src/modules/3rd-party-management/apis/SupplierHistoryLogService";

import { DateRangePicker, defaultInputRanges, defaultStaticRanges } from 'react-date-range';

import * as locales from 'react-date-range/dist/locale';

const TableFilter = ({
    t,
    supplierId,
    onFilterChanged,
    onOkButtonClicked,
    isSearchAvailable,
    defaultValues,
    ...restProps
}) => {
    const [ filters, setFilters ] = useState({
        fromDate    :   defaultValues.fromDate || null,
        toDate      :   defaultValues.toDate || null,
        user        :   defaultValues.user || null,
        sections    :   defaultValues.sections || [],
        actions       :   defaultValues.actions || []
    });

    const [ dropdownOpen, setDropdownOpen ] = useState(false);
    const [ activeTab, setActiveTab ] = useState('date');
    const dateInputRef = useRef();

    const datePickerTranlations = useMemo(() => {
        function translateRange(dictionary) {
            return (item) => dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item;
        }

        return {
            "staticRanges"  :   defaultStaticRanges.map(translateRange({
                "Today"         :   t("Today"),
                "Yesterday"     :   t("Yesterday"),
                "This Week"     :   t("This Week"),
                "Last Week"     :   t("Last Week"),
                "This Month"    :   t("This Month"),
                "Last Month"    :   t("Last Month")
            })) ,
            "inputRanges"   :   defaultInputRanges.map(translateRange({
                "days up to today"      :   t("days up to today"),
                "days starting today"   :   t("days starting today")
            }))
        }
    }, [t]);

    const handleFetchSupplierUsersQuery = useQuery({
		queryKey: ['3rd-party-management-supplier-users-list'],
		queryFn: async () => {
			const service = SupplierHistoryLogService.getInstance();

            return await service.fetchUsers(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
        enabled: false,
		onError: (error) => {
			toast(t('An error occurred while fetching supplier users list.'), {
				type: 'error',
			});
		},
	});

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const renderSectionsFilterList = () => {
      const result = [];

      for (const sectionKey in HistoryLogSections) {
        result.push(
          <div className="filter-item" key={sectionKey}>
            <Form.Check
              checked={
                filters.sections.find((s) => s === sectionKey) ? true : false
              }
              onChange={({ target }) => {
                if (target.checked) {
                  // Add the selected section to the filters
                  setFilters((filters) => {
                    const result = {
                      ...filters,
                      sections: [...filters.sections, sectionKey],
                    };

                    onFilterChanged && onFilterChanged("sections", result);

                    return result;
                  });
                } else {
                  // Remove the selected section from the filters and remove any corresponding actions
                  setFilters((filters) => {
                    const updatedSections = filters.sections.filter(
                      (id) => id !== sectionKey
                    );
                    const updatedActions = filters.actions.filter(
                      (action) => !(action in HistoryLogActions[sectionKey])
                    );

                    const result = {
                      ...filters,
                      sections: updatedSections,
                      actions: updatedActions,
                    };

                    onFilterChanged && onFilterChanged("sections", result);
                    onFilterChanged && onFilterChanged("actions", result);

                    return result;
                  });
                }
              }}
              type="checkbox"
              id={`activity-${sectionKey}-filter`}
              label={t(HistoryLogSections[sectionKey])}
            />
          </div>
        );
      }

      return result;
    };

    const renderActivitiesFilterList = () => {
      const result = [];
      const selectedSections = filters.sections;

      // If no section is selected or section array is empty, show all case objects
      if (!selectedSections || selectedSections.length === 0) {
        for (const actionKey in HistoryLogActions.case) {
          result.push(
            <div className="filter-item" key={actionKey}>
              <Form.Check
                checked={
                  filters.actions.find((a) => a === actionKey) ? true : false
                }
                onChange={({ target }) => {
                  if (target.checked) {
                    setFilters((filters) => {
                      const result = {
                        ...filters,
                        actions: [...filters.actions, actionKey],
                      };

                      onFilterChanged && onFilterChanged("actions", result);

                      return result;
                    });
                  } else {
                    setFilters((filters) => {
                      const result = {
                        ...filters,
                        actions: [
                          ...filters.actions.filter((id) => id !== actionKey),
                        ],
                      };

                      onFilterChanged && onFilterChanged("action", result);

                      return result;
                    });
                  }
                }}
                type="checkbox"
                id={`activity-${actionKey}-filter`}
                label={t(HistoryLogActions.case[actionKey])}
              />
            </div>
          );
        }
      } else {
        // Show items based on the selected sections
        selectedSections.forEach((section) => {
          for (const actionKey in HistoryLogActions[section]) {
            result.push(
              <div className="filter-item" key={actionKey}>
                <Form.Check
                  checked={
                    filters.actions.find((a) => a === actionKey) ? true : false
                  }
                  onChange={({ target }) => {
                    if (target.checked) {
                      setFilters((filters) => {
                        const result = {
                          ...filters,
                          actions: [...filters.actions, actionKey],
                        };

                        onFilterChanged && onFilterChanged("actions", result);

                        return result;
                      });
                    } else {
                      setFilters((filters) => {
                        const result = {
                          ...filters,
                          actions: [
                            ...filters.actions.filter((id) => id !== actionKey),
                          ],
                        };

                        onFilterChanged && onFilterChanged("actions", result);

                        return result;
                      });
                    }
                  }}
                  type="checkbox"
                  id={`activity-${actionKey}-filter`}
                  label={t(HistoryLogActions[section][actionKey])}
                />
              </div>
            );
          }
        });
      }

      return result;
    };
    
    const renderUsersFilterList = () => {
        if (handleFetchSupplierUsersQuery.isFetching || handleFetchSupplierUsersQuery.isLoading) {
          return (
            <div className="text-center">
              <Spinner animation="border" variant="primary" size="md" />
            </div>
          );
        }
      
        return (handleFetchSupplierUsersQuery.data || []).map((user) => {
          const isChecked = filters.user === user.id; // Check if current user is selected
          return (
            <div className="filter-item" key={user.id}>
              <Form.Check
                checked={isChecked}
                onChange={() => {
                  if (!isChecked) {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      user: user.id,
                    }));
                    onFilterChanged && onFilterChanged('user', user.id);
                  }
                }}
                type="radio"
                id={`user-${user.id}-filter`}
                label={`${user.first_name} ${user.last_name}`}
              />
            </div>
          );
        });
    };
      
    const renderDateFilter = () => {
        return (
          <DateRangePicker
            onChange={(range) => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                fromDate: range.selection.startDate,
                toDate: range.selection.endDate,
              }));
            }}
            locale={ locales[restProps?.lng] || locales["enUS"]}
            ranges={[
                {
                    startDate   :   filters.fromDate,
                    endDate     :   filters.toDate,
                    key         :   "selection",
                }
            ]}
            rangeColors={["#5664d2"]}
            {...datePickerTranlations}
          />
        );
    }

    const renderNavContent = () => {
        switch(activeTab){
            case 'date':
                return renderDateFilter();

            case 'user':
                return renderUsersFilterList();

            case 'sections':
                return renderSectionsFilterList();

            case 'actions':
                return renderActivitiesFilterList();
            
            default:
                return null;
        }
    }

    useEffect(() => {
        if(activeTab === 'date' && dateInputRef?.current){
            console.log(dateInputRef)
            dateInputRef.current.focus();
        }
    }, [ activeTab ]);

    return (
      <div className="custom-table-filter">
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle caret color="secondary" outline>
            <i className="ri-filter-3-fill"></i>
            <span>{t("Filter")}</span>
          </DropdownToggle>

          <DropdownMenu>
            <div className="filters-container">
              {isSearchAvailable &&
                <div className="search-input">
                  <InputGroup>
                    <Form.Control placeholder="Search" aria-describedby="search-result-input" style={{borderRight: 'none'}} />
                    <InputGroup.Text className="bg-transparent border-left-0" id="search-result-input" style={{borderLeft: 'none'}}>
                      <i className="ri-search-line"></i>
                    </InputGroup.Text>
                  </InputGroup>
                </div>}

              <div className="navs-container d-flex justify-content-center">
                <Nav tabs>
                  <NavItem onClick={() => setActiveTab('date')} className={`${activeTab === 'date' && 'active'}`}>
                    <NavLink>
                      <img src={activeTab === 'date' ? icons.date.active : icons.date.default} alt="" />
                      <span>{t('Date')}</span>
                    </NavLink>
                  </NavItem>

                  <NavItem onClick={() => {
                      setActiveTab('user');
                      handleFetchSupplierUsersQuery.refetch();
                    }} className={`${activeTab === 'user' && 'active'}`}>
                    <NavLink>
                      <img src={activeTab === 'user' ? icons.user.active : icons.user.default} alt="" />
                      <span>{t('User')}</span>
                    </NavLink>
                  </NavItem>

                  
                                <NavItem onClick={() => setActiveTab('sections')} className={`${activeTab === 'sections' && 'active'}`}>
                    <NavLink>
                      <img src={activeTab === 'sections' ? icons.section.active : icons.section.default} alt="" />
                      <span>{t('Sections')}</span>
                    </NavLink>
                  </NavItem>

                  
                                <NavItem onClick={() => setActiveTab('actions')} className={`${activeTab === 'actions' && 'active'}`}>
                    <NavLink>
                      <img src={activeTab === 'actions' ? icons.actions.active : icons.actions.default} alt="" />
                      <span>{t('Actions')}</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <div className="navs-content-container">
                            { renderNavContent() }
                        </div>

              <div className="actions-container gap-3 d-flex align-items-center justify-content-end">
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => {
                    onOkButtonClicked && onOkButtonClicked(filters);
                    toggle();
                  }}
                >
                  {t("ok")}
                </Button>
                <Button
                  color="secondry"
                  size="sm"
                  onClick={() => {
                    setFilters({
                      fromDate: null,
                      toDate: null,
                      user: null,
                      sections: [],
                      actions: [],
                    });
                  }}
                >
                  {t("Reset")}
                </Button>
              </div>
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
};


export default withNamespaces()(TableFilter); 