import { withNamespaces } from "react-i18next";

import DateUtils from "src/services/utils/DateUtils";

import { useQuery } from "@tanstack/react-query";

import { toast } from "react-toastify";

import DataProtectionSoftwareService from "src/modules/data-protection/apis/DataProtectionSoftwareService";

import React, { useEffect, useState } from "react";


import FirstRow from "./FirstRow";
import SecondRow from "./SecondRow";
import CommonService from "src/modules/data-protection/apis/CommonService";

const InfoCards = ({
  t,
  assetId,
  handleFetchSoftwareDetailsQuery,
  // Analysts
  setSelectedAssignedAnalyst,
  selectedAssignedAnalyst,
  // Statuses
  selectedStatus,
  setSelectedStatus,
  statuses,
  // Priorities
  priorities,
  selectedPriority,
  setSelectedPriority,
  // Providers,
  selectedProvider,
  setSelectedProvider,
  // Types
  setSelectedAssetType,
  selectedAssetType,
  // OS
  selectedOperatingSystem,
  setSelectedOperatingSystem,
  // Version
  version,
  // Service
  service,
  // Modules
  selectedModules,
  // Attachments
  attachments,
  // Company
  selectedCompany,
  // Position
  selectedPosition,
  // Phone Number
  selectedPhoneNumber,
  // Email
  selectedEmail,
  // Department
  selectedDepartment,
  // Responsible Person
  selectedResponsiblePerson,
  // Entities
  changeSelectedEntity,
  selectedLegalEntities,
  legalEntitiesOptions,
  fetchLegalEntitiesOptions,
}) => {
  const [asset, setAsset] = useState(null);
  const [users, setUsers] = useState([]);

  const handleFetchAssetBasicInformation = useQuery({
    queryKey: ["data-protection-supplier-overview-contnet"],
    queryFn: async () => {
      const service = DataProtectionSoftwareService.getInstance();
      return await service.details(assetId);
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while fetching overview content."), {
        type: "error",
      });
    },
  });

  const handleFetchUsersListQuery = useQuery({
    queryFn: async () => {
      const service = CommonService.getInstance();

      return await service.analystAdminsAndAnalysts();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(t("An error occurred while fetching users."), {
        type: "error",
      });
    },
  });

 
  useEffect(() => {
    if (handleFetchAssetBasicInformation.data) {
      setAsset(handleFetchAssetBasicInformation.data);
    }
  }, [handleFetchAssetBasicInformation.data]);

  useEffect(() => {
    if (
      handleFetchUsersListQuery.data &&
      handleFetchUsersListQuery.data?.length
    ) {
      const users = handleFetchUsersListQuery.data.map((u) => {
        return {
          value: u.id,
          label: u.first_name + " " + u.last_name,
        };
      });
      setUsers(users);
    }
  }, [handleFetchUsersListQuery.data]);

  //   console.log("Asset", asset);
  return (
    <React.Fragment>
      <FirstRow
        // General
        handleFetchSoftwareDetailsQuery={handleFetchSoftwareDetailsQuery}
        assetId={assetId}
        // Analysts
        setSelectedAssignedAnalyst={setSelectedAssignedAnalyst}
        selectedAssignedAnalyst={selectedAssignedAnalyst}
        users={users}
        // Statuses
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        statuses={statuses}
        // Priorities
        selectedPriority={selectedPriority}
        setSelectedPriority={setSelectedPriority}
        priorities={priorities}
        // Entities
        changeSelectedEntity={changeSelectedEntity}
        selectedLegalEntities={selectedLegalEntities}
        legalEntitiesOptions={legalEntitiesOptions}
        fetchLegalEntitiesOptions={fetchLegalEntitiesOptions}
      />

      <SecondRow
        // General
        assetId={assetId}
        handleFetchSoftwareDetailsQuery={handleFetchSoftwareDetailsQuery}
        // Providers
        selectedProvider={selectedProvider}
        setSelectedProvider={setSelectedProvider}
        // Asset Type
        selectedAssetType={selectedAssetType}
        setSelectedAssetType={setSelectedAssetType}
        // OS
        selectedOperatingSystem={selectedOperatingSystem}
        setSelectedOperatingSystem={setSelectedOperatingSystem}
        // Version
        version={version}
        // Service
        service={service}
        // Selected Modules
        selectedModules={selectedModules}
        // Responsible Person
        users={users}
        // Attachments
        attachments={attachments}
        // Company
        selectedCompany={selectedCompany}
        // Position
        selectedPosition={selectedPosition}
        // Phone Number
        selectedPhoneNumber={selectedPhoneNumber}
        // Email
        selectedEmail={selectedEmail}
        // Department
        selectedDepartment={selectedDepartment}
        // Responsible Person
        selectedResponsiblePerson={selectedResponsiblePerson}
      />
    </React.Fragment>
  );
};

export default withNamespaces()(InfoCards);
