import { DateFilter } from './types/DateFilter'
import { RadioList } from './types/RadioList'

const FilterTabContent = ({ type, ...rest }) => {
    switch (type) {
        case 'date':
            return <DateFilter {...rest} />
        case 'radio_list':
            return <RadioList {...rest} />
        default:
            return null
    }
}

export { FilterTabContent }
